import {useSortBy, useTable} from "react-table";
import { useEffect, useRef } from "react";
import { Table } from "react-bootstrap";

function SortingTable({ columns, data = [], defaultSorted, fnSort, manualSortBy = false }) {
  const { getTableProps, headerGroups, rows, prepareRow, state: { sortBy } } = useTable(
    {
      columns,
      data,
      manualSortBy: manualSortBy,
      disableSortRemove: true,
      initialState: {
        sortBy: [defaultSorted]
      }
    },
    useSortBy
  );
  const isInitialMount = useRef(true);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      // update 에만 실행
      if(manualSortBy) {
        fnSort(sortBy.length === 0 ? defaultSorted : sortBy[0]);
      }
    }
  }, [sortBy, defaultSorted, manualSortBy, fnSort]);

  return (
    <Table responsive bordered hover {...getTableProps()}>
      <thead className="table-dark">
      {
        headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {
              headerGroup.headers.map(column => {
                const headerProps = {
                  ...column.getHeaderProps([
                    {
                      style: {
                        minWidth: column.minWidth,
                        width: column.width,
                        textAlign: 'center'
                      }
                    },
                    column.getSortByToggleProps()
                  ])
                };
                return (
                  <th { ...headerProps }>
                    {column.render('Header')}
                    <span>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? <i className="fas fa-sort-down ms-2"></i>
                          : <i className="fas fa-sort-up ms-2"></i>
                        : ""}
                    </span>
                  </th>
                );
              })
            }
          </tr>
        ))
      }
      </thead>
      <tbody>
      {
        rows.map((row, i) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {
                row.cells.map(cell => {
                  const cellProps = cell.getCellProps([
                    {
                      style: {
                        minWidth: cell.column.minWidth,
                        width: cell.column.width,
                        textAlign : cell.column.textAlign
                      }
                    }
                  ]);
                  return (
                    <td {...cellProps}>
                      {cell.render('Cell')}
                    </td>
                  )
                })
              }
            </tr>
          )
        })
      }
      {
        (!rows || rows.length === 0) &&
        <tr>
          <td colSpan={columns.length} className="text-center">검색 결과가 없습니다.</td>
        </tr>
      }
      </tbody>
    </Table>
  )
}

export default SortingTable;