import {useMemo, useRef} from "react";
import {Controller, useForm} from "react-hook-form";
import { Form, Row, Col } from 'react-bootstrap';
import { http, Validator, toast } from "../../../utils";
import ErrorMessageFeedback from '../../../components/common/ErrorMessageFeedback';
import HelmetWrapper from "../../../components/common/HelmetWrapper";
import {useMutation, useQuery} from "react-query";

function PasswordChange() {
  const initValue = useMemo(() => {
    return {
      userId: '',
      userName: '',
      password: '',
      passwordConfirm: ''
    }
  }, []);

  const {handleSubmit, reset, formState: {errors}, control, watch} = useForm({
    defaultValues : { ...initValue }
  });

  const password = useRef();
  password.current = watch("password", "");

  useQuery(
    ['searchMyAccount'],
    async () => {
      return  (await http.get(`/api/v1/my-account/info`)).data;
    },
    {
      initialData: {},
      onSuccess: (data) => {
        reset({...data, password: '', passwordConfirm: ''});
      }
    });

  const changePasswordAction = useMutation((params) => http.put('/api/v1/my-account/info/password', params));

  const onValid = async (data) => {
    await changePasswordAction.mutateAsync(data);
    toast.success("변경되었습니다.");
  };

  return (
    <>
      <div className="contents-fluid col-6">
        <HelmetWrapper meta={{
          title : 'Password Change'
        }} />

        <div className="page-title">
          Password Change
        </div>

        <div className="mt-4">
          <Form onSubmit={handleSubmit(onValid)} onReset={reset} className="w-100">
            <Row>
              <Col xs="12">
                <Form.Group className="form-group" controlId="id_userId">
                  <Form.Label>User ID</Form.Label>
                  <Controller
                    name="userId"
                    control={control}
                    render={({ field }) =>
                      <Form.Control
                        {...field}
                        type="text"
                        disabled={true}
                      />
                    }
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs="12">
                <Form.Group className="form-group" controlId="id_userName">
                  <Form.Label>User Name</Form.Label>
                  <Controller
                    name="userName"
                    control={control}
                    render={({ field }) =>
                      <Form.Control
                        {...field}
                        type="text"
                        disabled={true}
                      />
                    }
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs="12">
                <Form.Group className="form-group required" controlId="id_password">
                  <Form.Label>Password</Form.Label>
                  <Controller
                    name="password"
                    control={control}
                    rules={{
                      required: true,
                      validate: {
                        passwordCheck: (value) => Validator.validatePasswordCheck(value)
                      }
                    }}
                    render={({ field }) =>
                      <Form.Control
                        {...field}
                        type="password"
                        maxLength="100"
                      />
                    }
                  />
                  <ErrorMessageFeedback error={errors.password} />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs="12">
                <Form.Group className="form-group required" controlId="id_passwordConfirm">
                  <Form.Label>Confirm Password</Form.Label>
                  <Controller
                    name="passwordConfirm"
                    control={control}
                    rules={{
                      required: true,
                      validate: {
                        passwordConfirm : (value) => Validator.validatePasswordConfirm(value, password.current)
                      }
                    }}
                    render={({ field }) =>
                      <Form.Control
                        {...field}
                        type="password"
                        maxLength="100"
                      />
                    }
                  />
                  <ErrorMessageFeedback error={errors.passwordConfirm} />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs="12" className="btn-wrapper d-flex justify-content-end">
                <button className="btn btn-success" type="submit">
                  <span className="material-icons">done</span>Change Password
                </button>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    </>
  );
}

export default PasswordChange;