import _ from 'lodash';
import React, { useEffect, useCallback, useState } from "react";
import {Link, useLocation} from 'react-router-dom';
import { getMenuList } from './MenuData';
import {connect} from "react-redux";

function Menu({ loginInfo, parent, currentPathName = '' }) {
  const [active, setActive] = useState(false);

  const isChildActive = useCallback((path) => {
    return currentPathName.indexOf(_.replace(path, 'view', '')) >= 0;
  }, [currentPathName]);

  const isServerType = useCallback((serverTypes = []) => {
    return serverTypes.length === 0 || _.includes(serverTypes, loginInfo.serverType);
  }, [loginInfo.serverType]);

  useEffect(() => {
    const haveChildActive = parent.childList.map((item) => isChildActive(item.path))
                               .filter((childActive) => childActive)
                               .length > 0;
    setActive(haveChildActive);
  }, [parent, isChildActive]);

  const clickHandler = (e) => {
    e.preventDefault();
    setActive(!active);
  };

  const haveChild = parent.childList && parent.childList.length > 0;

  return (
    <>
      <li className={(active && haveChild) ? 'active' : ''}>
        <a href="#!" className={haveChild ? 'dropdown-toggle' : ''} onClick={clickHandler}>
          <span className="material-icons me-2">{parent.icon}</span>
          {parent.name}
        </a>
        {
          haveChild &&
          <ul className={active ? 'collapse show' : 'collapse'}>
            {
              parent.childList
                .filter((item) => isServerType(item.serverTypes))
                .map((item, idx) =>
                <SubMenu key={idx} child={item} isActive={isChildActive(item.path)} />
              )
            }
          </ul>
        }
      </li>
    </>
  );
}

function SubMenu({ child, isActive }) {
  return (
    <>
      <li className={isActive ? 'active' : ''}>
        <Link to={child.path}>{child.name}</Link>
      </li>
    </>
  );
}

function SideBar ({ loginInfo }) {
  const location = useLocation();

  const menuList = getMenuList(loginInfo.role);
  return (
    <>
      <div id="sidebar">
        <div className="logo mt-3">
          <img src="/logo.png" alt="archipin logo" />
        </div>
        <div className="p-4 pt-5">
          <ul>
            {
              menuList.map((item, idx) =>
                <Menu key={idx} loginInfo={loginInfo} parent={item} currentPathName={location.pathname} />
              )
            }
          </ul>
        </div>
      </div>
    </>
  );
}

function mapStateToProps(state) {
  return {
    loginInfo: state.loginInfo.data
  };
}

export default connect(mapStateToProps)(SideBar);