import { useMemo, useState, useCallback } from "react";
import { Link } from "react-router-dom";
import { Form, Row, Col } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import HelmetWrapper from "../../../components/common/HelmetWrapper";
import { http } from '../../../utils';
import { useQuery } from 'react-query';
import AccountDetailModal from './AccountDetailModal';
import SortingTable from '../../../components/material/SortingTable';
import Paging from '../../../components/common/Paging';

function AccountInfo() {
  const initValue = useMemo(() => {
    return {
      userId: '',
      phoneNumber: '',
      userName: ''
    }
  }, []);

  const [modalItem, setModalItem] = useState({
    isShow: false,
    item: {}
  });

  const {handleSubmit, reset, control, watch, setValue} = useForm({
    defaultValues : { ...initValue }
  });

  const searchList = async () => {
    const condition = watch();
    return (await http.get('/api/v1/user/account', {params: condition})).data;
  };

  const { data: result, refetch : refetchSearch } = useQuery(['searchUserList'], () => searchList(), {
    enabled: false,
    initialData: { resultList: [] }
  });

  const fnSort = useCallback(async (sortBy) => {
    setValue('sortByName', sortBy.id);
    setValue('desc', sortBy.desc);

    await refetchSearch();
  }, [setValue, refetchSearch]);

  const onValid = async () => {
    setValue('currentPage', 1);
    await refetchSearch();
  };

  const fnPaging = async (pageNum) => {
    setValue('currentPage', pageNum);
    await refetchSearch();
  };

  const handleModalShow = (e, item) => {
    e.preventDefault();
    setModalItem({
      isShow: true,
      item: item
    });
  };
  const handleModalHide = () => {
    setModalItem({
      isShow: false,
      item: {}
    });
  };

  const defaultSorted = useMemo(() => ({ id: 'id', desc: true }), []);
  const columns = useMemo(() => [
      {
        Header: "User ID",
        accessor: "id",
        textAlign: 'center',
        Cell: ({ row }) => (<Link to="#!" onClick={ e => handleModalShow(e, row.original)}>{row.original.id}</Link>),
      },
      {
        Header: "Phone",
        accessor: "phoneNumber",
        textAlign: 'center',
      },
      {
        Header: "Create Date",
        accessor: "createDate",
        textAlign: 'center',
      }
    ],[]
  );

  return (
    <>
      <div className="contents-fluid">
        <HelmetWrapper meta={{
          title : 'Account Info'
        }} />

        <div className="page-title">
          Account Info
        </div>

        <div className="search-condition">
          <Form onSubmit={handleSubmit(onValid)} onReset={reset} className="w-100">
            <Row>
              <Col xs="12" md="6">
                <Form.Group className="form-group" controlId="id_userId">
                  <Form.Label>User ID</Form.Label>
                  <Controller
                    name="userId"
                    control={control}
                    render={({ field }) =>
                      <Form.Control
                        {...field}
                        type="text"
                        placeholder="일치"
                      />
                    }
                  />
                </Form.Group>
              </Col>
              <Col xs="12" md="6">
                <Form.Group className="form-group" controlId="id_phoneNumber">
                  <Form.Label>Phone</Form.Label>
                  <Controller
                    name="phoneNumber"
                    control={control}
                    render={({ field }) =>
                      <Form.Control
                        {...field}
                        type="text"
                        placeholder="포함"
                      />
                    }
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs="12" className="btn-wrapper">
                <button className="btn btn-primary" type="submit">
                  <span className="material-icons">search</span>Search
                </button>
              </Col>
            </Row>
          </Form>
        </div>

        <div className="search-result">
          <div className="sub-title">검색 결과</div>

          <div className="table-wrapper">
            <div className="count-block">
              <span>Showing {result.startIndex}-{result.endIndex} of {result.totalCnt} results</span>
            </div>

            <SortingTable columns={columns}
                          data={result.resultList}
                          defaultSorted={defaultSorted}
                          manualSortBy={true}
                          fnSort={fnSort} />

            <Paging totalPages={result.totalPages}
                    currentPage={result.currentPage}
                    prevPage={result.prevPage}
                    nextPage={result.nextPage}
                    startPage={result.startPage}
                    endPage={result.endPage}
                    displayPageNum={result.displayPageNum}
                    fnPaging={fnPaging} />
          </div>
        </div>

        <AccountDetailModal
          onHide={handleModalHide}
          modalItem={modalItem}
        />
      </div>
    </>
  );
}

export default AccountInfo;