import {useCallback, useEffect, useMemo} from "react";
import {http} from "../../../utils";
import {useQuery} from "react-query";
import {useForm} from "react-hook-form";
import SortingTable from "../../../components/material/SortingTable";
import Paging from "../../../components/common/Paging";

function HistoryLudiPass({student={}, forceRefresh}) {
    const initValue = useMemo(() => {
        return {
            historyLudiPassId: ''
        }
    }, []);
    const {setValue, getValues} = useForm({
        mode: 'onChange',
        defaultValues : { ...initValue }
    });
    const { data: result, refetch : refetchSearch } = useQuery(
    ['searchHistoryLudiPass'],
    async () => {
        const param = {
            ...getValues(),
            studentId : student?.id
        }
        const result = await http.get(`/api/v1/edit/ludipass/history`,{params: param});
        return result.data;
    },
    {
        enabled: false,
        initialData: []
    });
    useEffect(() => {
        async function fetchData() {
            await refetchSearch();
        }
        fetchData();
    }, [student, forceRefresh, refetchSearch, setValue]);

    const fnHistoryLudiPassSort = useCallback(async (sortBy) => {
        setValue('sortByName', sortBy.id);
        setValue('desc', sortBy.desc);

        await refetchSearch();
    }, [setValue, refetchSearch]);

    const fnHistoryLudiPassValidPaging = async (pageNum) => {
        setValue('currentPage', pageNum);
        await refetchSearch();
    };
    const defaultHistoryLudiPassSorted = useMemo(() => ({ id: 'id', desc: false }), []);
    const columns = [
        {
            Header: "Product Name",
            accessor: "productName",
            textAlign: 'center',
        },
        {
            Header: "Target Code",
            accessor: "targetCode",
            textAlign: 'center',
        },
        {
            Header: "Reg Id",
            accessor: "regId",
            textAlign: 'center',
        },
        {
            Header: "Market Type",
            accessor: "marketType",
            textAlign: 'center',
        },
        {
            Header: "Ludi Pass Type",
            accessor: "ludiPassType",
            textAlign: 'center',
        },
        {
            Header: "Apply Day Count",
            accessor: "applyDayCount",
            textAlign: 'center',
        },
        {
            Header: "History Create Date",
            accessor: "createDate",
            textAlign: 'center',
        }
    ];
    return (
        <>
            <div className="search-result">
                <div className="sub-title">Ludi Pass 등록 내역</div>

                <div className="table-wrapper">
                    <div className="count-block justify-content-between">
                        <div>
                        </div>
                        <div>
                            <span>Showing {result.startIndex}-{result.endIndex} of {result.totalCnt} results</span>
                        </div>
                    </div>

                    <SortingTable columns={columns}
                                  data={result.resultList}
                                  defaultSorted={defaultHistoryLudiPassSorted}
                                  manualSortBy={true}
                                  fnSort={fnHistoryLudiPassSort} />

                    <Paging totalPages={result.totalPages}
                            currentPage={result.currentPage}
                            prevPage={result.prevPage}
                            nextPage={result.nextPage}
                            startPage={result.startPage}
                            endPage={result.endPage}
                            displayPageNum={result.displayPageNum}
                            fnPaging={fnHistoryLudiPassValidPaging} />
                </div>
            </div>
        </>
    )
}
export default HistoryLudiPass;