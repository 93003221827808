import {Alert, http, toast} from "../../../utils";
import HelmetWrapper from "../../../components/common/HelmetWrapper";
import {Row, Col, Button, Form} from "react-bootstrap";
import {history} from "../../../router/History";
import {Controller, useForm} from "react-hook-form";
import ErrorMessageFeedback from "../../../components/common/ErrorMessageFeedback";
import {useMemo} from "react";
import {useMutation} from "react-query";
import {useNavigate} from "react-router-dom";


function StudentLinkRegist() {
  const initValue = useMemo(() => {
    return {
      godomallId: '',
      firstName: '',
      lastName: '',
      phoneNumber: '',
    }
  }, []);

  const navigate = useNavigate();

  const {handleSubmit, reset, formState: {errors}, control} = useForm({
    mode: 'onChange',
    defaultValues : { ...initValue }
  });

  const insertAction = useMutation((params) => http.post('/api/v1/parents/student-link/force', params));

  const onValid = (e) => {
    e.preventDefault();

    handleSubmit(async (data) => {
      const confirmMsg = '정말 강제 연동 하시겠습니까?';

      Alert.confirm(confirmMsg, async () => {
        await insertAction.mutateAsync(data);
        toast.success('완료 되었습니다.');
        navigate({
          pathname: '/adms/parents/student-link/view'
        });
      });
    })();
  }

  return (
    <>
      <div className="contents-fluid">
        <HelmetWrapper meta={{
          title : '학습자 연동 강제 등록'
        }} />
        <div className="page-title">
          학습자 연동 강제 등록
        </div>

        <div className="text-end mt-3">
          <Button variant="outline-secondary" onClick={() => history.back() }><span className="material-icons">keyboard_backspace</span><span>Back</span></Button>
        </div>

        <div className="mt-4">
          <Form onSubmit={onValid} onReset={reset} className="w-100">
            <Row>
              <Col xs="6">
                <Form.Group className="form-group required" controlId="id_godomallId">
                  <Form.Label>Godomall ID</Form.Label>
                  <Controller
                    name="godomallId"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) =>
                      <Form.Control
                        {...field}
                        type="text"
                        maxLength="100"
                        placeholder=""
                        isInvalid={errors.godomallId}
                      />
                    }
                  />
                  <ErrorMessageFeedback error={errors.godomallId} />
                </Form.Group>
              </Col>
              <Col xs="6">
                <Form.Group className="form-group required" controlId="id_phoneNumber">
                  <Form.Label>Phone Number</Form.Label>
                  <Controller
                    name="phoneNumber"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) =>
                      <Form.Control
                        {...field}
                        type="text"
                        maxLength="100"
                        placeholder=""
                        isInvalid={errors.phoneNumber}
                      />
                    }
                  />
                  <ErrorMessageFeedback error={errors.phoneNumber} />
                </Form.Group>
              </Col>
              <Col xs="6">
                <Form.Group className="form-group required" controlId="id_firstName">
                  <Form.Label>First Name</Form.Label>
                  <Controller
                    name="firstName"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) =>
                      <Form.Control
                        {...field}
                        type="text"
                        maxLength="100"
                        placeholder=""
                        isInvalid={errors.firstName}
                      />
                    }
                  />
                  <ErrorMessageFeedback error={errors.firstName} />
                </Form.Group>
              </Col>
              <Col xs="6">
                <Form.Group className="form-group required" controlId="id_lastName">
                  <Form.Label>Last Name</Form.Label>
                  <Controller
                    name="lastName"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) =>
                      <Form.Control
                        {...field}
                        type="text"
                        maxLength="100"
                        placeholder=""
                        isInvalid={errors.lastName}
                      />
                    }
                  />
                  <ErrorMessageFeedback error={errors.lastName} />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs="12" className="btn-wrapper d-flex justify-content-end">
                <button className="btn btn-success" type="submit">
                  <span className="material-icons">done</span>강제 연동
                </button>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    </>
  );
}

export default StudentLinkRegist;