import React, {useEffect} from "react";
import { Outlet } from 'react-router-dom';
import Header from './Header'
import Footer from './Footer'
import SideBar from "./SideBar";
import { useNavigate } from "react-router-dom";
import { store } from "../../store/store";
import { Constant } from '../../utils';

// Layout
const DefaultLayout = () => {
  const navigate = useNavigate();
  const isLoggedIn = store.getState().loginInfo.isLogin;

  useEffect(() => {
    if(!isLoggedIn) {
      navigate({
        pathname: Constant.LOGIN_URL
      });
    }
  }, [navigate, isLoggedIn]);

  return (
    <>
      <div className="d-flex align-items-stretch">
        <SideBar />

        <div id="content-wrapper">
          <Header />

          <div className="content">
            <Outlet />
          </div>

          <Footer />
        </div>
      </div>
    </>
  );
};
export default DefaultLayout;

