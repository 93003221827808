import {Button, Col, Form, Modal, Row} from "react-bootstrap";
import {Controller, useForm} from "react-hook-form";
import ErrorMessageFeedback from "../../../components/common/ErrorMessageFeedback";
import {useMemo} from "react";
import {Alert, http, toast, Validator} from "../../../utils";
import {useMutation} from "react-query";

function EditLudiEnengyModal({onHide, modalItem}) {
  const {
    isShow,
    item,
  } = modalItem;

  const initValue = useMemo(() => {
    return {
      userId : '',
      studentId : '',
      name : '',
      ludiEnergy: ''
    }
  }, []);

  const {handleSubmit, reset, formState: {errors}, control} = useForm({
    mode: 'onChange',
    defaultValues : { ...initValue }
  });

  const updateItemAction = useMutation((params) => http.put('/api/v1/edit/student/portal/ludi-energy', params));

  const handleClose = (isRefresh = false) => {
    onHide(isRefresh);
  };

  const init = async () => {
    reset({...item});
  };

  const onValid = async (data) => {
    const confirmMsg = '수정 하시겠습니까?';
    const completeMsg = '수정 되었습니다.';
    Alert.confirm(confirmMsg, async () => {
      const param = {...data, userId : item.userId, studentId: item.studentId};
      await updateItemAction.mutateAsync(param);

      toast.success(completeMsg);
      handleClose(true);
    });
  };

  return (
    <>
      <Modal
        size="lg"
        show={isShow}
        onHide={() => handleClose()}
        className="detail"
        onEntered={() => {
          init();
        }}
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">Edit LudiEnergy</Modal.Title>
          <Button type="button" className="close" aria-label="Close" onClick={() => handleClose()}>
            <span aria-hidden="true">&times;</span>
          </Button>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xs="12" md="6">
              <div className="detail-row ms-0">
                <span className="label">Student ID</span> <span>{item.studentId}</span>
              </div>
            </Col>
            <Col xs="12" md="6">
              <div className="detail-row">
                <span className="label">Student Name</span> <span>{item.name}</span>
              </div>
            </Col>
          </Row>
          <Form onSubmit={handleSubmit(onValid)} onReset={reset} className="w-100 mt-4">
            <Row>
              <Col xs="12">
                  <Form.Group className="form-group required" controlId="id_ludiEnergy">
                    <Form.Label>LudiEnergy</Form.Label>
                    <Controller
                      name="ludiEnergy"
                      control={control}
                      rules={{
                        required: true,
                        validate: {
                          number: (value) => Validator.validateNumber(value)
                        }
                      }}
                      render={({ field }) =>
                        <Form.Control
                          {...field}
                          type="text"
                          isInvalid={errors.ludiEnergy}
                        />
                      }
                    />
                    <ErrorMessageFeedback error={errors.ludiEnergy} />
                  </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs="12" className="btn-wrapper mt-4 text-end">
                <button className="btn btn-success" type="submit">
                  <span className="material-icons">done</span>Update
                </button>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default EditLudiEnengyModal;