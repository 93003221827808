import React from 'react';
import {Routes, Route } from 'react-router-dom';
import ScrollToTop from "../components/common/ScrollToTop";
import LoginLayout from '../layouts/login';
import DefaultLayout from '../layouts/default';
import Page404 from '../pages/404';
import Login from '../pages/Login';
import AccountInfo from '../pages/user/AccountInfo';
import StudentInfo from '../pages/user/StudentInfo';
import StudentDetail from '../pages/user/StudentInfo/StudentDetail';
import StudentAccesses from '../pages/user/StudentAccesses';
import MissionInfo from '../pages/game/MissionInfo';
import InteractionInfo from '../pages/game/InteractionInfo';
import PortalInfo from '../pages/game/PortalInfo';
import DailyReport from '../pages/report/DailyReport';
import DmLog from '../pages/report/DmLog';
import AcCompetence from '../pages/report/AcCompetence';
import PasswordChange from '../pages/myAccount/PasswordChange';
import AdmsUser from '../pages/system/AdmsUser';
import AdmsUserDetail from '../pages/system/AdmsUser/AdmsUserDetail';
import TestResult from '../pages/onlineTest/TestResult';
import ExcelUploadHistory from "../pages/datatable/ExcelUploadHistory";
import DataTableOverwrite from "../pages/datatable/DataTableOverwrite";
import EditStudent from "../pages/editdata/EditStudent";
import VHDmLog from "../pages/voiceHunter/VHDmLog";
import VHReserveFund from "../pages/voiceHunter/VHReserveFund";
import MessageRepeat from "../pages/message/MessageRepeat";
import MessageRepeatDetail from "../pages/message/MessageRepeat/MessageRepeatDetail";
import MessageReward from "../pages/message/MessageReward";
import MessageRewardDetail from "../pages/message/MessageReward/MessageRewardDetail";
import StudentLink from "../pages/parents/StudentLink";
import StudentLinkDetail from "../pages/parents/StudentLink/StudentLinkDetail";
import StudentLinkRegist from "../pages/parents/StudentLink/StudentLinkRegist";
import EditLudiPassDetail from "../pages/editdata/EditLudiPass";
import Maintenance from "../pages/maintenance/Maintenance";
import MaintenanceDetail from "../pages/maintenance/Maintenance/MaintenanceDetail";
import RoutineMaintenance from "../pages/maintenance/RoutineMaintenance";
import EditLudiPassBulk from "../pages/editdata/EditLudiPassBulk";
import CreateAccount from "../pages/b2b/CreateAccount";
import Notice from "../pages/notice";
import CreateHistory from "../pages/b2b/CreateHistory";
const RouterApp = () => (
  <div>
    <Routes>
      <Route element={<ScrollToTop />}>
        <Route element={<LoginLayout />}>
          <Route path="/" element={<Login />} />
          <Route path="/adms/login/view" element={<Login />} />
        </Route>

        <Route element={<DefaultLayout />}>
          <Route path="/adms/user/account-info/view" element={<AccountInfo />} />
          <Route path="/adms/user/student-info/view" element={<StudentInfo />} />
          <Route path="/adms/user/student-info/detail/view" element={<StudentDetail />} />
          <Route path="/adms/user/student-accesses/view" element={<StudentAccesses />} />

          <Route path="/adms/game/mission-info/view" element={<MissionInfo />} />
          <Route path="/adms/game/interaction-info/view" element={<InteractionInfo />} />
          <Route path="/adms/game/portal-info/view" element={<PortalInfo />} />

          <Route path="/adms/game/datatable/history/view" element={<ExcelUploadHistory />} />
          <Route path="/adms/game/datatable/overwrite/view" element={<DataTableOverwrite />} />

          <Route path="/adms/edit/student/view" element={<EditStudent />} />
          <Route path="/adms/edit/ludipass/detail/view" element={<EditLudiPassDetail />} />
          <Route path="/adms/edit/ludipass/bulk/view" element={<EditLudiPassBulk />} />

          <Route path="/adms/parents/student-link/view" element={<StudentLink />} />
          <Route path="/adms/parents/student-link/detail/view/:id" element={<StudentLinkDetail />} />
          <Route path="/adms/parents/student-link/regist" element={<StudentLinkRegist />} />

          <Route path="/adms/report/daily-report/view" element={<DailyReport />} />
          <Route path="/adms/report/dm-log/view" element={<DmLog />} />
          <Route path="/adms/report/ac-competence/view" element={<AcCompetence />} />

          <Route path="/adms/my-account/password-change/view" element={<PasswordChange />} />

          <Route path="/adms/message/repeat/view" element={<MessageRepeat />} />
          <Route path="/adms/message/repeat/add/view" element={<MessageRepeatDetail />} />
          <Route path="/adms/message/repeat/detail/view/:id" element={<MessageRepeatDetail />} />
          <Route path="/adms/message/reward/view" element={<MessageReward />} />
          <Route path="/adms/message/reward/add/view" element={<MessageRewardDetail />} />
          <Route path="/adms/message/reward/detail/view/:id" element={<MessageRewardDetail />} />

          <Route path="/adms/system/adms-user/view" element={<AdmsUser />} />
          <Route path="/adms/system/adms-user/add/view" element={ <AdmsUserDetail /> }/>
          <Route path="/adms/system/adms-user/detail/view/:userId" element={ <AdmsUserDetail /> }/>

          <Route path="/adms/online-test/report/view" element={ <TestResult /> }/>

          <Route path="/adms/voice-hunber/dm-log/view" element={ <VHDmLog /> }/>
          <Route path="/adms/voice-hunber/reserve-fund/view" element={ <VHReserveFund /> }/>

          <Route path="/adms/notice/view" element={<Notice />} />

          <Route path="/adms/maintenance/view" element={<Maintenance />} />
          <Route path="/adms/maintenance/add/view" element={<MaintenanceDetail />} />
          <Route path="/adms/maintenance/detail/view/:id" element={<MaintenanceDetail />} />

          <Route path="/adms/b2b/account/view" element={<CreateAccount />} />
          <Route path="/adms/b2b/history/view" element={<CreateHistory />} />

          <Route path="/adms/routine-maintenance/view" element={<RoutineMaintenance />} />
        </Route>

        <Route path="/404" element={<Page404 />} />
        <Route path="*" element={<Page404/>}/>
      </Route>
    </Routes>
  </div>
);

export default RouterApp;