import _ from 'lodash';

function isEmpty(value) {
  return _.isNil(value) || value.length === 0;
}

export const validateEmail = (value) => {
  return isEmpty(value) || /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test( _.trim(value) );
};

export const validatePasswordCheck = (value) => {
  return isEmpty(value) || /^(?=.*[0-9])(?=.*[a-z~`!@#$%^&*()-]).{8,20}$/.test(value);
};

export const validatePasswordConfirm = (passwordConfirm, password) => {
  return isEmpty(password) || passwordConfirm === password;
};

export const validateNumber = (value) => {
  return isEmpty(value) || /^[0-9]*$/.test(value);
};
/* 소수점 한자리 체크 */
export const validateDecimalPoint1 = (value) => {
  return isEmpty(value) || /^(\d*)[.]?(\d{1})?$/g.test(value)
}

export const validateGreaterThan = (value, target) => {
  return isEmpty(value) || !validateNumber(value) || parseInt(value) > target;
}
