import "react-datepicker/dist/react-datepicker.css";
import {Col, Form, Row} from "react-bootstrap";
import HelmetWrapper from "../../../components/common/HelmetWrapper";
import {Controller, useForm} from "react-hook-form";
import React, {useCallback, useMemo} from "react";
import DatePicker from "react-datepicker";
import DatePickerInput from '../../../components/material/DatePickerInput';
import ErrorMessageFeedback from "../../../components/common/ErrorMessageFeedback";
import moment from "moment";
import {DateUtils, http, Formatter} from "../../../utils";
import {useQuery} from "react-query";
import SortingTable from '../../../components/material/SortingTable';
import qs from "qs";
import Paging from "../../../components/common/Paging";

function MissionInfo() {
  const initValue = useMemo(() => {
    return {
      startDate: moment(DateUtils.todayTime('00:00:00')).subtract(1, 'days').toDate(),
      endDate: DateUtils.todayTime('00:00:00'),
      userId: '',
      studentId: '',
      missionId: ''
    }
  }, []);

  const {handleSubmit, reset, formState: {errors, isValid}, control, getValues, setValue } = useForm({
    mode: 'onChange',
    defaultValues : { ...initValue }
  });

  const { data: result, refetch : refetchSearch } = useQuery(
    ['searchMissionInfo'],
    async () => {
      const result = await http.get(`/api/v1/game/mission-info/statistics`,{params: getValues()});
      return result.data;
    },
    {
      enabled: false,
      initialData: []
    });

  const fnSort = useCallback(async (sortBy) => {
    setValue('sortByName', sortBy.id);
    setValue('desc', sortBy.desc);

    await refetchSearch();
  }, [setValue, refetchSearch]);

  const onValid = async () => {
    setValue('currentPage', 1);
    await refetchSearch();
  };

  const fnPaging = async (pageNum) => {
    setValue('currentPage', pageNum);
    await refetchSearch();
  };

  const onDownload = (e) => {
    e.preventDefault();
    if(!isValid) {
      return;
    }
    const url = `/api/v1/game/mission-info/excel/statistics?${qs.stringify(getValues())}`;
    window.open(url, '_blank');
  };

  const defaultSorted = useMemo(() => ({ id: 'missionId', desc: false }), []);
  const columns = useMemo(() => [
      {
        Header: 'Mission ID',
        accessor: 'missionId',
        textAlign: 'center',
      },
      {
        Header: 'Subject',
        accessor: 'missionName',
        textAlign: 'center',
      },
      {
        Header: 'NPC',
        accessor: 'npcName',
        textAlign: 'center',
        disableSortBy: true,
      },
      {
        Header: 'Category',
        accessor: 'missionType',
        textAlign: 'center',
      },
      {
        Header: 'Complete',
        accessor: 'completeCount',
        textAlign: 'center',
        Cell: ({ row }) => Formatter.numberWithCommas(row.original.completeCount),
      },
      {
        Header: 'In progress',
        accessor: 'progressCount',
        textAlign: 'center',
        Cell: ({ row }) => Formatter.numberWithCommas(row.original.progressCount),
      },
    ],[]
  );

  return (
    <>
      <div className="contents-fluid">
        <HelmetWrapper meta={{
          title : 'Mission Info'
        }} />

        <div className="page-title">
          Mission Info
        </div>

        <div className="search-condition">
          <Form onSubmit={handleSubmit(onValid)} onReset={reset} className="w-100">
            <Row>
              <Col xs="6" className=" mb-4">
                <Form.Group className="form-group required mb-0">
                  <Form.Label>Date</Form.Label>
                </Form.Group>
                <div className="condition-date-picker">
                  <Form.Group className="form-group mb-0">
                    <Controller
                      name="startDate"
                      control={control}
                      rules={ {required: true} }
                      render={({ field, fieldState }) =>
                        <DatePicker
                          {...field}
                          dateFormat="yyyy/MM/dd HH:mm"
                          selected={field.value}
                          showTimeSelect
                          timeIntervals={60}
                          autoComplete="off"
                          onChange={(date) => {
                            field.onChange(date);
                          }}
                          className={fieldState.invalid ? 'is-invalid' : ''}
                          customInput={ <DatePickerInput /> }
                        />
                      }
                    />
                    <ErrorMessageFeedback error={errors.startDate} />
                  </Form.Group>

                  <div className='ms-2 me-2'> ~ </div>

                  <Form.Group className="form-group mb-0">
                    <Form.Label></Form.Label>
                    <Controller
                      name="endDate"
                      control={control}
                      rules={ {required: true} }
                      render={({ field, fieldState }) =>
                        <DatePicker
                          {...field}
                          dateFormat="yyyy/MM/dd HH:mm"
                          selected={field.value}
                          showTimeSelect
                          timeIntervals={60}
                          autoComplete="off"
                          onChange={(date) => {
                            field.onChange(date);
                          }}
                          className={fieldState.invalid ? 'is-invalid' : ''}
                          customInput={ <DatePickerInput /> }
                        />
                      }
                    />
                    <ErrorMessageFeedback error={errors.endDate} />
                  </Form.Group>
                </div>
              </Col>
              <Col xs="12" md="6">
                <Form.Group className="form-group" controlId="id_userId">
                  <Form.Label>User ID</Form.Label>
                  <Controller
                    name="userId"
                    control={control}
                    render={({ field }) =>
                      <Form.Control
                        {...field}
                        type="text"
                        placeholder="일치"
                      />
                    }
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs="12" md="6">
                <Form.Group className="form-group" controlId="id_studentId">
                  <Form.Label>Student Id</Form.Label>
                  <Controller
                    name="studentId"
                    control={control}
                    render={({ field }) =>
                      <Form.Control
                        {...field}
                        type="text"
                        placeholder="일치"
                      />
                    }
                  />
                </Form.Group>
              </Col>
              <Col xs="12" md="6">
                <Form.Group className="form-group" controlId="id_missionId">
                  <Form.Label>Mission Id</Form.Label>
                  <Controller
                    name="missionId"
                    control={control}
                    render={({ field }) =>
                      <Form.Control
                        {...field}
                        type="text"
                        placeholder="포함"
                      />
                    }
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs="12" className="btn-wrapper">
                <button className="btn btn-primary" type="submit">
                  <span className="material-icons">search</span>Search
                </button>
              </Col>
            </Row>
          </Form>
        </div>

        <div className="search-result">
          <div className="sub-title">검색 결과</div>
            <div className="table-wrapper">
              <div className="count-block justify-content-between">
                <div>
                  <div className="pt-1 pb-2"><a href="#!" onClick={onDownload}>Excel Download<i className="fas fa-file-download ms-2"></i></a></div>
                </div>
                <div>
                  <span>Showing {result.startIndex}-{result.endIndex} of {result.totalCnt} results</span>
                </div>
              </div>

              <SortingTable columns={columns}
                            data={result.resultList}
                            defaultSorted={defaultSorted}
                            manualSortBy={true}
                            fnSort={fnSort} />

              <Paging totalPages={result.totalPages}
                      currentPage={result.currentPage}
                      prevPage={result.prevPage}
                      nextPage={result.nextPage}
                      startPage={result.startPage}
                      endPage={result.endPage}
                      displayPageNum={result.displayPageNum}
                      fnPaging={fnPaging} />
            </div>
          </div>
      </div>
    </>
  );
}

export default MissionInfo;