import { createSlice } from "@reduxjs/toolkit";

export const loaderSlice = createSlice({
  name: "loaderCount",
  initialState: {
    count: 0
  },
  reducers: {
    init : (state) => {
      state.count = 0;
    },
    increment: (state) => {
      state.count += 1;
    },
    decrement: (state) => {
      state.count = state.count - 1 < 0 ? 0 : state.count - 1;
    },
    getCount: (state) => {
      return state.count;
    }
  }
});
