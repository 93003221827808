import {Button, Col, Form, Modal, Row} from "react-bootstrap";
import {Controller, useForm} from "react-hook-form";
import {useCallback, useMemo} from "react";
import {http, Validator} from "../../../../utils";
import {useQuery} from "react-query";
import SortingTable from "../../../../components/material/SortingTable";
import Paging from "../../../../components/common/Paging";

function ItemSearchModal({onSelect, onHide, modalItem}) {
  const {
    isShow,
  } = modalItem;

  const initValue = useMemo(() => {
    return {
      itemCategory: '',
      itemId: ''
    }
  }, []);

  const {handleSubmit, reset, control, setValue, getValues} = useForm({
    mode: 'onChange',
    defaultValues : { ...initValue }
  });

  const { data: resultCategory, refetch : refetchCategorySearch } = useQuery(
    ['searchCategory'],
    async () => {
      const result = await http.get(`/api/v1/edit/student/inventory/item/category`);
      return result.data;
    },
    {
      enabled: false,
      initialData: []
    });

  const { data: result, refetch : refetchSearch } = useQuery(
    ['searchItems'],
    async () => {
      const result = await http.get(`/api/v1/edit/student/inventory/items`,{params: getValues()});
      return result.data;
    },
    {
      enabled: false,
      initialData: []
    });

  const handleClose = useCallback(() => {
    onHide();
  }, [onHide]);

  const handleSelect = useCallback((e, data) => {
    e.preventDefault();
    onSelect(data);
    handleClose();
  }, [onSelect, handleClose]);

  const onValid = async () => {
    setValue('currentPage', 1);
    await refetchSearch();
  };

  const fnSort = useCallback(async (sortBy) => {
    setValue('sortByName', sortBy.id);
    setValue('desc', sortBy.desc);

    await refetchSearch();
  }, [setValue, refetchSearch]);

  const fnPaging = async (pageNum) => {
    setValue('currentPage', pageNum);
    await refetchSearch();
  };

  const defaultSorted = useMemo(() => ({ id: 'itemId', desc: false }), []);
  const columns = useMemo(() => [
      {
        Header: "Category",
        accessor: "itemCategory",
        textAlign: 'center',
      },
      {
        Header: "Item Id",
        accessor: "itemId",
        textAlign: 'center',
      },
      {
        Header: "Name",
        accessor: "name",
        textAlign: 'center',
      },
      {
        Header: "Name Native",
        accessor: "nameNative",
        textAlign: 'center',
      },
      {
        Header: "Item Count Limit",
        accessor: "itemCountLimit",
        textAlign: 'center',
        Cell: ({ row }) => (row.original.itemCountLimit === 0 ? '' : row.original.itemCountLimit),
      },
      {
        Header: "",
        accessor: "id",
        textAlign: 'center',
        disableSortBy: true,
        Cell: ({ row }) => (<a href="#!" onClick={(e) => handleSelect(e, row.original)}>select</a>),
      },
    ],[handleSelect]
  );

  const init = async () => {
    reset({...initValue});
    await refetchCategorySearch();
    await refetchSearch();
  };

  return (
    <>
      <Modal
        size="lg"
        show={isShow}
        onHide={handleClose}
        className="detail"
        onEntered={() => {
          init();
        }}
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">Item Search</Modal.Title>
          <Button type="button" className="close" aria-label="Close" onClick={handleClose}>
            <span aria-hidden="true">&times;</span>
          </Button>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit(onValid)} onReset={reset} className="w-100 mt-4">
            <Row>
              <Col xs="6">
                <Form.Group className="form-group" controlId="id_itemCategory">
                  <Form.Label>Category</Form.Label>
                  <Controller
                    name="itemCategory"
                    control={control}
                    render={({ field }) =>
                      <Form.Control
                        as="select"
                        className="form-select"
                        {...field}
                        value={field.value || ''}
                      >
                        {
                          resultCategory?.map((item, idx) => {
                            return <option key={idx} value={item.itemCategory}>{item.itemCategory}</option>
                          })
                        }
                      </Form.Control>
                    }
                  />
                </Form.Group>
              </Col>
              <Col xs="6">
                <Form.Group className="form-group" controlId="id_itemId">
                  <Form.Label>Item ID</Form.Label>
                  <Controller
                    name="itemId"
                    control={control}
                    rules={{
                      validate: {
                        number: (value) => Validator.validateNumber(value)
                      }
                    }}
                    render={({ field }) =>
                      <Form.Control
                        {...field}
                        type="text"
                        placeholder="포함"
                      />
                    }
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs="12" className="btn-wrapper mt-4 text-end">
                <button className="btn btn-primary" type="submit">
                  <span className="material-icons">search</span>Search
                </button>
              </Col>
            </Row>
          </Form>

          <div className="search-result">
            <div className="table-wrapper">
              <div className="count-block">
                <span>Showing {result.startIndex}-{result.endIndex} of {result.totalCnt} results</span>
              </div>

              <SortingTable columns={columns}
                            data={result.resultList}
                            defaultSorted={defaultSorted}
                            manualSortBy={true}
                            fnSort={fnSort} />

              <Paging totalPages={result.totalPages}
                      currentPage={result.currentPage}
                      prevPage={result.prevPage}
                      nextPage={result.nextPage}
                      startPage={result.startPage}
                      endPage={result.endPage}
                      displayPageNum={result.displayPageNum}
                      fnPaging={fnPaging} />
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ItemSearchModal;