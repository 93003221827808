import AudioPlayModal from "../../../components/material/AudioPlayModal";
import DefaultTable from "../../../components/material/DefaultTable";
import Paging from "../../../components/common/Paging";
import _ from 'lodash';
import {useEffect, useMemo, useState} from "react";
import {Controller, useForm} from "react-hook-form";
import {useQuery} from "react-query";
import {Col, Form, Row, InputGroup, Button} from "react-bootstrap";
import HelmetWrapper from "../../../components/common/HelmetWrapper";
import {http, Constant, DateUtils, toast} from "../../../utils";
import moment from "moment";
import DatePicker from "react-datepicker";
import DatePickerInput from "../../../components/material/DatePickerInput";
import ErrorMessageFeedback from "../../../components/common/ErrorMessageFeedback";
import Select from 'react-select';
import 'react-tagsinput/react-tagsinput.css';

function DmLog() {
  const initValue = useMemo(() => {
    return {
      startDate: moment(DateUtils.todayTime('00:00:00')).subtract(6, 'days').toDate(),
      endDate: DateUtils.todayTime('23:59:59'),
      userIdText: '',
      userIdList: [],
      studentIdText: '',
      studentIdList: [],
      studentNameText: '',
      studentNameList: [],
      missionIdText: '',
      missionIdList:[],
      taskIdText: '',
      taskIdList: [],
      npcHL : '',
      userHL : '',
      turnIdText: '',
      turnIdList: [],
      rationales: []
    }
  }, []);


  const {handleSubmit, reset, formState: {errors}, control, getValues, setValue, watch} = useForm({
    mode: 'onChange',
    defaultValues : { ...initValue }
  });

  const watchUserIdText = watch('userIdText', '');
  const watchUserIdList = watch('userIdList', []);

  const watchStudentIdText = watch('studentIdText', '');
  const watchStudentIdList = watch('studentIdList', []);

  const watchStudentNameText = watch('studentNameText', '');
  const watchStudentNameList = watch('studentNameList', []);

  const watchMissionIdText = watch('missionIdText', '');
  const watchMissionIdList = watch('missionIdList', []);

  const watchTaskIdText = watch('taskIdText', '');
  const watchTaskIdList = watch('taskIdList', []);

  const watchTurnIdText = watch('turnIdText', '');
  const watchTurnIdList = watch('turnIdList', []);

  useEffect(() => {
    const list = _.split(watchUserIdText, /\r?\n/)
        .map(v => v.trim())
        .filter((v) => v.length !== 0);

    setValue('userIdList', list);
  }, [watchUserIdText, setValue]);

  useEffect(() => {
    const list = _.split(watchStudentIdText, /\r?\n/)
        .map(v => v.trim())
        .filter((v) => v.length !== 0);

    setValue('studentIdList', list);
  }, [watchStudentIdText, setValue]);

  useEffect(() => {
    const list = _.split(watchStudentNameText, /\r?\n/)
        .map(v => v.trim())
        .filter((v) => v.length !== 0);

    setValue('studentNameList', list);
  }, [watchStudentNameText, setValue]);

  useEffect(() => {
    const list = _.split(watchMissionIdText, /\r?\n/)
        .map(v => v.trim())
        .filter((v) => v.length !== 0);

    setValue('missionIdList', list);
  }, [watchMissionIdText, setValue]);

  useEffect(() => {
    const list = _.split(watchTaskIdText, /\r?\n/)
        .map(v => v.trim())
        .filter((v) => v.length !== 0);

    setValue('taskIdList', list);
  }, [watchTaskIdText, setValue]);

  useEffect(() => {
    const list = _.split(watchTurnIdText, /\r?\n/)
        .map(v => v.trim())
        .filter((v) => v.length !== 0);
    setValue('turnIdList', list);
  }, [watchTurnIdText, setValue]);

  const [audioPlayModalItem, setAudioPlayModalItem] = useState({
    isShow: false,
    url : ''
  });

  const { data: result, refetch : refetchSearch } = useQuery(
    ['searchDmLog'],
    async () => {
      const res = await http.get(`/api/v1/report/dm-log`,{params: getValues()});
      return res.data;
    },
    {
      enabled: false,
      initialData: {}
    });

  const handleSearch = (e) => {
    e.preventDefault();

    handleSubmit(async (data) => {
      if(getValues('userIdList').length === 0 && getValues('studentIdList').length === 0
        && getValues('missionId') === '' && getValues('taskIdList').length === 0 && getValues('turnIdList').length === 0
        && !validateDate(data.startDate, data.endDate)) {
        return;
      }
      await refetchSearch();
    })();
  };

  const downloadExcel = (e) => {
    e.preventDefault();
    handleSubmit(() => {
      http.postExcelDownload('/api/v1/report/dm-log/excel', {...getValues()});
    })();
  }

  /* 시간 체크 */
  const validateDate = (start, end) => {
    const oneDayMill = 24 * 60 * 60 * 1000; // 86400000
    const ms = moment(end).diff(moment(start));
    const diff = moment.duration(ms);
    if(diff < 0) {
      toast.error('기간을 올바르게 입력하여 주세요.');
      return false;
    }
    if(diff > oneDayMill * 7) {
      toast.error('7일 까지만 조회 가능합니다.');
      return false;
    }

    return true;
  }

  const handleParaphrasing = () => {
    const rationales = ['CLOSEST_PT', 'GrammarCorrection', 'P2F'];
    setValue('rationales', rationales);
  }
  const handleInputCoverage = () => {
    const excludeRationales = ['DEFAULT', 'FOUND_MAIN_BRANCH', 'PASS_MAIN_BRANCH', 'CLOSEST_PT', 'GrammarCorrection', 'P2F'];

    const rationales = _.filter(Constant.CODE_RATIONALE_CD.map((item) => item.code), (item) => !_.includes(excludeRationales, item));
    setValue('rationales', rationales);
  }

  const showHl = (item) => {
    const hlList = [];
    if(item.prevNpcHL) {
      hlList.push({
        type: 'Npc',
        message : item.prevNpcHL,
      });
    }

    hlList.push({
      type: 'User',
      message : item.userHL || '',
    });

    if(item.npcHL) {
      hlList.push({
        type: 'Npc',
        message : item.npcHL,
      });
    }

    return (
      <>
        {
          hlList.map((hl, idx) =>
            <div key={idx} className={`${hl.className || ''} mb-1 hl-row`}>
              <span className="me-2">{`${hl.type} : `}</span> <span>{hl.message}</span>
            </div>
          )
        }
      </>
    );
  }
  const showSelectPt = (item) => {
    const pts = item.selectedPTs || [];
    return (
      <>
        {
          pts.map((pt, idx) =>
            <div key={idx} className="text-break">
              <span className="text-nowrap">{`${pt.sentence} (${pt.count})`}</span>
            </div>
          )
        }
      </>
    );
  }
  const showGcResult = (item) => {
    const gcResult = item.gcResult
    return (
        <>
          {
            <span className="text-nowrap">{gcResult? 'true' : 'false'}</span>
          }
        </>
    )
  }
  const showAudio = (row) => {
    let path = "https://helloludi-voice.s3.ap-northeast-2.amazonaws.com/" + row.original.filename
    return (
        <>
          {
            row.original.filename &&
            <a href="#!" title="play audio" onClick={(e) => handleaudioPlayModalShow(e, path)}>
              <span className="material-icons">play_circle_outline</span>
            </a>
          }
        </>
    )
  }
  const handleaudioPlayModalShow = (e, path) => {
    e.preventDefault();
    setAudioPlayModalItem({
      isShow: true,
      url : path
    });
  }
  const handleaudioPlayModalHide = () => {
    setAudioPlayModalItem({
      isShow: false,
      url : ''
    });
  }
  const columns = [
    {
      Header: "Student\nId",
      accessor: "studentId",
      textAlign: 'center',
      tdClassName: 'text-break',
      width: '2%'
    },
    {
      Header: "Student Name",
      accessor: "studentName",
      textAlign: 'center',
      tdClassName: 'text-break',
      width: '2%'
    },
    {
      Header: "Mission\nId",
      accessor: "missionId",
      textAlign: 'center',
      tdClassName: 'text-break',
      width: '10%'
    },
    {
      Header: "Task Id",
      accessor: "taskId",
      textAlign: 'center',
      tdClassName: 'text-break',
      width: '11%'
    },
    {
      Header: "Turn Id",
      accessor: "turnId",
      textAlign: 'center',
      tdClassName: 'text-break',
      width: '40%'
    },
    {
      Header: "HL",
      accessor: "",
      textAlign: 'left',
      width: "40%",
      tdClassName : 'p-0',
      Cell: ({ row }) => showHl(row.original),
    },
    {
      Header: "rationale",
      accessor: "rationale",
      textAlign: 'center',
      tdClassName: 'text-break',
      width: '5%'
    },
    {
      Header: "ptClick",
      accessor: "individualPtClickCount",
      textAlign: 'center',
      tdClassName: 'text-break',
      width: '5%'
    },
    {
      Header: "korClick",
      accessor: "korButtonClickCount",
      textAlign: 'center',
      tdClassName: 'text-break',
      width: '5%'
    },
    {
      Header: "create\nDate",
      accessor: "createDate",
      textAlign: 'center',
      tdClassName: 'text-break',
      width: '10%'
    },
    {
      Header: "select PT",
      accessor: "createDate",
      textAlign: 'left',
      tdClassName: 'text-break',
      width: '7%',
      Cell: ({ row }) => showSelectPt(row.original),
    },
    {
      Header: "gc\nResult",
      accessor: "gcResult",
      textAlign: 'center',
      tdClassName: 'text-break',
      width: '3%',
      Cell: ({ row }) => showGcResult(row.original),
    }
    ,{
      Header: "intent\nGroup\nName",
      accessor: "intentGroupName",
      textAlign: 'center',
      tdClassName: 'text-break',
      width: '1%'
    }
    ,{
      Header: "intent\nEntity",
      accessor: "intentEntity",
      textAlign: 'center',
      tdClassName: 'text-break',
      width: '3%'
    },
    {
      Header: "order",
      accessor: "order",
      textAlign: 'center',
      tdClassName: 'text-break',
      width: '1%'
    },
    {
      Header: "build code",
      accessor: "buildCode",
      textAlign: "center",
      tdClassName: 'text-break',
      width: "3%"
    },
    {
      Header: "audio",
      accessor: "fileName",
      textAlign: "center",
      tdClassName: 'text-break',
      width: "1%",
      Cell: ({ row }) => showAudio(row)
    }
  ];

  const rationaleOptions = Constant.CODE_RATIONALE_CD.map((code) => {
    return {
      label: code.name,
      value: code.code
    }
  });
  const fnPaging = async (pageNum) => {
    setValue('currentPage', pageNum);
    await refetchSearch();
  };
  return (
    <>
      <div className="contents-fluid">
        <HelmetWrapper meta={{
          title : 'Dm Log'
        }} />

        <div className="page-title">
          Dm Log
        </div>

        <div className="search-condition">
          <Form onSubmit={handleSearch} onReset={reset} className="w-auto">
            <Row>
              <Col xs="6" className=" mb-4">
                <Form.Group className="form-group required mb-0">
                  <Form.Label>Date</Form.Label>
                </Form.Group>
                <div className="condition-date-picker">
                  <Form.Group className="form-group mb-0">
                    <Controller
                      name="startDate"
                      control={control}
                      rules={ {required: true} }
                      render={({ field, fieldState }) =>
                        <DatePicker
                          {...field}
                          dateFormat="yyyy/MM/dd HH:mm"
                          selected={field.value}
                          showTimeSelect
                          timeIntervals={60}
                          autoComplete="off"
                          onChange={(date) => {
                            field.onChange(date);
                          }}
                          className={fieldState.invalid ? 'is-invalid' : ''}
                          customInput={ <DatePickerInput /> }
                        />
                      }
                    />
                    <ErrorMessageFeedback error={errors.startDate} />
                  </Form.Group>

                  <div className='ms-2 me-2'> ~ </div>

                  <Form.Group className="form-group mb-0">
                    <Controller
                      name="endDate"
                      control={control}
                      rules={ {required: true} }
                      render={({ field, fieldState }) =>
                        <DatePicker
                          {...field}
                          dateFormat="yyyy/MM/dd HH:mm"
                          selected={field.value}
                          showTimeSelect
                          timeIntervals={60}
                          autoComplete="off"
                          onChange={(date) => {
                            field.onChange(date);
                          }}
                          className={fieldState.invalid ? 'is-invalid' : ''}
                          customInput={ <DatePickerInput /> }
                        />
                      }
                    />
                    <ErrorMessageFeedback error={errors.endDate} />
                  </Form.Group>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs="12" md="6">
                <Form.Group className="form-group" controlId="id_buildCode">
                  <Form.Label>Build Code</Form.Label>
                  <Controller
                      name="buildCode"
                      control={control}
                      render={({ field }) =>
                          <Form.Control
                              {...field}
                              type="text"
                              placeholder="포함"
                          />
                      }
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs="12" md="6">
                <Form.Group className="form-group" controlId="id_npcHL">
                  <Form.Label>NPC HL</Form.Label>
                  <Controller
                    name="npcHL"
                    control={control}
                    render={({ field }) =>
                      <Form.Control
                        {...field}
                        type="text"
                        placeholder="포함"
                      />
                    }
                  />
                </Form.Group>
              </Col>
              <Col xs="12" md="6">
                <Form.Group className="form-group" controlId="id_userHL">
                  <Form.Label>User HL</Form.Label>
                  <Controller
                    name="userHL"
                    control={control}
                    render={({ field }) =>
                      <Form.Control
                        {...field}
                        type="text"
                        placeholder="포함"
                      />
                    }
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs="12" md="6">
                <Form.Group className="form-group" controlId="id_studentIdText">
                  <Form.Label>Student ID( <span className="fw-bold text-primary">{watchStudentIdList.length}</span> 건 )</Form.Label>
                  <Controller
                      name="studentIdText"
                      control={control}
                      render={({ field }) =>
                          <Form.Control
                              {...field}
                              as="textarea"
                              rows="5"
                              placeholder="줄바꿈(Enter)으로 studentId를 구분하여 넣어주세요"
                          />
                      }
                  />
                </Form.Group>
              </Col>
              <Col xs="12" md="6">
                <Form.Group className="form-group" controlId="id_studentNameText">
                  <Form.Label>Student Name( <span className="fw-bold text-primary">{watchStudentNameList.length}</span> 건 )</Form.Label>
                  <Controller
                      name="studentNameText"
                      control={control}
                      render={({ field }) =>
                          <Form.Control
                              {...field}
                              as="textarea"
                              rows="5"
                              placeholder="줄바꿈(Enter)으로 studentName을 구분하여 넣어주세요"
                          />
                      }
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs="12" md="6">
                <Form.Group className="form-group" controlId="id_turnIdText">
                  <Form.Label>Turn Id( <span className="fw-bold text-primary">{watchTurnIdList.length}</span> 건 )</Form.Label>
                  <Controller
                      name="turnIdText"
                      control={control}
                      render={({ field }) =>
                          <Form.Control
                              {...field}
                              as="textarea"
                              rows="5"
                              placeholder="줄바꿈(Enter)으로 turnId를 구분하여 넣어주세요"
                          />
                      }
                  />
                </Form.Group>
              </Col>
              <Col xs="12" md="6">
                <Form.Group className="form-group" controlId="id_taskIdText">
                  <Form.Label>Task ID( <span className="fw-bold text-primary">{watchTaskIdList.length}</span> 건 )</Form.Label>
                  <Controller
                      name="taskIdText"
                      control={control}
                      render={({ field }) =>
                          <Form.Control
                              {...field}
                              as="textarea"
                              rows="5"
                              placeholder="줄바꿈(Enter)으로 taskId를 구분하여 넣어주세요"
                          />
                      }
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs="12" md="6">
                <Form.Group className="form-group" controlId="id_userIdText">
                  <Form.Label>User ID( <span className="fw-bold text-primary">{watchUserIdList.length}</span> 건 )</Form.Label>
                  <Controller
                      name="userIdText"
                      control={control}
                      render={({ field }) =>
                          <Form.Control
                              {...field}
                              as="textarea"
                              rows="5"
                              placeholder="줄바꿈(Enter)으로 userId를 구분하여 넣어주세요"
                          />
                      }
                  />
                </Form.Group>
              </Col>
              <Col xs="12" md="6">
                <Form.Group className="form-group" controlId="id_missionIdText">
                  <Form.Label>Mission ID( <span className="fw-bold text-primary">{watchMissionIdList.length}</span> 건 )</Form.Label>
                  <Controller
                      name="missionIdText"
                      control={control}
                      render={({ field }) =>
                          <Form.Control
                              {...field}
                              as="textarea"
                              rows="5"
                              placeholder="줄바꿈(Enter)으로 userId를 구분하여 넣어주세요"
                          />
                      }
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs="12">
                <Form.Group className="form-group">
                  <Form.Label>Rationale</Form.Label>
                  <InputGroup>
                    <Controller
                        name="rationales"
                        control={control}
                        rules={ {required: false} }
                        render={({ field }) =>
                            <Select
                                {...field}
                                isMulti
                                closeMenuOnSelect={false}
                                options={rationaleOptions}
                                onChange={ (valueList) => {
                                  field.onChange(valueList.map((item) => item.value));
                                }}
                                value={
                                  field.value.map((v) => {
                                    return _.find(rationaleOptions, {'value' : v})
                                  })
                                }
                                className="w-80"
                            />
                        }
                    />
                    <Button variant="outline-secondary" type="button" onClick={handleParaphrasing}>패러프레이징</Button>
                    <Button variant="outline-secondary" type="button" onClick={handleInputCoverage}>입력 커버리지</Button>
                  </InputGroup>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs="12" className="btn-wrapper justify-content-between">
                <div>
                  <button className="btn btn-outline-secondary" type="button" onClick={downloadExcel}>
                    <i className="fas fa-file-download me-2"></i>Excel Download
                  </button>
                </div>
                <div>
                  <button className="btn btn-primary" type="submit">
                    <span className="material-icons">search</span>Search
                  </button>
                </div>
              </Col>
            </Row>
          </Form>
        </div>

        <div className="search-result">
          <div className="sub-title">검색 결과</div>

          <div className="table-wrapper">
            <div className="count-block">
              <span>Showing {result.startIndex}-{result.endIndex} of {result.totalCnt} results</span>
            </div>

            <DefaultTable columns={columns}
                          data={result.resultList}
                          />

            <Paging totalPages={result.totalPages}
                    currentPage={result.currentPage}
                    prevPage={result.prevPage}
                    nextPage={result.nextPage}
                    startPage={result.startPage}
                    endPage={result.endPage}
                    displayPageNum={result.displayPageNum}
                    fnPaging={fnPaging} />
          </div>
        </div>
      </div>
      <AudioPlayModal
          onHide={handleaudioPlayModalHide}
          modalItem={audioPlayModalItem}
          autoPlay={true}
      />
    </>
  );
}

export default DmLog;