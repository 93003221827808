import {useCallback, useEffect, useMemo} from "react";
import {http} from "../../../utils";
import {useQuery} from "react-query";
import {useForm} from "react-hook-form";
import SortingTable from "../../../components/material/SortingTable";
import Paging from "../../../components/common/Paging";

function HistoryOrderData({student={}, forceRefresh}) {
    const initValue = useMemo(() => {
        return {
            orderId:'',
            userId:'',
            studentId:'',
            marketType:'',
            receipt:'',
            ludiPassType:'',
            createDate:''
        }
    }, []);
    const {setValue} = useForm({
        mode: 'onChange',
        defaultValues : { ...initValue }
    });

    const { data: result, refetch : refetchSearch } = useQuery(
        ['searchHistoryLudiPass'],
        async () => {
            const param = {
                userId : student?.userId
            }
            const result = await http.get(`/api/v1/edit/ludipass/history/order-data`,{params: param});
            return result.data;
        },
        {
            enabled: false,
            initialData: []
        });
    useEffect(() => {
        async function fetchData() {
            await refetchSearch();
        }
        fetchData();
    }, [student, forceRefresh, refetchSearch, setValue]);

    const fnHistoryOrderDataValidPaging = async (pageNum) => {
        setValue('currentPage', pageNum);
        await refetchSearch();
    };
    const defaultHistoryOrderDataSorted = useMemo(() => ({ createDate: 'createDate', desc: false }), []);
    const fnHistoryOrderDataSort = useCallback(async (sortBy) => {
        setValue('sortByName', sortBy.id);
        setValue('desc', sortBy.desc);

        await refetchSearch();
    }, [setValue, refetchSearch]);
    const columns = [
        {
          Header: "Id",
          accessor: "id",
          textAlign: 'center'
        },
        {
            Header: "Market Type",
            accessor: "marketType",
            textAlign: 'center',
        },
        {
            Header: "Ludi Pass Type",
            accessor: "ludiPassType",
            textAlign: 'center',
        },
        {
            Header: "History Create Date",
            accessor: "createDate",
            textAlign: 'center',
        }
    ];
    return (
        <>
            <div className="search-result">
                <div className="sub-title">구매 내역</div>

                <div className="table-wrapper">
                    <div className="count-block justify-content-between">
                        <div>
                        </div>
                        <div>
                            <span>Showing {result.startIndex}-{result.endIndex} of {result.totalCnt} results</span>
                        </div>
                    </div>
                    <SortingTable columns={columns}
                                  data={result.resultList}
                                  defaultSorted={defaultHistoryOrderDataSorted}
                                  manualSortBy={true}
                                  fnSort={fnHistoryOrderDataSort} />

                    <Paging totalPages={result.totalPages}
                            currentPage={result.currentPage}
                            prevPage={result.prevPage}
                            nextPage={result.nextPage}
                            startPage={result.startPage}
                            endPage={result.endPage}
                            displayPageNum={result.displayPageNum}
                            fnPaging={fnHistoryOrderDataValidPaging} />
                </div>
            </div>
        </>
    )
}
export default HistoryOrderData;