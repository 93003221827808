import {useEffect, useMemo, useRef} from "react";
import {Controller, useForm} from "react-hook-form";
import {useMutation, useQuery} from "react-query";
import HelmetWrapper from "../../../components/common/HelmetWrapper";
import {Col, Form, Row, Table} from "react-bootstrap";
import ErrorMessageFeedback from "../../../components/common/ErrorMessageFeedback";
import {http, Alert, toast} from '../../../utils';
import _ from "lodash";

/**
 * 기존의 message status 메뉴를 maintenance 으로 이름 변경
 * routine maintenance도 message server status 를 기반으로 동작함
 */
function RoutineMaintenance() {
  const initValue = useMemo(() => {
    return {
      subject: '',
      message: '',
      allowIpText: '',
      allowIps: [],
      dayRoutines:[
          {"day":"SUN", "isActive":false},
          {"day":"MON","isActive":false},
          {"day":"TUE","isActive":false},
          {"day":"WED","isActive":false},
          {"day":"THU","isActive":false},
          {"day":"FRI","isActive":false},
          {"day":"SAT","isActive":false}
      ],
    }
  }, []);

  const refAllowIpText = useRef();

  const {handleSubmit, reset, formState: {errors}, control, watch, setValue} = useForm({
    mode: 'onChange',
    defaultValues : { ...initValue }
  });

  const {data: result, refetch: refetchSearch} = useQuery(
      ['searchRoutineMaintenance'],
      async () => {
        const result = (await http.get(`/api/v1/message/status/routine`)).data;
        if(result.length === 0) {
          return {...initValue}
        } else {
          setDayRoutinesToComponent(result.dayRoutines)
          return result
        }
      },
      {
        initialData: {},
        onSuccess: (data) => {
          const result = {
            ...data,
            allowIps : data.allowIps || [],
            allowIpText : _.join(data.allowIps || [], '\n')
          }
          reset({ ...result });
        }
      });

  const watchAllowIpText = watch('allowIpText', '');
  const watchAllowIps = watch('allowIps', []);

  useEffect(() => {
    const list = _.split(watchAllowIpText, /\r?\n/)
        .map(v => v.trim())
        .filter((v) => v.length !== 0);

    setValue('allowIps', list);
  }, [watchAllowIpText, setValue]);

  const setRoutine = useMutation((param) => http.post('/api/v1/message/status/routine', param));
  const onValid = (e) => {
    e.preventDefault();

    handleSubmit(async (data) => {
      const confirmMsg = '스케줄을 저장하시겠습니까?';
      const completeMsg = '완료 되었습니다.';

      let sendData = {}
      sendData = result
      sendData.subject = data.subject
      sendData.message = data.message
      sendData.allowIps = data.allowIps
      
      Alert.confirm(confirmMsg, async () => {
        await setRoutine.mutateAsync(sendData);
        toast.success(completeMsg);
        await refetchSearch();
      });
    })();
  };

  const onChange = (e) => {
    e.preventDefault();
    const componentId = e.target.id
    const componentIdx = componentId.substring(componentId.length-1)
    const componentName = componentId.substring(0, componentId.length-1)
    let componentValue = e.target.value

    if(componentValue === "true" || componentValue === "false") {
      componentValue = JSON.parse(componentValue)
      handleIsReadOnlyChange(componentValue, componentIdx)
    }
    result.dayRoutines[componentIdx][componentName] = componentValue
  }

  const handleServerOn = () => {
    const confirmMsg = '현재 서버 접속이 차단된 상태입니다. 접속을 다시 허용하시겠습니까?'
    const completeMsg = '서버 접속 허용되었습니다.'

    let sendData = {}
    sendData = result
    sendData.status = 'ON'

    Alert.confirm(confirmMsg, async () => {
      await  setRoutine.mutateAsync(sendData);
      toast.success(completeMsg)
      await refetchSearch();
    })
  }
  /**
   * query selector는 권장되지 않는 방법으로, 향후 개선이 필요함
   */
  const handleIsReadOnlyChange = (componentValue, componentIdx) => {
    if(componentValue) {
      document.querySelector("#startTime" + componentIdx).readOnly = false
      document.querySelector("#startTime" + componentIdx).required = true
      document.querySelector("#duration" + componentIdx).readOnly = false
      document.querySelector("#duration" + componentIdx).required = true
    } else {
      document.querySelector("#startTime" + componentIdx).readOnly = true
      document.querySelector("#startTime" + componentIdx).required = false
      document.querySelector("#duration" + componentIdx).readOnly = true
      document.querySelector("#duration" + componentIdx).required = false
    }
  }

  const setDayRoutinesToComponent = (dayRoutines) => {
    for(let i=0; i<=6; i++) {
      document.querySelector("#isActive" + i).value = dayRoutines[i].isActive.toString()
      document.querySelector("#startTime" + i).value = dayRoutines[i].startTime
      document.querySelector("#duration" + i).value = dayRoutines[i].duration
      handleIsReadOnlyChange(dayRoutines[i].isActive, i)
    }
  }

  const columns = [
    {
      Header: "is Active",
      accessor: "isActive",
      textAlign: 'center',
    },
    {
      Header: "DAY",
      accessor: "day",
      textAlign: 'center',
    },
    {
      Header: "START",
      accessor: "startTime",
      textAlign: 'center',
    },
    {
      Header: "DURATION",
      accessor: "duration",
      textAlign: 'center',
    }
  ];
  const week = [
      "SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"
  ]
  return (
      <>
        <div className="contents-fluid">
          <HelmetWrapper meta={{
            title : 'Routine Maintenance'
          }} />
          <div className="page-title">
            Routine Maintenance
          </div>
          <div className="mt-4">
            <Form onSubmit={onValid} onReset={reset} className="w-100">
              <Row>
                <Col xs="12">
                  <Form.Group className="form-group required" controlId="id_subject">
                    <Form.Label>Subject</Form.Label>
                    <Controller
                        name="subject"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) =>
                            <Form.Control
                                {...field}
                                type="text"
                                maxLength="100"
                                placeholder=""
                                isInvalid={errors.subject}
                            />
                        }
                    />
                    <ErrorMessageFeedback error={errors.subject} />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xs="12">
                  <Form.Group className="form-group required" controlId="id_message">
                    <Form.Label>Message</Form.Label>
                    <Controller
                        name="message"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) =>
                            <Form.Control
                                {...field}
                                as="textarea"
                                rows="10"
                                placeholder=""
                                isInvalid={errors.message}
                            />
                        }
                    />
                    <ErrorMessageFeedback error={errors.message} />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xs="12">
                  <Form.Group className="form-group" controlId="id_allowIpText">
                    <Form.Label>Allow IP ( <span className="fw-bold text-primary">{watchAllowIps.length}</span> 건 )</Form.Label>
                    <Controller
                        name="allowIpText"
                        control={control}
                        render={({ field }) =>
                            <Form.Control
                                {...field}
                                as="textarea"
                                rows="5"
                                placeholder="줄바꿈 으로 (Enter) IP를 구분하여 넣어주세요"
                                ref={refAllowIpText}
                            />
                        }
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xs="12" className="btn-wrapper d-flex justify-content-end">
                  {
                    <button className="btn btn-primary" type="submit">
                      <span className="material-icons">schedule</span>&nbsp;Proceed
                    </button>
                  }
                </Col>
              </Row>
              <Row style={{marginTop: 20}}>
                  <Table responsive bordered hover>
                    <thead className="table-dark">
                    <tr>
                      {
                        columns.map((header, i) => {
                          const headerProps = {
                            style: {
                              minWidth: header.minWidth,
                              width: header.width,
                              textAlign: 'center'
                            }
                          };
                          return (
                              <th key={i} {...headerProps}>
                                <span>{header.Header}</span>
                              </th>
                          );
                        })
                      }
                    </tr>
                    </thead>
                    <tbody>
                    {
                      week.map((value, rowIdx) => {
                        return (
                            <tr key={rowIdx}>
                              <td>
                                <Form.Group className="mb-0 ms-0" controlId={`isActive${rowIdx}`}>
                                  <Form.Select aria-label="Default select example" onChange={onChange} >
                                    <option value="false">OFF</option>
                                    <option value="true">ON</option>
                                  </Form.Select>
                                </Form.Group>
                              </td>
                              <td>
                                <Form.Group className="required" controlId={`day${rowIdx}`}>
                                  <Form.Control
                                      type="text"
                                      maxLength="3"
                                      value={value}
                                      readOnly={true}
                                      placeholder=""
                                  />
                                </Form.Group>
                              </td>
                              <td>
                                <Form.Group className="form-group mb-0 ms-0" controlId={`startTime${rowIdx}`}>
                                  <Controller
                                      name={`startTime${rowIdx}`}
                                      control={control}
                                      render={({ field }) =>
                                          <Form.Control
                                              {...field}
                                              type="time"
                                              readOnly={true}
                                              onChange={onChange}
                                          />
                                      }
                                  />
                                </Form.Group>
                              </td>
                              <td>
                                <Form.Group className="form-group mb-0 ms-0" controlId={`duration${rowIdx}`}>
                                  <Controller
                                      name={`duration${rowIdx}`}
                                      control={control}
                                      render={({ field }) =>
                                          <Form.Control
                                              {...field}
                                              type="number"
                                              min="1"
                                              max="24"
                                              placeholder="단위: 시간(HOUR)"
                                              readOnly={true}
                                              onChange={onChange}
                                          />
                                      }
                                  />
                                </Form.Group>
                              </td>
                            </tr>
                        )
                    })
                    }
                    </tbody>
                  </Table>
              </Row>
            </Form>
          </div>
        </div>
        {
          result.status === 'OFF' &&
            <div>
              <Row>
                <Col xs="12" className="btn-wrapper d-flex justify-content-end">
                  {
                    <button className="btn btn-danger" onClick={handleServerOn}>
                      <span className="material-icons">lock_open</span>&nbsp;서버 접속 허용
                    </button>
                  }
                </Col>
              </Row>
            </div>
        }
      </>
  );
}

export default RoutineMaintenance;