import HelmetWrapper from "../../../components/common/HelmetWrapper";
import {Col, Row} from "react-bootstrap";
import {Fragment, useCallback, useMemo, useRef, useState} from "react";
import readXlsxFile from "read-excel-file";
import DefaultTable from "../../../components/material/DefaultTable";
import {http, Alert} from '../../../utils';
import {useMutation} from "react-query";
import {toast} from "react-toastify";

function EditLudiPassBulk () {
    const [resultList, setResultList] = useState([])
    const [filename, setFilename] = useState("")

    const fileInput = useRef(null);

    const handleExcelUpload = e => {
        fileInput.current.click();
    }

    const handleExcelUploadChange = async (e) => {
        e.preventDefault()

        console.log(e.target.files[0])
        const map = {
            UserID: "userId",
            ExpiryDate: "expireDate",
            Log: "message"
        }
        readXlsxFile(e.target.files[0], {map}).then((result => {
            setFilename(e.target.files[0].name)
            setResultList(result.rows)
        }))
    }

    const defaultSorted = useMemo(() => ({ id: 'userId', desc: true }), []);

    const fnSort = useCallback(async (sortBy) => {
    },[])

    const columns = useMemo(() => [
        {
            Header: "User ID",
            accessor: "userId",
            textAlign: 'center',
        },
        {
            Header: "Expiry Date",
            accessor: "expireDate",
            textAlign: 'center',
        },
        {
            Header: "Log",
            accessor: "message",
            textAlign: 'center',
        }
    ],[])

    const updateAction = useMutation((param) => http.put("/api/v1/edit/ludipass/bulk", param));
    const handleUpdate = async () => {
        const confirmMsg = '루디패스 만료 날짜를 변경하시겠습니까?'
        const completeMsg = '변경 되었습니다.';

        Alert.confirm(confirmMsg, async () => {
            await updateAction.mutateAsync(resultList)
            toast.success(completeMsg)
        })
    }
    return (
        <>
            <div className="contents-fluid">
                <HelmetWrapper meta={{
                    title : 'Edit Ludi Pass(Bulk)'
                }} />
                <div className="page-title">
                    Edit Ludi Pass(Bulk)
                </div>
                <div className="search-condition">
                    <Row>
                        <Col xs="12" className="btn-wrapper justify-content-end">
                            <span>업로드 파일명: {filename}</span>
                            &nbsp;&nbsp;&nbsp;
                            <Fragment>
                                <button className="btn btn-success" onClick={handleExcelUpload}>
                                    <span className="material-icons">file_upload</span>Excel Upload
                                </button>
                                <input type="file"
                                       name="file"
                                       ref={fileInput}
                                       onChange={handleExcelUploadChange}
                                       style={{ display: "none" }} />
                            </Fragment>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="12" className="btn-wrapper justify-content-end">
                            <button className="btn btn-danger" onClick={handleUpdate}>
                                <span className="material-icons">done</span>Update Data
                            </button>
                        </Col>
                    </Row>
                </div>
                <div className="page-title">
                    업로드 내역
                </div>
                <div className="search-result">
                    <DefaultTable columns={columns}
                                  data={resultList}
                                  defaultSorted={defaultSorted}
                                  fnSort={fnSort}/>
                </div>
            </div>
        </>
    )
}
export default EditLudiPassBulk;