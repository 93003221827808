import {useEffect, useMemo} from "react";
import {Controller, useForm} from "react-hook-form";
import {useQuery} from "react-query";
import { http, SearchParamUtils } from '../../../utils';
import { history } from "../../../router/History";
import HelmetWrapper from "../../../components/common/HelmetWrapper";
import {Col, Form, Row} from "react-bootstrap";
import Paging from "../../../components/common/Paging";
import {Link, useLocation} from "react-router-dom";
import DefaultTable from "../../../components/material/DefaultTable";

function StudentInfo() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const defaultSorted = useMemo(() => ({ id: 'id', desc: true }), []);
  const initValue = useMemo(() => {
    return {
      userId: '',
      studentId: '',
      phoneNumber: '',
      studentName: '',
      ...defaultSorted
    }
  }, [defaultSorted]);

  const {handleSubmit, reset, control, setValue, getValues} = useForm({
    defaultValues : { ...initValue, ...SearchParamUtils.searchParamToJsonObject(searchParams) }
  });

  const { data: result, refetch : refetchSearch } = useQuery(
    ['searchStudentList'],
    async () => {
      const result = await http.get(`/api/v1/user/student`,{params: getValues()});
      result.data.resultList.forEach((item) => {
        item.loginDate = item.lastAccesses.endDate
      })
      return result.data;
    },
    {
      enabled: false,
      initialData: []
    });

  const pushHistory = () => {
    const searchParams = new URLSearchParams(getValues());
    history.push(`/adms/user/student-info/view?${searchParams.toString()}`);
  };

  useEffect(() => {
    async function fetchData() {
      await refetchSearch();
    }
    const params = ['userId', 'studentId', 'phoneNumber', 'studentName'];

    for(const idx in params) {
      if(getValues(params[idx])) {
        fetchData();
        break;
      }
    }
  }, [refetchSearch, getValues]);

  const fnSort = async (sortBy) => {
    setValue('sortByName', sortBy.id);
    setValue('desc', sortBy.desc);
    pushHistory();

    await refetchSearch();
  };

  const fnPaging = async (pageNum) => {
    setValue('currentPage', pageNum);
    pushHistory();
    await refetchSearch();
  };

  const onValid = async () => {
    setValue('currentPage', 1);
    pushHistory();
    await refetchSearch();
  };

  const columns = [
      {
        Header: "User ID",
        accessor: "userId",
        textAlign: 'center',
      },
      {
        Header: "Student ID",
        accessor: "id",
        textAlign: 'center',
        Cell: ({ row }) => (
          <Link
            to={`/adms/user/student-info/detail/view?studentId=${row.original.id}`}>
            {row.original.id}
          </Link>),
      },
      {
        Header: "Student Name",
        accessor: "name",
        textAlign: 'center',
      },
      {
        Header: "Phone",
        accessor: "phoneNumber",
        textAlign: 'center',
      },
      {
        Header: "Login Date",
        accessor: "loginDate",
        textAlign: 'center',
      },
      {
        Header: "DeleteDate",
        accessor: "deleteDate",
        textAlign: 'center',
      }
    ];

  return (
    <>
      <div className="contents-fluid">
        <HelmetWrapper meta={{
          title : 'Student Info'
        }} />

        <div className="page-title">
          Student Info
        </div>

        <div className="search-condition">
          <Form onSubmit={handleSubmit(onValid)} onReset={reset} className="w-100">
            <Row>
              <Col xs="12" md="6">
                <Form.Group className="form-group" controlId="id_userId">
                  <Form.Label>User ID</Form.Label>
                  <Controller
                    name="userId"
                    control={control}
                    render={({ field }) =>
                      <Form.Control
                        {...field}
                        type="text"
                        placeholder="일치"
                      />
                    }
                  />
                </Form.Group>
              </Col>
              <Col xs="12" md="6">
                <Form.Group className="form-group" controlId="id_studentId">
                  <Form.Label>Student ID</Form.Label>
                  <Controller
                    name="studentId"
                    control={control}
                    render={({ field }) =>
                      <Form.Control
                        {...field}
                        type="text"
                        placeholder="일치"
                      />
                    }
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs="12" md="6">
                <Form.Group className="form-group" controlId="id_phoneNumber">
                  <Form.Label>Phone</Form.Label>
                  <Controller
                    name="phoneNumber"
                    control={control}
                    render={({ field }) =>
                      <Form.Control
                        {...field}
                        type="text"
                        placeholder="포함"
                      />
                    }
                  />
                </Form.Group>
              </Col>
              <Col xs="12" md="6">
                <Form.Group className="form-group" controlId="id_studentName">
                  <Form.Label>Student Name</Form.Label>
                  <Controller
                    name="studentName"
                    control={control}
                    render={({ field }) =>
                      <Form.Control
                        {...field}
                        type="text"
                        placeholder="포함"
                      />
                    }
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs="12" className="btn-wrapper">
                <button className="btn btn-primary" type="submit">
                  <span className="material-icons">search</span>Search
                </button>
              </Col>
            </Row>
          </Form>
        </div>

        <div className="search-result">
          <div className="sub-title">검색 결과</div>

          <div className="table-wrapper">
            <div className="count-block">
              <span>Showing {result.startIndex}-{result.endIndex} of {result.totalCnt} results</span>
            </div>

            <DefaultTable columns={columns}
                          data={result.resultList}
                          defaultSorted={defaultSorted}
                          fnSort={fnSort} />

            <Paging totalPages={result.totalPages}
                    currentPage={result.currentPage}
                    prevPage={result.prevPage}
                    nextPage={result.nextPage}
                    startPage={result.startPage}
                    endPage={result.endPage}
                    displayPageNum={result.displayPageNum}
                    fnPaging={fnPaging} />
          </div>
        </div>
      </div>
    </>
  );
}

export default StudentInfo;