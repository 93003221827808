import {useMemo} from "react";
import {Controller, useForm} from "react-hook-form";
import {useMutation, useQuery} from "react-query";
import {Button, Col, Form, Row} from "react-bootstrap";
import HelmetWrapper from "../../../components/common/HelmetWrapper";
import DefaultTable from "../../../components/material/DefaultTable";
import Paging from "../../../components/common/Paging";
import {http, Constant, Alert, toast} from "../../../utils";
import DatePicker from "react-datepicker";
import DatePickerInput from "../../../components/material/DatePickerInput";
import ErrorMessageFeedback from "../../../components/common/ErrorMessageFeedback";
import {Link} from "react-router-dom";

function StudentLink() {
  const initValue = useMemo(() => {
    return {
      startDate: '',
      endDate: '',
      studentId: '',
      studentName: '',
      statusCd: '',
    }
  }, []);

  const {handleSubmit, reset, formState: {errors}, control, setValue, getValues} = useForm({
    mode: 'onChange',
    defaultValues : { ...initValue }
  });

  const { data: result, refetch : refetchSearch } = useQuery(
    ['searchStudentLink'],
    async () => {
      return (await http.get(`/api/v1/parents/student-link`,{params: getValues()})).data;
    },
    {
      enabled: false,
      initialData: []
    });

  const deleteAction = useMutation((id) => http.delete(`/api/v1/parents/student-link/${id}`));

  const onValid = async () => {
    setValue('currentPage', 1);
    await refetchSearch();
  };

  const handleDelete = (e, data) => {
    e.preventDefault();
    const confirmMsg = '정말 삭제하시겠습니까?';
    const completeMsg = '완료 되었습니다.';

    Alert.confirm(confirmMsg, async () => {
      await deleteAction.mutateAsync(data.id);

      toast.success(completeMsg);
      await onValid();
    });
  };

  const fnPaging = async (pageNum) => {
    setValue('currentPage', pageNum);
    await refetchSearch();
  };

  const columns = [
    {
      Header: "Name",
      accessor: "studentName",
      textAlign: 'center',
      Cell: ({ row }) => (<Link to={`/adms/parents/student-link/detail/view/${row.original.id}`}>{row.original.studentName}</Link>),
    },
    {
      Header: "realName",
      accessor: "realName",
      textAlign: 'center',
    },
    {
      Header: "status",
      accessor: "statusCd",
      textAlign: 'center',
    },
    {
      Header: "Phone",
      accessor: "phoneNumber",
      textAlign: 'center',
    },
    {
      Header: "Create Date",
      accessor: "createDate",
      textAlign: 'center',
    },
    {
      Header: "Complete Date",
      accessor: "completeDate",
      textAlign: 'center',
    },
    {
      Header: "",
      accessor: "userId",
      textAlign: 'center',
      disableSortBy: true,
      Cell: ({ row }) => (<Button type="button"
                                  variant="outline-danger"
                                  onClick={(e) => handleDelete(e, row.original)}>
        <span className="material-icons">delete_outline</span>Delete</Button>),
    }
  ];

  return (
    <>
      <div className="contents-fluid">
        <HelmetWrapper meta={{
          title : '학습자 연동'
        }} />

        <div className="page-title">
          학습자 연동
        </div>

        <div className="search-condition">
          <Form onSubmit={handleSubmit(onValid)} onReset={reset} className="w-100">
            <Row>
              <Col xs="6" className="mb-4">
                <Form.Group className="form-group mb-0">
                  <Form.Label>Create Date</Form.Label>
                </Form.Group>
                <div className="condition-date-picker">
                  <Form.Group className="form-group mb-0">
                    <Controller
                      name="startDate"
                      control={control}
                      rules={ {required: false} }
                      render={({ field, fieldState }) =>
                        <DatePicker
                          {...field}
                          dateFormat="yyyy/MM/dd HH:mm"
                          selected={field.value}
                          showTimeSelect
                          timeIntervals={60}
                          autoComplete="off"
                          onChange={(date) => {
                            field.onChange(date);
                          }}
                          className={fieldState.invalid ? 'is-invalid' : ''}
                          customInput={ <DatePickerInput /> }
                        />
                      }
                    />
                    <ErrorMessageFeedback error={errors.startDate} />
                  </Form.Group>

                  <div className='ms-2 me-2'> ~ </div>

                  <Form.Group className="form-group mb-0">
                    <Controller
                      name="endDate"
                      control={control}
                      rules={ {required: false} }
                      render={({ field, fieldState }) =>
                        <DatePicker
                          {...field}
                          dateFormat="yyyy/MM/dd HH:mm"
                          selected={field.value}
                          showTimeSelect
                          timeIntervals={60}
                          autoComplete="off"
                          onChange={(date) => {
                            field.onChange(date);
                          }}
                          className={fieldState.invalid ? 'is-invalid' : ''}
                          customInput={ <DatePickerInput /> }
                        />
                      }
                    />
                    <ErrorMessageFeedback error={errors.endDate} />
                  </Form.Group>
                </div>
              </Col>
              <Col xs="6">
                <Form.Group className="form-group" controlId="id_studentId">
                  <Form.Label>Student ID</Form.Label>
                  <Controller
                    name="studentId"
                    control={control}
                    render={({ field }) =>
                      <Form.Control
                        {...field}
                        type="text"
                        placeholder="일치"
                      />
                    }
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs="12" md="6">
                <Form.Group className="form-group" controlId="id_studentName">
                  <Form.Label>Student Name</Form.Label>
                  <Controller
                    name="studentName"
                    control={control}
                    render={({ field }) =>
                      <Form.Control
                        {...field}
                        type="text"
                        placeholder="포함"
                      />
                    }
                  />
                </Form.Group>
              </Col>
              <Col xs="12" md="6">
                <Form.Group className="form-group" controlId="id_statusCd">
                  <Form.Label>Status</Form.Label>
                  <Controller
                    name="statusCd"
                    control={control}
                    render={({ field }) =>
                      <Form.Control
                        as="select"
                        className="form-select"
                        {...field}
                        value={field.value || ''}
                      >
                        <option value=""></option>
                        {
                          Constant.CODE_STUDENT_LINK_STATUS.map((item, idx) => {
                            return <option key={idx} value={item.code}>{item.name}</option>
                          })
                        }
                      </Form.Control>
                    }
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs="6" className="btn-wrapper justify-content-start">
                <Link to="/adms/parents/student-link/regist">
                  <button className="btn btn-outline-success" type="button">
                    <span className="material-icons">add</span>강제 연동
                  </button>
                </Link>
              </Col>
              <Col xs="6" className="btn-wrapper">
                <button className="btn btn-primary" type="submit">
                  <span className="material-icons">search</span>Search
                </button>
              </Col>
            </Row>
          </Form>
        </div>

        <div className="search-result">
          <div className="sub-title">검색 결과</div>

          <div className="table-wrapper">
            <div className="count-block">
              <span>Showing {result.startIndex}-{result.endIndex} of {result.totalCnt} results</span>
            </div>

            <DefaultTable columns={columns}
                          data={result.resultList}
            />

            <Paging totalPages={result.totalPages}
                    currentPage={result.currentPage}
                    prevPage={result.prevPage}
                    nextPage={result.nextPage}
                    startPage={result.startPage}
                    endPage={result.endPage}
                    displayPageNum={result.displayPageNum}
                    fnPaging={fnPaging} />
          </div>
        </div>
      </div>
    </>
  );
}

export default StudentLink;