import {useMemo, useEffect, useCallback, useState} from "react";
import {Form, InputGroup } from "react-bootstrap";
import {Controller, useForm} from "react-hook-form";
import { http } from '../../../utils';
import {useQuery} from "react-query";
import SortingTable from '../../../components/material/SortingTable';
import Paging from "../../../components/common/Paging";
import {Link} from "react-router-dom";
import StudentMissionDetailModal from "./StudentMissionDetailModal";

function StudentMission({ studentId, reSearchFlag }) {
  const initValue = useMemo(() => {
    return {
      missionId: ''
    }
  }, []);

  const [modalItem, setModalItem] = useState({
    isShow: false,
    item: {}
  });

  const {handleSubmit, reset, control, watch, setValue} = useForm({
    defaultValues : { ...initValue }
  });

  const { data: result, refetch : refetchSearch } = useQuery(
    ['searchStudentMission'],
    async () => {
      if(!studentId) {
        return {};
      }
      const result = await http.get(`/api/v1/user/student/${studentId}/mission`,{params: watch()});
      return result.data;
    },
    {
      enabled: false,
      initialData: []
    });

  useEffect(() => {
    async function fetchData() {
      await refetchSearch();
    }
    setValue('studentId', studentId);
    setValue('currentPage', 1);
    fetchData();
  }, [studentId, reSearchFlag, refetchSearch, setValue]);

  const fnSort = useCallback(async (sortBy) => {
    setValue('sortByName', sortBy.id);
    setValue('desc', sortBy.desc);

    await refetchSearch();
  }, [setValue, refetchSearch]);

  const onValid = async () => {
    await refetchSearch();
  };

  const fnPaging = async (pageNum) => {
    setValue('currentPage', pageNum);
    await refetchSearch();
  };

  const handleModalShow = useCallback( (e, item) => {
    e.preventDefault();
    setModalItem({
      isShow: true,
      item: {...item, studentId: studentId}
    });
  }, [setModalItem, studentId]);

  const handleModalHide = () => {
    setModalItem({
      isShow: false,
      item: {}
    });
  };

  const defaultSorted = useMemo(() => ({ id: 'startDate', desc: true }), []);
  const columns = useMemo(() => [
      {
        Header: "ID",
        accessor: "missionId",
        textAlign: 'center',
        Cell: ({ row }) => (<Link to="#!" onClick={ e => handleModalShow(e, row.original)}>{row.original.missionId}</Link>),
      },
      {
        Header: "Mission Type",
        accessor: "missionType",
        textAlign: 'center',
      },
      {
        Header: "Mission Sub",
        accessor: "missionName",
        textAlign: 'center',
      },
      {
        Header: "Acquire Date",
        accessor: "startDate",
        textAlign: 'center',
      },
      {
        Header: "Clear Date",
        accessor: "endDate",
        textAlign: 'center',
        Cell: ({ row }) => (row.original.endDate ? row.original.endDate : 'in progress'),
      }
    ],[handleModalShow]
  );

  return (
    <>
      <div className="search-result">
        <div className="sub-title mb-3">
          <div>Mission info</div>
        </div>
        <div className="table-wrapper">
          <Form onSubmit={handleSubmit(onValid)} onReset={reset} className="w-100">
            <div className="count-block justify-content-between">
              <div>
                <InputGroup>
                  <Controller
                    name="missionId"
                    control={control}
                    render={({ field }) =>
                      <Form.Control
                        {...field}
                        type="text"
                        placeholder="mission id"
                      />
                    }
                  />
                  <button className="btn btn-primary" type="submit">
                    <span className="material-icons">search</span>Search
                  </button>
                </InputGroup>
              </div>
              <div>
                <span>Showing {result.startIndex}-{result.endIndex} of {result.totalCnt} results</span>
              </div>
            </div>

            <SortingTable columns={columns}
                          data={result?.resultList}
                          defaultSorted={defaultSorted}
                          manualSortBy={true}
                          fnSort={fnSort} />

            <Paging totalPages={result.totalPages}
                    currentPage={result.currentPage}
                    prevPage={result.prevPage}
                    nextPage={result.nextPage}
                    startPage={result.startPage}
                    endPage={result.endPage}
                    displayPageNum={result.displayPageNum}
                    fnPaging={fnPaging} />
          </Form>
        </div>

        <StudentMissionDetailModal
          onHide={handleModalHide}
          modalItem={modalItem}
        />
      </div>
    </>
  );
}

export default StudentMission;