import {forwardRef} from "react";
import {Form} from "react-bootstrap";

const DatePickerInput = forwardRef((prop, ref) => {
  return (
    <Form.Control
      ref={ref}
      type="text"
      {...prop}
    />
  );
});

export default DatePickerInput;