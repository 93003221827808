import "react-datepicker/dist/react-datepicker.css";
import {Col, Form, Row} from "react-bootstrap";
import HelmetWrapper from "../../../components/common/HelmetWrapper";
import {Controller, useForm} from "react-hook-form";
import React, {useCallback, useMemo} from "react";
import DatePicker from "react-datepicker";
import DatePickerInput from '../../../components/material/DatePickerInput';
import ErrorMessageFeedback from "../../../components/common/ErrorMessageFeedback";
import moment from "moment";
import {DateUtils, http} from "../../../utils";
import {useQuery} from "react-query";
import SortingTable from '../../../components/material/SortingTable';
import Paging from "../../../components/common/Paging";

function StudentAccesses() {
  const initValue = useMemo(() => {
    return {
      startDate: DateUtils.getDate(moment().startOf('month').format('YYYY-MM-DD') + ' 00:00:00'),
      endDate: DateUtils.getDate(moment().endOf('month').format('YYYY-MM-DD') + ' 00:00:00'),
      studentId: '',
    }
  }, []);

  const {handleSubmit, reset, formState: {errors}, control, getValues, setValue } = useForm({
    mode: 'onChange',
    defaultValues : { ...initValue }
  });

  const { data: result, refetch : refetchSearch } = useQuery(
    ['searchStudentAccesses'],
    async () => {
      const result = await http.get(`/api/v1/user/student-accesses`,{params: getValues()});
      return result.data;
    },
    {
      enabled: false,
      initialData: []
    });

  const fnSort = useCallback(async (sortBy) => {
    setValue('sortByName', sortBy.id);
    setValue('desc', sortBy.desc);

    await refetchSearch();
  }, [setValue, refetchSearch]);

  const onValid = async () => {
    setValue('currentPage', 1);
    await refetchSearch();
  };

  const fnPaging = async (pageNum) => {
    setValue('currentPage', pageNum);
    await refetchSearch();
  };

  const defaultSorted = useMemo(() => ({ id: 'startDate', desc: true }), []);
  const columns = useMemo(() => [
      {
        Header: 'Student ID',
        accessor: 'studentId',
        textAlign: 'center',
      },
      {
        Header: 'Start Date',
        accessor: 'startDate',
        textAlign: 'center',
      },
      {
        Header: 'End Date',
        accessor: 'endDate',
        textAlign: 'center',
      },
      {
        Header: 'Device',
        accessor: 'deviceName',
        textAlign: 'center',
      },
      {
        Header: 'os name',
        accessor: 'osName',
        textAlign: 'center',
      },
      {
        Header: 'Build Code',
        accessor: 'buildCode',
        textAlign: 'center',
        width: '50px'
      },
    ],[]
  );

  return (
    <>
      <div className="contents-fluid">
        <HelmetWrapper meta={{
          title : 'Student Accesses'
        }} />

        <div className="page-title">
          Student Accesses
        </div>

        <div className="search-condition">
          <Form onSubmit={handleSubmit(onValid)} onReset={reset} className="w-100">
            <Row>
              <Col xs="6" className=" mb-4">
                <Form.Group className="form-group required mb-0">
                  <Form.Label>Date</Form.Label>
                </Form.Group>
                <div className="condition-date-picker">
                  <Form.Group className="form-group mb-0">
                    <Controller
                      name="startDate"
                      control={control}
                      rules={ {required: true} }
                      render={({ field, fieldState }) =>
                        <DatePicker
                          {...field}
                          dateFormat="yyyy/MM/dd HH:mm"
                          selected={field.value}
                          showTimeSelect
                          timeIntervals={60}
                          autoComplete="off"
                          onChange={(date) => {
                            field.onChange(date);
                          }}
                          className={fieldState.invalid ? 'is-invalid' : ''}
                          customInput={ <DatePickerInput /> }
                        />
                      }
                    />
                    <ErrorMessageFeedback error={errors.startDate} />
                  </Form.Group>

                  <div className='ms-2 me-2'> ~ </div>

                  <Form.Group className="form-group mb-0">
                    <Form.Label></Form.Label>
                    <Controller
                      name="endDate"
                      control={control}
                      rules={ {required: true} }
                      render={({ field, fieldState }) =>
                        <DatePicker
                          {...field}
                          dateFormat="yyyy/MM/dd HH:mm"
                          selected={field.value}
                          showTimeSelect
                          timeIntervals={60}
                          autoComplete="off"
                          onChange={(date) => {
                            field.onChange(date);
                          }}
                          className={fieldState.invalid ? 'is-invalid' : ''}
                          customInput={ <DatePickerInput /> }
                        />
                      }
                    />
                    <ErrorMessageFeedback error={errors.endDate} />
                  </Form.Group>
                </div>
              </Col>
              <Col xs="12" md="6">
                <Form.Group className="form-group" controlId="id_studentId">
                  <Form.Label>Student Id</Form.Label>
                  <Controller
                    name="studentId"
                    control={control}
                    render={({ field }) =>
                      <Form.Control
                        {...field}
                        type="text"
                        placeholder="일치"
                      />
                    }
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs="12" className="btn-wrapper">
                <button className="btn btn-primary" type="submit">
                  <span className="material-icons">search</span>Search
                </button>
              </Col>
            </Row>
          </Form>
        </div>

        <div className="search-result">
          <div className="sub-title">검색 결과</div>
          <div className="table-wrapper">
            <div className="count-block justify-content-between">
              <div>
              </div>
              <div>
                <span>Showing {result.startIndex}-{result.endIndex} of {result.totalCnt} results</span>
              </div>
            </div>

            <SortingTable columns={columns}
                          data={result.resultList}
                          defaultSorted={defaultSorted}
                          manualSortBy={true}
                          fnSort={fnSort} />

            <Paging totalPages={result.totalPages}
                    currentPage={result.currentPage}
                    prevPage={result.prevPage}
                    nextPage={result.nextPage}
                    startPage={result.startPage}
                    endPage={result.endPage}
                    displayPageNum={result.displayPageNum}
                    fnPaging={fnPaging} />
          </div>
        </div>
      </div>
    </>
  );
}

export default StudentAccesses;