import _ from 'lodash';
import alertify from 'alertifyjs';
import 'alertifyjs/build/css/alertify.css'
import 'alertifyjs/build/css/themes/default.css'

alertify.set('confirm', 'closable', false);
alertify.set('confirm', 'transition', 'pulse');
alertify.defaults.glossary.title = 'Message';

export function confirm (message, fnOk, fnCancel) {
  alertify.confirm('Confirm', message, function () {
    fnOk();
  }, function() {
    if(_.isFunction(fnCancel)) {
      fnCancel();
    }
  });
}
export function prompt(message, fnOk, fnCancel) {
  alertify.prompt('Confirm', message, '', function(evt, value) {
    fnOk(evt, value);
  }, function() {
    if (_.isFunction(fnCancel)) {
      fnCancel();
    }
  })
}

export function alert(message) {
  alertify.alert('Confirm', message);
}