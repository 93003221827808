import InteractionList from "../../game/InteractionInfo/InteractionList";

function ContentsTab({ dataList }) {

  return (
    <>
      <InteractionList resultList={dataList} />
    </>
  );
}

export default ContentsTab;