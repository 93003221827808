import {useEffect, useMemo} from "react";
import {Controller, useForm} from "react-hook-form";
import {DateUtils, http} from "../../../utils"
import {useQuery} from "react-query";
import HelmetWrapper from "../../../components/common/HelmetWrapper";
import {Col, Form, Row} from "react-bootstrap";
import DatePickerInput from "../../../components/material/DatePickerInput";
import ErrorMessageFeedback from "../../../components/common/ErrorMessageFeedback";
import DatePicker from "react-datepicker";
import DefaultTable from "../../../components/material/DefaultTable";
import Paging from "../../../components/common/Paging";
import moment from "moment";

function CreateHistory() {
    const initValue = useMemo(() => {
        return {
            godomallId:'',
            godomallIdString:'',
            groupId:'',
            organization:'',
            admsUserId:'',
            startDate: moment(DateUtils.todayTime('00:00:00')).subtract(1, 'days').toDate(),
            endDate: DateUtils.todayTime('00:00:00'),
        }
    }, [])

    const {handleSubmit, reset, formState: {errors}, control, watch, setValue} = useForm({
        mode: 'onChange',
        defaultValues : { ...initValue }
    });
    const searchList = async () => {
        const resp = await http.get('/api/v1/b2b', {params: watch()})
        console.log(resp.data)
        return resp.data;
    };

    const { data: result, refetch : refetchSearch } = useQuery(['searchHistory'], () => searchList(), {
        enabled: false,
        initialData: { resultList: [] }
    });

    const onValid = async () => {
        setValue('currentPage', 1);
        await refetchSearch();
    };

    const fnPaging = async (pageNum) => {
        setValue('currentPage', pageNum);
        await refetchSearch();
    };
    const columns = useMemo(() => [
            {
                Header: "Adms User",
                accessor: "admsUserId",
                textAlign: 'center',
            },
            {
                Header: "Organization",
                accessor: "organization",
                textAlign: 'center',
            },
            {
                Header: "Day Count",
                accessor: "dayCount",
                textAlign: 'center',
            },
            {
                Header: "Godomall ID(Num)",
                accessor: "godomallId",
                textAlign: 'center',
            },
            {
                Header: "Godomall ID(Str)",
                accessor: "godomallIdString",
                textAlign: 'center',
            },
            {
                Header: "Group ID",
                accessor: "groupId",
                textAlign: 'center',
            },
            {
                Header: "Student Count",
                accessor: "studentIdCount",
                textAlign: 'center',
            },
            {
                Header: "Create Date",
                accessor: "createDate",
                textAlign: 'center',
            },
        ],[]
    );
    //onload 시 한번 실행
    useEffect(() => {
        refetchSearch()
    },[refetchSearch])
    return (
        <>
            <div className="contents-fluid">
                <HelmetWrapper meta={{
                    title : 'Create History'
                }} />
                <div className="page-title">
                    B2B Create History
                </div>
            </div>
            <div className="search-condition">
                <Form onSubmit={handleSubmit(onValid)} onReset={reset} className="w-100">
                    <Row>
                        <Col xs="12" md="6">
                            <Form.Group className="form-group" controlId="id_admsUserId">
                                <Form.Label>Adms User ID</Form.Label>
                                <Controller
                                    name="admsUserId"
                                    control={control}
                                    render={({ field }) =>
                                        <Form.Control
                                            {...field}
                                            type="text"
                                            placeholder="포함"
                                        />
                                    }
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="12" className="mb-4">
                            <Form.Group className="form-group required mb-0">
                                <Form.Label>Date</Form.Label>
                            </Form.Group>

                            <div className="condition-date-picker">
                                <Form.Group className="form-group mb-0">
                                    <Controller
                                        name="startDate"
                                        control={control}
                                        rules={ {required: true} }
                                        render={({ field, fieldState }) =>
                                            <DatePicker
                                                {...field}
                                                dateFormat="yyyy/MM/dd HH:mm"
                                                selected={field.value}
                                                showTimeSelect
                                                timeIntervals={60}
                                                autoComplete="off"
                                                onChange={(date) => {
                                                    field.onChange(date);
                                                }}
                                                className={fieldState.invalid ? 'is-invalid' : ''}
                                                customInput={ <DatePickerInput /> }
                                            />
                                        }
                                    />
                                    <ErrorMessageFeedback error={errors.startDate} />
                                </Form.Group>

                                <div className='ms-2 me-2'> ~ </div>

                                <Form.Group className="form-group mb-0">
                                    <Controller
                                        name="endDate"
                                        control={control}
                                        rules={ {required: true} }
                                        render={({ field, fieldState }) =>
                                            <DatePicker
                                                {...field}
                                                dateFormat="yyyy/MM/dd HH:mm"
                                                selected={field.value}
                                                showTimeSelect
                                                timeIntervals={60}
                                                autoComplete="off"
                                                onChange={(date) => {
                                                    field.onChange(date);
                                                }}
                                                className={fieldState.invalid ? 'is-invalid' : ''}
                                                customInput={ <DatePickerInput /> }
                                            />
                                        }
                                    />
                                    <ErrorMessageFeedback error={errors.endDate} />
                                </Form.Group>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="12" className="btn-wrapper">
                            <button className="btn btn-primary" type="submit">
                                <span className="material-icons">search</span>Search
                            </button>
                        </Col>
                    </Row>
                </Form>
            </div>
            <div className="search-result">
                <div className="sub-title">검색 결과</div>
                <div className="table-wrapper">
                    <div className="count-block">
                        <span>Showing {result.startIndex}-{result.endIndex} of {result.totalCnt} results</span>
                    </div>

                    <DefaultTable columns={columns}
                                  data={result.resultList}
                                  />

                    <Paging totalPages={result.totalPages}
                            currentPage={result.currentPage}
                            prevPage={result.prevPage}
                            nextPage={result.nextPage}
                            startPage={result.startPage}
                            endPage={result.endPage}
                            displayPageNum={result.displayPageNum}
                            fnPaging={fnPaging} />
                </div>
            </div>
        </>
    )
}
export default CreateHistory;