import "react-datepicker/dist/react-datepicker.css";
import {Col, Form, Row} from "react-bootstrap";
import HelmetWrapper from "../../../components/common/HelmetWrapper";
import {Controller, useForm} from "react-hook-form";
import {useMemo} from "react";
import DatePicker from "react-datepicker";
import DatePickerInput from '../../../components/material/DatePickerInput';
import ErrorMessageFeedback from "../../../components/common/ErrorMessageFeedback";
import moment from "moment";
import {DateUtils, http} from "../../../utils";
import {useQuery} from "react-query";
import InteractionList from "./InteractionList";

function InteractionInfo() {
  const initValue = useMemo(() => {
    return {
      startDate: DateUtils.getDate(moment().startOf('month').format('YYYY-MM-DD') + ' 00:00:00'),
      endDate: DateUtils.getDate(moment().endOf('month').format('YYYY-MM-DD') + ' 00:00:00'),
      content: '',
      contentCategory: ''
    }
  }, []);

  const {handleSubmit, reset, formState: {errors}, control, getValues } = useForm({
    defaultValues : { ...initValue }
  });

  const { data: resultList, refetch : refetchResult } = useQuery(
    ['searchInteractionInfo'],
    async () => {
      const result = await http.get(`/api/v1/game/interaction-info/statistics`,{params: getValues()});
      return result.data;
    },
    {
      enabled: false,
      initialData: []
    });

  const onValid = async () => {
    await refetchResult();
  };

  return (
    <>
      <div className="contents-fluid">
        <HelmetWrapper meta={{
          title : 'Interaction Info'
        }} />

        <div className="page-title">
          Interaction Info
        </div>

        <div className="search-condition">
          <Form onSubmit={handleSubmit(onValid)} onReset={reset} className="w-100">
            <Row>
              <Col xs="6" className=" mb-4">
                <Form.Group className="form-group required mb-0">
                  <Form.Label>Date</Form.Label>
                </Form.Group>
                <div className="condition-date-picker">
                  <Form.Group className="form-group mb-0">
                    <Controller
                      name="startDate"
                      control={control}
                      rules={ {required: true} }
                      render={({ field, fieldState }) =>
                        <DatePicker
                          {...field}
                          dateFormat="yyyy/MM/dd HH:mm"
                          selected={field.value}
                          showTimeSelect
                          timeIntervals={60}
                          autoComplete="off"
                          onChange={(date) => {
                            field.onChange(date);
                          }}
                          className={fieldState.invalid ? 'is-invalid' : ''}
                          customInput={ <DatePickerInput /> }
                        />
                      }
                    />
                    <ErrorMessageFeedback error={errors.startDate} />
                  </Form.Group>

                  <div className='ms-2 me-2'> ~ </div>

                  <Form.Group className="form-group mb-0">
                    <Controller
                      name="endDate"
                      control={control}
                      rules={ {required: true} }
                      render={({ field, fieldState }) =>
                        <DatePicker
                          {...field}
                          dateFormat="yyyy/MM/dd HH:mm"
                          selected={field.value}
                          showTimeSelect
                          timeIntervals={60}
                          autoComplete="off"
                          onChange={(date) => {
                            field.onChange(date);
                          }}
                          className={fieldState.invalid ? 'is-invalid' : ''}
                          customInput={ <DatePickerInput /> }
                        />
                      }
                    />
                    <ErrorMessageFeedback error={errors.endDate} />
                  </Form.Group>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs="12" md="6">
                <Form.Group className="form-group" controlId="id_content">
                  <Form.Label>Content</Form.Label>
                  <Controller
                    name="content"
                    control={control}
                    render={({ field }) =>
                      <Form.Control
                        {...field}
                        type="text"
                        placeholder="포함"
                      />
                    }
                  />
                </Form.Group>
              </Col>
              <Col xs="12" md="6">
                <Form.Group className="form-group" controlId="id_contentCategory">
                  <Form.Label>Content Category</Form.Label>
                  <Controller
                    name="contentCategory"
                    control={control}
                    render={({ field }) =>
                      <Form.Control
                        {...field}
                        type="text"
                        placeholder="포함"
                      />
                    }
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs="12" className="btn-wrapper">
                <button className="btn btn-primary" type="submit">
                  <span className="material-icons">search</span>Search
                </button>
              </Col>
            </Row>
          </Form>
        </div>

        <div className="search-result">
          <InteractionList resultList={resultList} />
        </div>
      </div>
    </>
  );
}

export default InteractionInfo;