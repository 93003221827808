
export const searchParamToJsonObject = (searchParam) => {
  const result = {};
  searchParam.forEach((value, key) => {
    if(!value) {
      return;
    }
    result[key] = value;
  });

  return result;
};