import {useCallback, useEffect, useMemo} from "react";
import {http, Alert} from "../../../utils";
import {useQuery} from "react-query";
import {useForm} from "react-hook-form";
import SortingTable from "../../../components/material/SortingTable";
import Paging from "../../../components/common/Paging";

function HistoryLudiPassUpdate({student={}, forceRefresh}) {
    const initValue = useMemo(() => {
        return {
            historyLudiPassUpdateId: ''
        }
    }, []);
    const {setValue, getValues} = useForm({
        mode: 'onChange',
        defaultValues : { ...initValue }
    });
    const { data: result, refetch : refetchSearch } = useQuery(
    ['searchHistoryLudiPassUpdate'],
    async () => {
        const param = {
            ...getValues(),
            userLudiPassId : student.userLudiPass?.id
        }
        const result = await http.get(`/api/v1/edit/ludipass/update/history`,{params: param});
        return result.data;
    },
    {
        enabled: false,
        initialData: []
    });

    useEffect(() => {
        async function fetchData() {
            await refetchSearch();
        }
        fetchData();
    }, [student, forceRefresh, refetchSearch, setValue]);

    const fnHistoryLudiPassSort = useCallback(async (sortBy) => {
        setValue('sortByName', sortBy.id);
        setValue('desc', sortBy.desc);
    }, [setValue]);

    const fnHistoryLudiPassValidPaging = async (pageNum) => {
        setValue('currentPage', pageNum);
        await refetchSearch();
    };
    const defaultHistoryLudiPassSorted = useMemo(() => ({ id: 'id', desc: false }), []);

    const showMemoBtn = (row) => {
        return (
            <>
                {
                    row.message &&
                    <a href="#!" title="showMemo" onClick={(e) => showMemo(row.message)}>
                        <span className="material-icons">sticky_note_2</span>
                    </a>
                }
            </>
        )
    }
    const showMemo = (message) => {
        Alert.alert(message)
    }
    const columns = [
        {
            Header: "Pre EndDate",
            accessor: "preEndDate",
            textAlign: 'center',
        },
        {
            Header: "Updated EndDate",
            accessor: "updatedEndDate",
            textAlign: 'center',
        },
        {
            Header: "Update Admin Id",
            accessor: "updateUserId",
            textAlign: 'center',
        },
        {
            Header: "Update Date",
            accessor: "createDate",
            textAlign: 'center',
        },
        {
            Header: "Memo",
            accessor: "message",
            textAlign: "center",
            Cell: ({ row }) => showMemoBtn(row.original),
        }
    ];
    return (
        <>
            <div className="search-result">
                <div className="sub-title">Expiry Date 변경 내역</div>

                <div className="table-wrapper">
                    <div className="count-block justify-content-between">
                        <div>
                        </div>
                        <div>
                            <span>Showing {result.startIndex}-{result.endIndex} of {result.totalCnt} results</span>
                        </div>
                    </div>

                    <SortingTable columns={columns}
                                  data={result.resultList}
                                  defaultSorted={defaultHistoryLudiPassSorted}
                                  manualSortBy={true}
                                  fnSort={fnHistoryLudiPassSort} />

                    <Paging totalPages={result.totalPages}
                            currentPage={result.currentPage}
                            prevPage={result.prevPage}
                            nextPage={result.nextPage}
                            startPage={result.startPage}
                            endPage={result.endPage}
                            displayPageNum={result.displayPageNum}
                            fnPaging={fnHistoryLudiPassValidPaging} />
                </div>
            </div>
        </>
    )
}
export default HistoryLudiPassUpdate;