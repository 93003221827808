import {useCallback, useMemo, useRef} from "react";
import {Controller, useForm} from "react-hook-form";
import {Button, Col, Form, Modal, Row} from "react-bootstrap";
import {Alert, http, toast, Validator} from "../../../../utils";
import ErrorMessageFeedback from "../../../../components/common/ErrorMessageFeedback";
import {useMutation} from "react-query";

function AddMissionModal({onHide, modalItem}) {
  const {
    isShow,
    item
  } = modalItem;

  const initValue = useMemo(() => {
    return {
      missionId: ''
    }
  }, []);
  const refMissionId = useRef();

  const {handleSubmit, reset, formState: {errors}, control} = useForm({
    mode: 'onChange',
    defaultValues : { ...initValue }
  });
  const insertSettingMissionAction = useMutation((params) => http.post('/api/v1/edit/student/mission/setting/mission', params));

  const handleClose = useCallback(() => {
    onHide(false);
  }, [onHide]);

  const init = () => {
    refMissionId.current.focus();
    reset({...initValue});
  };

  const onValid = async (data) => {
    const confirmMsg = `${data.missionId}를 추가 하시겠습니까?`;
    const completeMsg = '완료 되었습니다.';
    Alert.confirm(confirmMsg, async () => {
      const param = {...data, userId : item.userId, studentId: item.studentId};
      await insertSettingMissionAction.mutateAsync(param);

      toast.success(completeMsg);
      onHide(true);
    });
  };

  return (
    <>
      <Modal
        size="lg"
        show={isShow}
        onHide={handleClose}
        className="detail"
        onEntered={() => {
          init();
        }}
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">Item Search</Modal.Title>
          <Button type="button" className="close" aria-label="Close" onClick={handleClose}>
            <span aria-hidden="true">&times;</span>
          </Button>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit(onValid)} onReset={reset} className="w-100 mt-4">
            <Row>
              <Col xs="12">
                <Form.Group className="form-group" controlId="id_missionId">
                  <Form.Label>Mission ID</Form.Label>
                  <Controller
                    name="missionId"
                    control={control}
                    rules={{
                      required: true,
                      validate: {
                        number: (value) => Validator.validateNumber(value)
                      }
                    }}
                    render={({ field }) =>
                      <Form.Control
                        {...field}
                        ref={refMissionId}
                        type="text"
                        isInvalid={errors.missionId}
                      />
                    }
                  />
                  <ErrorMessageFeedback error={errors.missionId} />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs="12" className="btn-wrapper mt-4 text-end">
                <button className="btn btn-primary" type="submit">
                  <span className="material-icons">add</span>Add Mission
                </button>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default AddMissionModal;