import {Button, Col, Modal, Row } from "react-bootstrap";
import {useCallback} from "react";
import {Alert} from "../../../utils";

function MessageRewardConfirmModal({onHide, modalItem, fnSubmit}) {
  const {
    isShow,
    item,
  } = modalItem;

  const onSubmit = (e) => {
    e.preventDefault();
    const confirmMsg = '등록 하시겠습니까?';
    Alert.confirm(confirmMsg, async () => {
      handleClose();
      fnSubmit();
    });
  };

  const handleClose = useCallback(() => {
    onHide();
  }, [onHide]);

  return (
    <>
      <Modal
        size="lg"
        show={isShow}
        onHide={handleClose}
        className="detail"
        onEntered={() => {
          //init();
        }}
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">Message Reward Confirm</Modal.Title>
          <Button type="button" className="close" aria-label="Close" onClick={handleClose}>
            <span aria-hidden="true">&times;</span>
          </Button>
        </Modal.Header>
        <Modal.Body>
          <Row className="border border-primary mb-2">
            <Col xs="12" md="6">
              <div className="detail-row d-block">
                <div className="mb-2">
                  <span className="label">Subject (영어)</span>
                </div>
                <div><span>{item.subject}</span></div>
              </div>
            </Col>
            <Col xs="12">
              <div className="detail-row d-block">
                <div className="mb-2">
                  <span className="label">Message (영어)</span>
                </div>
                <div style={{"whiteSpace" : "pre-line"}}>
                  {item.message}
                </div>
              </div>
            </Col>
          </Row>
          <Row className="border border-warning">
            <Col xs="12" md="6">
              <div className="detail-row d-block">
                <div className="mb-2">
                  <span className="label">Subject Native (번역)</span>
                </div>
                <div><span>{item.subjectNative}</span></div>
              </div>
            </Col>
            <Col xs="12">
              <div className="detail-row d-block">
                <div className="mb-2">
                  <span className="label">Message Native (번역)</span>
                </div>
                <div style={{"whiteSpace" : "pre-line"}}>
                  {item.messageNative}
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs="12" md="6">
              <div className="detail-row">
                <span className="label">ItemId</span> <span>{item.items?.length > 0 ? `${item.items[0].itemId} (${item.items[0].name})` : ''}</span>
              </div>
            </Col>
            <Col xs="12" md="6">
              <div className="detail-row">
                <span className="label">Count</span> <span>{item.items?.length > 0 ? item.items[0]?.qty : ''}</span>
              </div>
            </Col>
            <Col xs="12" md="6">
              <div className="detail-row">
                <span className="label">Target Student</span> <span>{item.activeAllStudent === 'true' ? 'All Student' : `${item.studentIds?.length} 건`}</span>
              </div>
            </Col>
          </Row>

          <Row>
            <Col xs="12" className="btn-wrapper mt-4 text-end">
              <button className="btn btn-success" type="button" onClick={onSubmit}>
                <span className="material-icons">done</span>Submit
              </button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default MessageRewardConfirmModal;