import {Button, Form, InputGroup} from "react-bootstrap";
import DefaultTable from "../../../../components/material/DefaultTable";
import {Alert, http, toast} from '../../../../utils';
import {useCallback, useEffect, useMemo, useState} from "react";
import {useMutation, useQuery} from "react-query";
import AddMissionModal from "./AddMissionModal";
import {Controller, useForm} from "react-hook-form";
import SortingTable from "../../../../components/material/SortingTable";
import Paging from "../../../../components/common/Paging";

function Mission({ student = {}, forceRefresh }) {
  const initValue = useMemo(() => {
    return {
      missionId: ''
    }
  }, []);

  const [addMissionModalItem, setAddMissionModalItem] = useState({
    isShow: false,
    item: {}
  });

  const {handleSubmit, reset, control, setValue, getValues} = useForm({
    mode: 'onChange',
    defaultValues : { ...initValue }
  });

  const { data: result, refetch : refetchSearch } = useQuery(
    ['searchEditStudentTasks'],
    async () => {
      const param = {
        userId : student?.userId,
        studentId : student?.id
      }
      const result = await http.get(`/api/v1/edit/student/mission/tasks`,{params: param});
      return result.data;
    },
    {
      enabled: false,
      initialData: []
    });

  const { data: resultSettingMission, refetch : refetchSearchSettingMission } = useQuery(
    ['searchEditStudentSettingMission'],
    async () => {
      const param = {
        userId : student?.userId,
        studentId : student?.id
      }
      const result = await http.get(`/api/v1/edit/student/mission/setting/mission`,{params: param});
      return result.data;
    },
    {
      enabled: false,
      initialData: []
    });

  const { data: resultPlayedMission, refetch : refetchSearchPlayedMission } = useQuery(
    ['searchEditStudentPlayedMission'],
    async () => {
      const param = {
        ...getValues(),
        userId : student?.userId,
        studentId : student?.id
      }
      const result = await http.get(`/api/v1/edit/student/mission/played/mission`,{params: param});
      return result.data;
    },
    {
      enabled: false,
      initialData: []
    });
  const completeTaskAction = useMutation((params) => http.put(`/api/v1/edit/student/mission/complete/task`, params));
  const removeSettingMissionAction = useMutation((params) => http.put('/api/v1/edit/student/mission/setting/mission/remove', params));

  useEffect(() => {
    if(!student.id) {
      return;
    }

    async function fetchData() {
      await refetchSearch();
      await refetchSearchSettingMission();

      setValue('currentPage', 1);
      await refetchSearchPlayedMission();
    }
    fetchData();

  }, [student, forceRefresh, refetchSearch, refetchSearchSettingMission, refetchSearchPlayedMission, setValue]);

  const handleTaskComplete = (e, data) => {
    e.preventDefault();
    const confirmMsg = `${data.taskId} 를 완료처리 하시겠습니까?`;
    const completeMsg = '완료 되었습니다.';

    Alert.confirm(confirmMsg, async () => {
      const param = {...data, userId: student.userId, studentId: student.id};

      await completeTaskAction.mutateAsync(param);

      toast.success(completeMsg);
      await refetchSearch();
    });
  };

  const handleDeleteSettingMission = (e, data) => {
    e.preventDefault();
    const confirmMsg = `${data.missionId} 를 삭제 하시겠습니까?`;
    const completeMsg = '완료 되었습니다.';

    Alert.confirm(confirmMsg, async () => {
      const param = {...data, userId : student.userId, studentId: student.id};

      await removeSettingMissionAction.mutateAsync(param);

      toast.success(completeMsg);
      await refetchSearchSettingMission();
    });
  }

  const addMissionModalHandler = (e) => {
    e.preventDefault();

    setAddMissionModalItem({
      isShow: true,
      item: {
        userId: student.userId,
        studentId: student.id
      }
    });
  };

  const handleModalHide = async (isRefresh = false) => {
    setAddMissionModalItem({
      isShow: false,
      item: {}
    });
    if(isRefresh) {
      await refetchSearchSettingMission();
    }
  };

  const onPlayedMissionValid = useCallback(async () => {
    setValue('currentPage', 1);
    await refetchSearchPlayedMission();
  }, [setValue, refetchSearchPlayedMission]);

  const fnPlayedMissionSort = useCallback(async (sortBy) => {
    setValue('sortByName', sortBy.id);
    setValue('desc', sortBy.desc);

    await refetchSearchPlayedMission();
  }, [setValue, refetchSearchPlayedMission]);

  const fnPlayedMissionPaging = async (pageNum) => {
    setValue('currentPage', pageNum);
    await refetchSearchPlayedMission();
  };

  const columns = [
      {
        Header: "Mission Type",
        accessor: "missionType",
        textAlign: 'center',
      },
      {
        Header: "Mission ID",
        accessor: "missionId",
        textAlign: 'center',
      },
      {
        Header: "Task Id",
        accessor: "taskId",
        textAlign: 'center',
      },
      {
        Header: "Task Name",
        accessor: "taskName",
        textAlign: 'center',
        Cell: ({ row }) => ( (row.original.taskName || '').replace(/\\/ig,"") ),
      },
      {
        Header: "",
        accessor: "true",
        textAlign: 'center',
        Cell: ({ row }) => (<Button type="button"
                                    variant="outline-warning"
                                    onClick={(e) => handleTaskComplete(e, row.original)}>
          <span className="material-icons">done</span>Complete</Button>),
      }
  ];

  const settingMissionColumns = [
      {
        Header: "Mission Type",
        accessor: "missionType",
        textAlign: 'center',
      },
      {
        Header: "Mission ID",
        accessor: "missionId",
        textAlign: 'center',
      },
      {
        Header: "Mission Sub",
        accessor: "missionName",
        textAlign: 'center',
      },
      {
        Header: "",
        textAlign: 'center',
        Cell: ({ row }) => (<Button type="button"
                                    variant="outline-danger"
                                    onClick={(e) => handleDeleteSettingMission(e, row.original)}>
          <span className="material-icons">delete_outline</span>Delete</Button>),
      }
  ];

  const defaultPlayedMissionSorted = useMemo(() => ({ id: 'missionId', desc: false }), []);
  const playedMissionColumns = [
    {
      Header: "Mission Type",
      accessor: "missionType",
      textAlign: 'center',
    },
    {
      Header: "Mission ID",
      accessor: "missionId",
      textAlign: 'center',
    },
    {
      Header: "Mission Sub",
      accessor: "missionName",
      textAlign: 'center',
    },
    {
      Header: "Complete Count",
      accessor: "completeCount",
      textAlign: 'center',
    }
  ];

  return (
    <>
      <div className="search-result">
        <div className="sub-title">진행중 Task</div>

        <div className="table-wrapper">
          <div className="count-block justify-content-between">
            <div>
            </div>
            <div>
              <span>Showing {result.startIndex}-{result.endIndex} of {result.totalCnt} results</span>
            </div>
          </div>

          <DefaultTable columns={columns}
                        data={result.resultList}
          />
        </div>
      </div>

      <div className="search-result">
        <div className="sub-title">Setting Mission</div>

        <div className="table-wrapper">
          <div className="count-block justify-content-between">
            <div>
              <button className="btn btn-outline-info" type="button" onClick={addMissionModalHandler}>
                <span className="material-icons">add</span>Add Mission
              </button>
            </div>
            <div>
              <span>Showing {resultSettingMission.startIndex}-{resultSettingMission.endIndex} of {resultSettingMission.totalCnt} results</span>
            </div>
          </div>

          <DefaultTable columns={settingMissionColumns}
                        data={resultSettingMission.resultList}
          />
        </div>
      </div>

      <div className="search-result">
        <div className="sub-title">Played Mission</div>

        <Form onSubmit={handleSubmit(onPlayedMissionValid)} onReset={reset} className="w-100">
          <div className="table-wrapper">
            <div className="count-block justify-content-between">
              <div>
                <InputGroup>
                  <Controller
                    name="missionId"
                    control={control}
                    render={({ field }) =>
                      <Form.Control
                        {...field}
                        type="text"
                        placeholder="mission id"
                      />
                    }
                  />
                  <button className="btn btn-primary" type="submit">
                    <span className="material-icons">search</span>Search
                  </button>
                </InputGroup>
              </div>
              <div>
                <span>Showing {resultPlayedMission.startIndex}-{resultPlayedMission.endIndex} of {resultPlayedMission.totalCnt} results</span>
              </div>
            </div>

            <SortingTable columns={playedMissionColumns}
                          data={resultPlayedMission.resultList}
                          defaultSorted={defaultPlayedMissionSorted}
                          manualSortBy={true}
                          fnSort={fnPlayedMissionSort} />

            <Paging totalPages={resultPlayedMission.totalPages}
                    currentPage={resultPlayedMission.currentPage}
                    prevPage={resultPlayedMission.prevPage}
                    nextPage={resultPlayedMission.nextPage}
                    startPage={resultPlayedMission.startPage}
                    endPage={resultPlayedMission.endPage}
                    displayPageNum={resultPlayedMission.displayPageNum}
                    fnPaging={fnPlayedMissionPaging} />
          </div>
        </Form>
      </div>


      <AddMissionModal
        onHide={handleModalHide}
        modalItem={addMissionModalItem}
      />
    </>
  );
}

export default Mission;