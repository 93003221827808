import { Table } from "react-bootstrap";
import _ from "lodash";

function DmLogTab({ data }) {

  return (
    <>
      <Table className="report-table">
        <colgroup>
          <col width="15%" />
          <col width="*" />
        </colgroup>
        <tbody>
          <tr>
            <th>Category 1</th>
            <td>{data?.category1}</td>
          </tr>
          <tr>
            <th>Category 2</th>
            <td>{data?.category2}</td>
          </tr>
          <tr>
            <th>Category 3</th>
            <td>{data?.category3}</td>
          </tr>
          <tr>
            <th>Category 4</th>
            <td>{data?.category4}</td>
          </tr>
          <tr>
            <th>Category 5</th>
            <td>{data?.category5}</td>
          </tr>

          <tr>
            <th>Case. 1</th>
            <td>{_.isUndefined(data.case1Per) ? '' : `${data?.case1Per}%`}</td>
          </tr>
          <tr>
            <th>Case. 2</th>
            <td>{_.isUndefined(data.case2Per) ? '' : `${data?.case2Per}%`}</td>
          </tr>
          <tr>
            <th>Case. 3</th>
            <td>{_.isUndefined(data.case3Per) ? '' : `${data?.case3Per}%`}</td>
          </tr>

          <tr>
            <th>ptIcon Count</th>
            <td>{data.ptIconCount}</td>
          </tr>
          <tr>
            <th>ptClick Count</th>
            <td>{data.ptClickCount}</td>
          </tr>
          <tr>
            <th>zepChoice Count</th>
            <td>{data.zepChoiceCount}</td>
          </tr>
          <tr>
            <th>korClick Count</th>
            <td>{data.korClickCount}</td>
          </tr>
        </tbody>
      </Table>
    </>
  );
}

export default DmLogTab;