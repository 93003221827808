import {Button, Modal, Row, Col, Table} from "react-bootstrap";
import {useQuery} from "react-query";
import { http } from '../../../utils';
import AudioPlayModal from "../../../components/material/AudioPlayModal";
import {useState} from "react";


function StudentMissionDetailModal({onHide, modalItem}) {
  const {
    isShow,
    item,
  } = modalItem;

  const [audioPlayModalItem, setAudioPlayModalItem] = useState({
    isShow: false,
    url : ''
  });

  const { data: result, refetch : refetchSearchDetail } = useQuery(
    ['searchStudentMissionDetail'],
    () => http.get(`/api/v1/user/student/${item.studentId}/mission/${item.id}`).then((r) => r.data),
    {
      enabled: false,
      initialData: []
    });

  const handleClose = () => {
    onHide();
  };

  const init = async () => {
    await refetchSearchDetail();
  };

  const handleaudioPlayModalShow = (e, row) => {
    e.preventDefault();

    const url = `/api/v1/user/student/${item.studentId}/mission/${item.id}/dmlog/${row.dmLogId}/audio`;
    setAudioPlayModalItem({
      isShow: true,
      url : url
    });
  }

  const handleaudioPlayModalHide = () => {
    setAudioPlayModalItem({
      isShow: false,
      url : ''
    });
  }

  return (
    <>
      <Modal
        size="xl"
        show={isShow}
        onHide={handleClose}
        className="detail modal-full"
        onEntered={() => {
          init();
        }}
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">Mission DM Result</Modal.Title>
          <Button type="button" className="close" aria-label="Close" onClick={handleClose}>
            <span aria-hidden="true">&times;</span>
          </Button>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xs="12">
              <h5>{`${item.missionId} ${item.missionName} (${item.missionType})`}</h5>
            </Col>
          </Row>
          <Row>
            <Table responsive bordered hover>
              <thead className="table-dark">
                <tr>
                  <th width="9%">Turn ID</th>
                  <th width="*">Script</th>
                  <th width="15%">Cue</th>
                  <th width="15%">Input Dialogue</th>
                  <th width="10%">Result Branch</th>
                  <th width="5%"></th>
                </tr>
              </thead>
              <tbody>
                {
                  result.map((row, i) => {
                    return (
                      <tr key={i}>
                        <td>{row.turnId}</td>
                        <td>{row.npcHL}</td>
                        <td>
                          {
                            row.selectedPTs?.map((pt, ptIdx) =>
                              <div key={ptIdx}>{`${pt.sentence} (${pt.count})`}</div>
                            )
                          }
                        </td>
                        <td>{row.userHL}</td>
                        <td>{row.rationale}</td>
                        <td className="text-center">
                          {
                            row.filename &&
                            <a href="#!" title="play audio" onClick={(e) => handleaudioPlayModalShow(e, row)}>
                              <span className="material-icons">play_circle_outline</span>
                            </a>
                          }
                        </td>
                      </tr>
                    );
                  })
                }
              </tbody>
            </Table>
          </Row>
        </Modal.Body>
      </Modal>

      <AudioPlayModal
        onHide={handleaudioPlayModalHide}
        modalItem={audioPlayModalItem}
        autoPlay={true}
      />
    </>
  );
}

export default StudentMissionDetailModal;