import _ from 'lodash';
import {useState, useMemo, useEffect, useRef} from "react";
import { useParams, useNavigate } from "react-router-dom";
import {useMutation, useQuery} from "react-query";
import {Controller, useForm} from "react-hook-form";
import { http, toast, Validator} from "../../../utils";
import HelmetWrapper from "../../../components/common/HelmetWrapper";
import {Col, Form, InputGroup, Row} from "react-bootstrap";
import ErrorMessageFeedback from "../../../components/common/ErrorMessageFeedback";
import DatePicker from "react-datepicker";
import DatePickerInput from "../../../components/material/DatePickerInput";
import ItemSearchModal from "../../editdata/EditStudent/Inventory/ItemSearchModal";
import MessageRewardConfirmModal from "../../message/MessageReward/MessageRewardConfirmModal";

function MessageRewardDetail() {
  const initValue = useMemo(() => {
    return {
      subject: '',
      message: '',
      subjectNative: '',
      messageNative: '',
      expireDate: '',
      createDate: '',
      items: [{"itemId" : "", "qty" : "", "name" : ""}],
      studentIdText: '',
      studentIds: [],
      activeAllStudent: 'false'
    }
  }, []);

  const [itemSearchModalItem, setItemSearchModalItem] = useState({
    isShow: false
  });

  const [confirmModalItem, setConfirmModalItem] = useState({
    isShow: false,
    item : {}
  });

  const navigate = useNavigate();
  const urlParam = useParams();
  const [isRegist] = useState(() => _.isNil(urlParam.id));
  const refStudentIdText = useRef();

  const {handleSubmit, reset, formState: {errors}, control, watch, setValue, getValues} = useForm({
    mode: 'onChange',
    defaultValues : { ...initValue }
  });

  useQuery(
    ['searchMessageRewardDetail'],
    async () => {
      if(isRegist) {
        return {...initValue };
      }
      return  (await http.get(`/api/v1/message/reward/${urlParam.id}`)).data;
    },
    {
      initialData: {},
      onSuccess: (data) => {
        const result = {
          ...data,
          activeAllStudent : String(data.activeAllStudent),
          studentIds : data.studentIds || [],
          studentIdText: _.join(data.studentIds || [], '\n')
        }
        reset({ ...result });

        if(!isRegist && data.activeAllStudent) {
          refStudentIdText.current.setAttribute('readonly', true);
        }
      }
    });

  const watchStudentIdText = watch('studentIdText', '');
  const watchStudentIds = watch('studentIds', []);

  useEffect(() => {
    const list = _.split(watchStudentIdText, /\r?\n/)
      .map(v => v.trim())
      .filter((v) => v.length !== 0);

    setValue('studentIds', list);
  }, [watchStudentIdText, setValue]);

  const insertAction = useMutation((params) => http.post('/api/v1/message/reward', params));

  const onValid = async (data) => {
    setConfirmModalItem({
      isShow: true,
      item : data
    });
  };

  const onSubmit = async () => {
    const completeMsg = '등록 되었습니다.';
    await insertAction.mutateAsync(getValues());

    toast.success(completeMsg);
    navigate({
      pathname: '/adms/message/reward/view'
    });
  }

  const activeAllStudentChangeHandler = (e) => {
    if(e.target.checked) {
      refStudentIdText.current.setAttribute('readonly', true);
    }else {
      refStudentIdText.current.removeAttribute('readonly');
    }
  }

  const handleItemSearchModalShow = () => {
    setItemSearchModalItem({
      isShow: true
    });
  }

  const handleItemSearchModalHide = () => {
    setItemSearchModalItem({
      isShow: false
    });
  }

  const handleItemSearchSelect = (data) => {
    setValue('items[0].itemId', data.itemId);
    setValue('items[0].name', data.name);
  }

  const handleConfirmModalHide = () => {
    setConfirmModalItem({
      isShow: false,
      item : {}
    });
  }

  return (
    <>
      <div className="contents-fluid">
        <HelmetWrapper meta={{
          title : 'Reward'
        }} />
        <div className="page-title">
          Reward
        </div>

        <div className="mt-4">
          <Form onSubmit={handleSubmit(onValid)} onReset={reset} className="w-100">
            <Row>
              <Col xs="12" className="mb-4">
                <Form.Group className="form-group required mb-0">
                  <Form.Label>ExpireDate</Form.Label>
                </Form.Group>
                <div className="condition-date-picker">
                  <Form.Group className="form-group mb-0">
                    <Controller
                      name="expireDate"
                      control={control}
                      rules={ {required: true} }
                      render={({ field, fieldState }) =>
                        <DatePicker
                          {...field}
                          dateFormat="yyyy/MM/dd"
                          selected={field.value}
                          showTimeSelect={false}
                          timeIntervals={60}
                          autoComplete="off"
                          onChange={(date) => {
                            field.onChange(date);
                          }}
                          className={fieldState.invalid ? 'is-invalid' : ''}
                          customInput={ <DatePickerInput /> }
                          readOnly={!isRegist}
                        />
                      }
                    />
                    <ErrorMessageFeedback error={errors.expireDate} />
                  </Form.Group>
                </div>
              </Col>
            </Row>
            <Row className="border border-primary pt-1 pb-1 mb-2">
              <Col xs="12">
                <Form.Group className="form-group required" controlId="id_subject">
                  <Form.Label>Subject (영어)</Form.Label>
                  <Controller
                    name="subject"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) =>
                      <Form.Control
                        {...field}
                        type="text"
                        maxLength="200"
                        placeholder=""
                        isInvalid={errors.subject}
                        readOnly={!isRegist}
                      />
                    }
                  />
                  <ErrorMessageFeedback error={errors.subject} />
                </Form.Group>
              </Col>
              <Col xs="12">
                <Form.Group className="form-group required" controlId="id_message">
                  <Form.Label>Message (영어)</Form.Label>
                  <Controller
                    name="message"
                    control={control}
                    rules={ {required: true} }
                    render={({ field }) =>
                      <Form.Control
                        {...field}
                        as="textarea"
                        rows="5"
                        placeholder=""
                        isInvalid={errors.message}
                        readOnly={!isRegist}
                      />
                    }
                  />
                  <ErrorMessageFeedback error={errors.message} />
                </Form.Group>
              </Col>
            </Row>
            <Row className="border border-warning pt-1 pb-1 mb-2">
              <Col xs="12">
                <Form.Group className="form-group required" controlId="id_subjectNative">
                  <Form.Label>Subject Native (번역)</Form.Label>
                  <Controller
                    name="subjectNative"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) =>
                      <Form.Control
                        {...field}
                        type="text"
                        maxLength="200"
                        placeholder=""
                        isInvalid={errors.subjectNative}
                        readOnly={!isRegist}
                      />
                    }
                  />
                  <ErrorMessageFeedback error={errors.subjectNative} />
                </Form.Group>
              </Col>
              <Col xs="12">
                <Form.Group className="form-group required" controlId="id_messageNative">
                  <Form.Label>Message Native (번역)</Form.Label>
                  <Controller
                    name="messageNative"
                    control={control}
                    rules={ {required: true} }
                    render={({ field }) =>
                      <Form.Control
                        {...field}
                        as="textarea"
                        rows="5"
                        placeholder=""
                        isInvalid={errors.messageNative}
                        readOnly={!isRegist}
                      />
                    }
                  />
                  <ErrorMessageFeedback error={errors.messageNative} />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs="6">
                <Form.Group className="form-group" controlId="id_itemId">
                  <Form.Label>Item ID</Form.Label>
                  <InputGroup>
                    <Controller
                      name="items[0].itemId"
                      control={control}
                      rules={{
                        required: false,
                        validate: {
                          number: (value) => Validator.validateNumber(value)
                        }
                      }}
                      render={({ field }) =>
                        <Form.Control
                          {...field}
                          type="text"
                          readOnly={true}
                          isInvalid={errors?.items?.[0]?.itemId}
                        />
                      }
                    />
                    <button className="btn btn-outline-dark ms-2" type="button" disabled={!isRegist} onClick={handleItemSearchModalShow}>
                      <span className="material-icons">search</span>Item Search
                    </button>
                  </InputGroup>
                  <ErrorMessageFeedback error={errors?.items?.[0]?.itemId} />
                  <div>{getValues('items[0].name')}</div>
                </Form.Group>
              </Col>
              <Col xs="6" className="mb-4">
                <Form.Group className="form-group" controlId="id_itemCount">
                  <Form.Label>Item Count</Form.Label>
                  <Controller
                    name="items.[0].qty"
                    control={control}
                    rules={{
                      required: false,
                      validate: {
                        number: (value) => Validator.validateNumber(value)
                      }
                    }}
                    render={({ field }) =>
                      <Form.Control
                        {...field}
                        type="text"
                        maxLength="200"
                        placeholder=""
                        isInvalid={errors?.items?.[0]?.qty}
                        readOnly={!isRegist}
                      />
                    }
                  />
                  <ErrorMessageFeedback error={errors?.items?.[0]?.qty} />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs="6">
                <Form.Group className="form-group" controlId="id_studentIdText">
                  <Controller
                    name="activeAllStudent"
                    control={control}
                    render={({ field }) =>
                      <>
                        <Form.Check
                          inline
                          name="activeAllStudent"
                          type="checkbox"
                          value="true"
                          onChange={ (e) => {
                            if(!isRegist) {
                              return;
                            }
                            field.onChange(e.target.checked ? 'true' : 'false');
                            activeAllStudentChangeHandler(e);
                          }}
                          checked={field.value === 'true'}
                        />
                      </>
                    }
                  />
                  <Form.Label>All Student</Form.Label>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs="12">
                <Form.Group className="form-group" controlId="id_studentIdText">
                  <Form.Label>학습자 ID ( <span className="fw-bold text-primary">{watchStudentIds.length}</span> 건 )</Form.Label>
                  <Controller
                    name="studentIdText"
                    control={control}
                    render={({ field }) =>
                      <Form.Control
                        {...field}
                        as="textarea"
                        rows="5"
                        placeholder="줄바꿈(Enter)으로 studentId를 구분하여 넣어주세요"
                        ref={refStudentIdText}
                        readOnly={!isRegist}
                      />
                    }
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs="12" className="btn-wrapper d-flex justify-content-end">
                {
                  isRegist &&
                  <button className="btn btn-success" type="submit">
                    <span className="material-icons">done</span>Submit
                  </button>
                }
              </Col>
            </Row>
          </Form>
        </div>
      </div>

      <ItemSearchModal
        onSelect={handleItemSearchSelect}
        onHide={handleItemSearchModalHide}
        modalItem={itemSearchModalItem}
      />

      <MessageRewardConfirmModal
        onHide={handleConfirmModalHide}
        modalItem={confirmModalItem}
        fnSubmit={onSubmit}
      />
    </>
  );
}

export default MessageRewardDetail;