import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from 'redux-persist'
import { CookieStorage } from 'redux-persist-cookie-storage'
import Cookies from 'cookies-js'
import { loaderSlice } from "./LoaderSlice";
import { loginInfoSlice } from "./LoginInfoSlice";

const persistConfig = {
  key: "root",
  storage: new CookieStorage(Cookies/*, options */)
};

const rootReducer = combineReducers({
  loaderCount: loaderSlice.reducer,
  loginInfo: loginInfoSlice.reducer
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    })
});

let persistor = persistStore(store);

export { store, persistor }
