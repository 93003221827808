import {useCallback, useMemo} from "react";
import {Controller, useForm} from "react-hook-form";
import {useQuery} from "react-query";
import {Alert, http, Validator} from "../../../../utils";
import {Button, Col, Form, Modal, Row} from "react-bootstrap";
import SortingTable from "../../../../components/material/SortingTable";
import Paging from "../../../../components/common/Paging";

function UnlockObjectSearchModal({onSelect, onHide, modalItem}) {
  const {
    isShow,
  } = modalItem;

  const initValue = useMemo(() => {
    return {
      itemCategory: '',
      processId: ''
    }
  }, []);

  const {handleSubmit, reset, control, setValue, getValues} = useForm({
    mode: 'onChange',
    defaultValues : { ...initValue }
  });

  const { data: result, refetch : refetchSearch } = useQuery(
    ['searchObjectUnlockItems'],
    async () => {
      const result = await http.get(`/api/v1/edit/student/unlock/items`,{params: getValues()});
      return result.data;
    },
    {
      enabled: false,
      initialData: []
    });

  const handleClose = useCallback(() => {
    onHide();
  }, [onHide]);

  const handleSelect = useCallback((e, data) => {
    e.preventDefault();
    const confirmMsg = 'Unlock 하시겠습니까?';
    Alert.confirm(confirmMsg, async () => {
      onSelect(data);
      handleClose();
    });

  }, [onSelect, handleClose]);

  const onValid = async () => {
    setValue('currentPage', 1);
    await refetchSearch();
  };

  const fnSort = useCallback(async (sortBy) => {
    setValue('sortByName', sortBy.id);
    setValue('desc', sortBy.desc);

    await refetchSearch();
  }, [setValue, refetchSearch]);

  const fnPaging = async (pageNum) => {
    setValue('currentPage', pageNum);
    await refetchSearch();
  };

  const defaultSorted = useMemo(() => ({ }), []);
  const columns = useMemo(() => [
      {
        Header: "Process Id",
        accessor: "processId",
        textAlign: 'center',
      },
      {
        Header: "Name",
        accessor: "name",
        textAlign: 'center',
      },
      {
        Header: "Name Native",
        accessor: "nameNative",
        textAlign: 'center',
      },
      {
        Header: "",
        accessor: "id",
        textAlign: 'center',
        disableSortBy: true,
        Cell: ({ row }) => (<a href="#!" onClick={(e) => handleSelect(e, row.original)}>Unlock</a>),
      },
    ],[handleSelect]
  );

  const init = async () => {
    reset({...initValue});
    await refetchSearch();
  };

  return (
    <>
      <Modal
        size="lg"
        show={isShow}
        onHide={handleClose}
        className="detail"
        onEntered={() => {
          init();
        }}
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">Item Search</Modal.Title>
          <Button type="button" className="close" aria-label="Close" onClick={handleClose}>
            <span aria-hidden="true">&times;</span>
          </Button>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit(onValid)} onReset={reset} className="w-100 mt-4">
            <Row>
              <Col xs="12">
                <Form.Group className="form-group" controlId="id_processId">
                  <Form.Label>Process ID</Form.Label>
                  <Controller
                    name="processId"
                    control={control}
                    rules={{
                      validate: {
                        number: (value) => Validator.validateNumber(value)
                      }
                    }}
                    render={({ field }) =>
                      <Form.Control
                        {...field}
                        type="text"
                        placeholder="포함"
                      />
                    }
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs="12" className="btn-wrapper mt-4 text-end">
                <button className="btn btn-primary" type="submit">
                  <span className="material-icons">search</span>Search
                </button>
              </Col>
            </Row>
          </Form>

          <div className="search-result">
            <div className="table-wrapper">
              <div className="count-block">
                <span>Showing {result.startIndex}-{result.endIndex} of {result.totalCnt} results</span>
              </div>

              <SortingTable columns={columns}
                            data={result.resultList}
                            defaultSorted={defaultSorted}
                            manualSortBy={true}
                            fnSort={fnSort} />

              <Paging totalPages={result.totalPages}
                      currentPage={result.currentPage}
                      prevPage={result.prevPage}
                      nextPage={result.nextPage}
                      startPage={result.startPage}
                      endPage={result.endPage}
                      displayPageNum={result.displayPageNum}
                      fnPaging={fnPaging} />
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default UnlockObjectSearchModal;