import {useEffect, useMemo} from "react";
import {Controller, useForm} from "react-hook-form";
import {useQuery} from "react-query";
import HelmetWrapper from "../../../components/common/HelmetWrapper";
import {Col, Form, Row} from "react-bootstrap";
import DatePicker from "react-datepicker";
import DatePickerInput from "../../../components/material/DatePickerInput";
import ErrorMessageFeedback from "../../../components/common/ErrorMessageFeedback";
import DefaultTable from "../../../components/material/DefaultTable";
import Paging from "../../../components/common/Paging";
import {http, Constant} from '../../../utils';
import {Link} from "react-router-dom";
import _ from "lodash";

/**
 * 기존의 message status 메뉴를 maintenance 으로 이름 변경
 * 관련 내용은 messageServerStatus 라는 이름으로 동작함
 */
function Maintenance() {
  const initValue = useMemo(() => {
    return {
      startDate: '',
      endDate: '',
      status: _.first(Constant.CODE_SERVER_STATUS).code,
    }
  }, []);

  const {handleSubmit, reset, formState: {errors}, control, setValue, getValues} = useForm({
    mode: 'onChange',
    defaultValues : { ...initValue }
  });

  const { data: result, refetch : refetchSearch } = useQuery(
    ['searchMessageStatus'],
    async () => {
      const result = await http.get(`/api/v1/message/status`,{params: getValues()});
      return result.data;
    },
    {
      enabled: false,
      initialData: []
    });

  useEffect(() => {
    refetchSearch();
  }, [refetchSearch]);

  const onValid = async () => {
    setValue('currentPage', 1);
    await refetchSearch();
  };

  const fnPaging = async (pageNum) => {
    setValue('currentPage', pageNum);
    await refetchSearch();
  };

  const columns = [
    {
      Header: "ID",
      accessor: "id",
      textAlign: 'center',
      Cell: ({ row }) => (<Link to={`/adms/maintenance/detail/view/${row.original.id}`}>{row.original.id}</Link>),
    },
    {
      Header: "Subject",
      accessor: "subject",
      textAlign: 'center',
    },
    {
      Header: "Status",
      accessor: "status",
      textAlign: 'center',
    },
    {
      Header: "Server OFF Date",
      accessor: "createDate",
      textAlign: 'center'
    },
    {
      Header: "Server ON Date",
      accessor: "updateDate",
      textAlign: 'center'
    }
  ];

  return (
    <>
      <div className="contents-fluid">
        <HelmetWrapper meta={{
          title : 'Maintenance'
        }} />

        <div className="page-title">
          Maintenance
        </div>

        <div className="search-condition">
          <Form onSubmit={handleSubmit(onValid)} onReset={reset} className="w-100">
            <Row>
              <Col xs="6" className=" mb-4">
                <Form.Group className="form-group mb-0">
                  <Form.Label>Date</Form.Label>
                </Form.Group>
                <div className="condition-date-picker">
                  <Form.Group className="form-group mb-0">
                    <Controller
                      name="startDate"
                      control={control}
                      rules={ {required: false} }
                      render={({ field, fieldState }) =>
                        <DatePicker
                          {...field}
                          dateFormat="yyyy/MM/dd HH:mm"
                          selected={field.value}
                          showTimeSelect
                          timeIntervals={60}
                          autoComplete="off"
                          onChange={(date) => {
                            field.onChange(date);
                          }}
                          className={fieldState.invalid ? 'is-invalid' : ''}
                          customInput={ <DatePickerInput /> }
                        />
                      }
                    />
                    <ErrorMessageFeedback error={errors.startDate} />
                  </Form.Group>

                  <div className='ms-2 me-2'> ~ </div>

                  <Form.Group className="form-group mb-0">
                    <Controller
                      name="endDate"
                      control={control}
                      rules={ {required: false} }
                      render={({ field, fieldState }) =>
                        <DatePicker
                          {...field}
                          dateFormat="yyyy/MM/dd HH:mm"
                          selected={field.value}
                          showTimeSelect
                          timeIntervals={60}
                          autoComplete="off"
                          onChange={(date) => {
                            field.onChange(date);
                          }}
                          className={fieldState.invalid ? 'is-invalid' : ''}
                          customInput={ <DatePickerInput /> }
                        />
                      }
                    />
                    <ErrorMessageFeedback error={errors.endDate} />
                  </Form.Group>
                </div>
              </Col>
              <Col xs="6">
                <Form.Group className="form-group" controlId="id_status">
                  <Form.Label>Status</Form.Label>
                  <Controller
                    name="status"
                    control={control}
                    render={({ field }) =>
                      <Form.Control
                        as="select"
                        className="form-select"
                        {...field}
                        value={field.value || ''}
                      >
                        {
                          Constant.CODE_SERVER_STATUS.map((item, idx) => {
                            return <option key={idx} value={item.code}>{item.name}</option>
                          })
                        }
                      </Form.Control>
                    }
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs="6" className="btn-wrapper justify-content-start">
                <Link to="/adms/maintenance/add/view">
                  <button className="btn btn-outline-success" type="button">
                    <span className="material-icons">add</span>Add
                  </button>
                </Link>
              </Col>
              <Col xs="6" className="btn-wrapper">
                <button className="btn btn-primary" type="submit">
                  <span className="material-icons">search</span>Search
                </button>
              </Col>
            </Row>
          </Form>
        </div>

        <div className="search-result">
          <div className="sub-title">검색 결과</div>

          <div className="table-wrapper">
            <div className="count-block">
              <span>Showing {result.startIndex}-{result.endIndex} of {result.totalCnt} results</span>
            </div>

            <DefaultTable columns={columns}
                          data={result.resultList}
            />

            <Paging totalPages={result.totalPages}
                    currentPage={result.currentPage}
                    prevPage={result.prevPage}
                    nextPage={result.nextPage}
                    startPage={result.startPage}
                    endPage={result.endPage}
                    displayPageNum={result.displayPageNum}
                    fnPaging={fnPaging} />
          </div>
        </div>
      </div>
    </>
  );
}

export default Maintenance;