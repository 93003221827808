/* currency format */
function formatCurrency(target) {
  if(!target) {
    return target;
  }
  return String(target).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

/* 3자리 마다 comma */
export const numberWithCommas = (target) => {
  return formatCurrency(target);
};