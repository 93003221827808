import {useForm} from "react-hook-form";
import {useQuery} from "react-query";
import {useCallback, useEffect, useMemo} from "react";
import {Form} from "react-bootstrap";
import { http } from '../../../utils';
import SortingTable from "../../../components/material/SortingTable";
import Paging from "../../../components/common/Paging";

function StudentRecommedation({ studentId, reSearchFlag }) {
  const initValue = useMemo(() => {
    return {}
  }, []);
  const {handleSubmit, reset, watch, setValue} = useForm({
    defaultValues : { ...initValue }
  });

  const { data: result, refetch : refetchSearch } = useQuery(
    ['searchStudentRecommedation'],
    async () => {
      if(!studentId) {
        return {};
      }
      const result = await http.get(`/api/v1/user/student/${studentId}/recommedation`,{params: watch()});
      return result.data;
    },
    {
      enabled: false,
      initialData: []
    });

  useEffect(() => {
    async function fetchData() {
      await refetchSearch();
    }
    setValue('studentId', studentId);
    setValue('currentPage', 1);
    fetchData();
  }, [studentId, reSearchFlag, refetchSearch, setValue]);

  const fnSort = useCallback(async (sortBy) => {
    setValue('sortByName', sortBy.id);
    setValue('desc', sortBy.desc);

    await refetchSearch();
  }, [setValue, refetchSearch]);

  const onValid = async () => {
    await refetchSearch();
  };

  const fnPaging = async (pageNum) => {
    setValue('currentPage', pageNum);
    await refetchSearch();
  };

  const defaultSorted = useMemo(() => ({ id: 'createDate', desc: true }), []);
  const columns = useMemo(() => [
      {
        Header: "CreateDate",
        accessor: "createDate",
        textAlign: 'center',
      },
      {
        Header: "Step",
        accessor: "step",
        textAlign: 'center',
      },
      {
        Header: "Reward Step",
        accessor: "rewardStep",
        textAlign: 'center',
      },
      {
        Header: "Category",
        accessor: "contentCategory",
        textAlign: 'center',
      },
      {
        Header: "Content",
        accessor: "content",
        textAlign: 'center',
      },
      {
        Header: "MissionId",
        accessor: "missionId",
        textAlign: 'center',
      },
      {
        Header: "Start Date",
        accessor: "startDate",
        textAlign: 'center',
      },
      {
        Header: "End Date",
        accessor: "endDate",
        textAlign: 'center',
      },
      {
        Header: "Result",
        accessor: "result",
        textAlign: 'center',
      }
    ],[]
  );

  return (
    <>
      <div className="search-result">
        <div className="sub-title mb-3">
          <div>Recommedation info</div>
        </div>
        <div className="table-wrapper">
          <Form onSubmit={handleSubmit(onValid)} onReset={reset} className="w-100">
            <div className="count-block justify-content-between">
              <div>
              </div>
              <div>
                <span>Showing {result.startIndex}-{result.endIndex} of {result.totalCnt} results</span>
              </div>
            </div>

            <SortingTable columns={columns}
                          data={result?.resultList}
                          defaultSorted={defaultSorted}
                          manualSortBy={true}
                          fnSort={fnSort} />

            <Paging totalPages={result.totalPages}
                    currentPage={result.currentPage}
                    prevPage={result.prevPage}
                    nextPage={result.nextPage}
                    startPage={result.startPage}
                    endPage={result.endPage}
                    displayPageNum={result.displayPageNum}
                    fnPaging={fnPaging} />
          </Form>
        </div>
      </div>
    </>
  );
}

export default StudentRecommedation;