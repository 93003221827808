import DefaultTable from "./DefaultTable";
import {useEffect, useState, useCallback} from "react";
import _ from "lodash";
import Paging from "../common/Paging";

function PagingTable({ columns, data = [], defaultSorted, fnSort, className = '' }) {
  const [pagingInfo, setPagingInfo] = useState({});
  const limit = 10;
  const displayPageNum = 10;

  const fnPaging = useCallback((strPageNum) => {
    const pageNum = Number(strPageNum);
    const offset = (pageNum - 1) * limit

    setPagingInfo({
      ...getPageObject(data, pageNum),
      pagedItems : _.drop(data, offset).slice(0, limit)
    });
  }, [data]);

  useEffect(() => {
    fnPaging(1);
  }, [data, fnPaging]);

  const getPageObject = (data, pageNum) => {
    const totalCnt = _.size(data);
    const offset = (pageNum - 1) * limit;
    const totalPages = Math.ceil(totalCnt / limit);
    const startPage = Math.floor((pageNum - 1) / displayPageNum) * displayPageNum + 1;
    let endPage = Math.ceil(pageNum / displayPageNum) * displayPageNum;
    endPage = endPage > totalPages ? totalPages : endPage;
    const prevPage = startPage === 1 ? 1 : startPage - displayPageNum;
    const nextPage = startPage + displayPageNum > totalPages ? totalPages : startPage + displayPageNum;

    const startIndex = offset + 1;
    let endIndex = offset + limit;
    endIndex = totalCnt < endIndex ? totalCnt : endIndex;

    const result = {
      totalCnt : totalCnt,
      totalPages : Math.ceil(totalCnt / limit),
      currentPage: pageNum,
      prevPage : prevPage,
      nextPage : nextPage,
      startPage : startPage,
      endPage : endPage,
      displayPageNum : displayPageNum,
      startIndex : startIndex,
      endIndex : endIndex
    }

    return result;
  }

  return (
    <>
      <div className="table-wrapper">
        <div className="count-block">
          <span>Showing {pagingInfo.startIndex}-{pagingInfo.endIndex} of {pagingInfo.totalCnt} results</span>
        </div>

        <DefaultTable columns={columns}
                      className={className}
                      defaultSorted={defaultSorted}
                      fnSort={fnSort}
                      data={pagingInfo.pagedItems}
        />

        <Paging totalPages={pagingInfo.totalPages}
                currentPage={pagingInfo.currentPage}
                prevPage={pagingInfo.prevPage}
                nextPage={pagingInfo.nextPage}
                startPage={pagingInfo.startPage}
                endPage={pagingInfo.endPage}
                displayPageNum={pagingInfo.displayPageNum}
                fnPaging={fnPaging} />
      </div>
    </>
  );
}

export default PagingTable;