import _ from "lodash";

function Paging({
                  totalPages,
                  currentPage,
                  prevPage,
                  nextPage,
                  startPage,
                  endPage,
                  displayPageNum,
                  fnPaging
                }) {
  if(totalPages === 0) {
    return <></>;
  }

  function handlePaging(e, pageNum) {
    e.preventDefault();
    fnPaging(pageNum);
  }

  return (
    <>
      <div className="pagination-wrapper">
        <nav aria-label="pagination">
          <ul className="pagination">
            {
              currentPage > displayPageNum &&
              <a className="page-link" href="#!" onClick={(e) => handlePaging(e, prevPage)}
                 tabIndex="-1"  aria-disabled="true" >Previous</a>
            }
            {
              _.range(startPage, endPage + 1).map((pageNum, idx) => {
                return (
                  <li key={idx} className={(currentPage === pageNum ? 'page-item active' : 'page-item')}>
                    <a href="#!" className="page-link" onClick={(e) => handlePaging(e, e.target.text)}>{pageNum}</a>
                  </li>
                )
              })
            }
            {
              nextPage < totalPages &&
              <li className="page-item">
                <a href="#!" className="page-link" onClick={(e) => handlePaging(e, nextPage)}>Next</a>
              </li>
            }
          </ul>
        </nav>
      </div>
    </>
  );
}

export default Paging;