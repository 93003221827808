import _ from 'lodash';
import {useState, useMemo, useRef, useEffect} from "react";
import { useParams, useNavigate } from "react-router-dom";
import {useMutation, useQuery} from "react-query";
import {Controller, useForm} from "react-hook-form";
import { http, toast, Alert, Constant} from "../../../utils";
import HelmetWrapper from "../../../components/common/HelmetWrapper";
import {Col, Form, Row} from "react-bootstrap";
import ErrorMessageFeedback from "../../../components/common/ErrorMessageFeedback";

function MaintenanceDetail() {
  const initValue = useMemo(() => {
    return {
      subject: '',
      message: '',
      allowIpText: '',
      allowIps: [],
    }
  }, []);

  const navigate = useNavigate();
  const urlParam = useParams();
  const [isRegist] = useState(() => _.isNil(urlParam.id));
  const refAllowIpText = useRef();

  const {handleSubmit, reset, formState: {errors}, control, watch, setValue} = useForm({
    mode: 'onChange',
    defaultValues : { ...initValue }
  });

  const {data: result} = useQuery(
    ['searchMessageServerStatusDetail'],
    async () => {
      if(isRegist) {
        return {...initValue };
      }
      return  (await http.get(`/api/v1/message/status/${urlParam.id}`)).data;
    },
    {
      initialData: {},
      onSuccess: (data) => {
        const result = {
          ...data,
          allowIps : data.allowIps || [],
          allowIpText : _.join(data.allowIps || [], '\n')
        }
        reset({ ...result });
      }
    });

  const watchAllowIpText = watch('allowIpText', '');
  const watchAllowIps = watch('allowIps', []);

  useEffect(() => {
    const list = _.split(watchAllowIpText, /\r?\n/)
      .map(v => v.trim())
      .filter((v) => v.length !== 0);

    setValue('allowIps', list);
  }, [watchAllowIpText, setValue]);

  const serverOffAction = useMutation((params) => http.post('/api/v1/message/status', params));
  const serverOnAction = useMutation((id) => http.put(`/api/v1/message/status/${id}`));
  const updateAllowIpAction = useMutation((params) => http.put(`/api/v1/message/status/${params.id}/allow-ip`, params));

  const onValid = (e) => {
    e.preventDefault();

    handleSubmit(async (data) => {
      const confirmMsg = isRegist ? '서버 접속 차단 하시겠습니까?' : '서버 접속 허용 하시겠습니까?';
      const completeMsg = '완료 되었습니다.';
      Alert.confirm(confirmMsg, async () => {
        if(isRegist) {
          await serverOffAction.mutateAsync(data);
        }else {
          await serverOnAction.mutateAsync(data.id);
        }
        toast.success(completeMsg);
        navigate({
          pathname: '/adms/maintenance/view'
        });
      });
    })();
  };

  const onVaildUpdateAllowIp = (e) => {
    e.preventDefault();

    handleSubmit(async (data) => {
      const confirmMsg = 'Allow IP를 수정하시겠습니까?';
      const completeMsg = '완료 되었습니다.';

      Alert.confirm(confirmMsg, async () => {
        await updateAllowIpAction.mutateAsync(data);

        toast.success(completeMsg);
        navigate({
          pathname: '/adms/maintenance/view'
        });
      });
    })();
  }

  return (
    <>
      <div className="contents-fluid">
        <HelmetWrapper meta={{
          title : 'Maintenance'
        }} />
        <div className="page-title">
          Maintenance
        </div>

        <div className="mt-4">
          <Form onSubmit={onValid} onReset={reset} className="w-100">
            {
              !isRegist &&
              <>
                <Row className="mb-4">
                  <Col xs="12" md="6">
                    <div className="detail-row">
                      <span className="label me-4">Status</span> <span className={`${result.status !== Constant.SERVER_STATUS_ON ? 'text-danger fw-bold' : ''}`}>{result.status}</span>
                    </div>
                  </Col>
                </Row>
                <Row className="mb-4">
                  <Col xs="12" md="6">
                    <div className="detail-row">
                      <span className="label me-4">Server OFF Date</span> <span>{result.createDate}</span>
                    </div>
                  </Col>
                  <Col xs="12" md="6">
                    <div className="detail-row">
                      <span className="label me-4">Server ON Date</span> <span>{result.updateDate}</span>
                    </div>
                  </Col>
                </Row>
              </>
            }
            <Row>
              <Col xs="12">
                <Form.Group className="form-group required" controlId="id_subject">
                  <Form.Label>Subject</Form.Label>
                  <Controller
                    name="subject"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) =>
                      <Form.Control
                        {...field}
                        type="text"
                        maxLength="100"
                        placeholder=""
                        readOnly={!isRegist}
                        isInvalid={errors.subject}
                      />
                    }
                  />
                  <ErrorMessageFeedback error={errors.subject} />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs="12">
                <Form.Group className="form-group required" controlId="id_message">
                  <Form.Label>Message</Form.Label>
                  <Controller
                    name="message"
                    control={control}
                    rules={ {required: true} }
                    render={({ field }) =>
                      <Form.Control
                        {...field}
                        as="textarea"
                        rows="10"
                        placeholder=""
                        readOnly={!isRegist}
                        isInvalid={errors.message}
                      />
                    }
                  />
                  <ErrorMessageFeedback error={errors.message} />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs="12">
                <Form.Group className="form-group" controlId="id_allowIpText">
                  <Form.Label>Allow IP ( <span className="fw-bold text-primary">{watchAllowIps.length}</span> 건 )</Form.Label>
                  <Controller
                    name="allowIpText"
                    control={control}
                    render={({ field }) =>
                      <Form.Control
                        {...field}
                        as="textarea"
                        rows="5"
                        placeholder="줄바꿈 으로 (Enter) IP를 구분하여 넣어주세요"
                        ref={refAllowIpText}
                      />
                    }
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs="12" className="btn-wrapper d-flex justify-content-end">
                {
                  isRegist &&
                  <button className="btn btn-danger" type="submit">
                    <span className="material-icons">warning_amber</span>서버 접속 차단
                  </button>
                }
                {
                  !isRegist &&
                  <>
                    <button className="btn btn-outline-success me-2"
                            type="button"
                            disabled={result.status === Constant.SERVER_STATUS_ON}
                            onClick={onVaildUpdateAllowIp}>
                      <span className="material-icons">done</span>Allow IP 수정
                    </button>
                    <button className="btn btn-success" type="submit" disabled={result.status === Constant.SERVER_STATUS_ON}>
                      <span className="material-icons">done</span>서버 접속 허용
                    </button>
                  </>
                }
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    </>
  );
}

export default MaintenanceDetail;