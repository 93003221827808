import { useMemo, useCallback } from "react";
import { Form, Row, Col } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import HelmetWrapper from "../../../components/common/HelmetWrapper";
import { http } from '../../../utils';
import { useQuery } from 'react-query';
import SortingTable from '../../../components/material/SortingTable';
import Paging from '../../../components/common/Paging';
import {Link} from "react-router-dom";

function AdmsUser() {
  const initValue = useMemo(() => {
    return {
      userId: '',
      userName: ''
    }
  }, []);

  const {handleSubmit, reset, control, watch, setValue} = useForm({
    defaultValues : { ...initValue }
  });

  const searchList = async () => {
    const condition = watch();
    return (await http.get('/api/v1/system/adms-user/index', {params: condition})).data;
  };

  const { data: result, refetch : refetchSearch } = useQuery(['searchAdmsUserList'], () => searchList(), {
    enabled: false,
    initialData: { resultList: [] }
  });

  const fnSort = useCallback(async (sortBy) => {
    setValue('sortByName', sortBy.id);
    setValue('desc', sortBy.desc);

    await refetchSearch();
  }, [setValue, refetchSearch]);

  const onValid = async () => {
    setValue('currentPage', 1);
    await refetchSearch();
  };

  const fnPaging = async (pageNum) => {
    setValue('currentPage', pageNum);
    await refetchSearch();
  };

  const defaultSorted = useMemo(() => ({ id: 'createDate', desc: true }), []);
  const columns = useMemo(() => [
      {
        Header: "User ID",
        accessor: "userId",
        textAlign: 'center',
        Cell: ({ row }) => (<Link to={`/adms/system/adms-user/detail/view/${row.original.userId}`}>{row.original.userId}</Link>),
      },
      {
        Header: "User Name",
        accessor: "userName",
        textAlign: 'center',
      },
      {
        Header: "Role",
        accessor: "role",
        textAlign: 'center',
      },
      {
        Header: "Create Date",
        accessor: "createDate",
        textAlign: 'center',
      },
    ],[]
  );

  return (
    <>
      <div className="contents-fluid">
        <HelmetWrapper meta={{
          title : 'Adms User'
        }} />

        <div className="page-title">
          Adms User
        </div>

        <div className="search-condition">
          <Form onSubmit={handleSubmit(onValid)} onReset={reset} className="w-100">
            <Row>
              <Col xs="12" md="6">
                <Form.Group className="form-group" controlId="id_userId">
                  <Form.Label>User ID (Email Address)</Form.Label>
                  <Controller
                    name="userId"
                    control={control}
                    render={({ field }) =>
                      <Form.Control
                        {...field}
                        type="text"
                        placeholder="포함"
                      />
                    }
                  />
                </Form.Group>
              </Col>
              <Col xs="12" md="6">
                <Form.Group className="form-group" controlId="id_userName">
                  <Form.Label>User Name</Form.Label>
                  <Controller
                    name="userName"
                    control={control}
                    render={({ field }) =>
                      <Form.Control
                        {...field}
                        type="text"
                        placeholder="포함"
                      />
                    }
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs="6" className="btn-wrapper justify-content-start">
                <Link to="/adms/system/adms-user/add/view">
                  <button className="btn btn-outline-success" type="button">
                    <span className="material-icons">add</span>Add
                  </button>
                </Link>
              </Col>
              <Col xs="6" className="btn-wrapper">
                <button className="btn btn-primary" type="submit">
                  <span className="material-icons">search</span>Search
                </button>
              </Col>
            </Row>
          </Form>
        </div>

        <div className="search-result">
          <div className="sub-title">검색 결과</div>

          <div className="table-wrapper">
            <div className="count-block">
              <span>Showing {result.startIndex}-{result.endIndex} of {result.totalCnt} results</span>
            </div>

            <SortingTable columns={columns}
                          data={result.resultList}
                          defaultSorted={defaultSorted}
                          manualSortBy={true}
                          fnSort={fnSort} />

            <Paging totalPages={result.totalPages}
                    currentPage={result.currentPage}
                    prevPage={result.prevPage}
                    nextPage={result.nextPage}
                    startPage={result.startPage}
                    endPage={result.endPage}
                    displayPageNum={result.displayPageNum}
                    fnPaging={fnPaging} />
          </div>
        </div>
      </div>
    </>
  );
}

export default AdmsUser;