import {Button, Col, Form, Row} from "react-bootstrap";
import {Controller, useForm} from "react-hook-form";
import {useCallback, useMemo, useState, useEffect} from "react";
import {useMutation, useQuery} from "react-query";
import {Alert, http, toast, Validator} from "../../../../utils";
import SortingTable from "../../../../components/material/SortingTable";
import Paging from "../../../../components/common/Paging";
import AddItemModal from "./AddItemModal";
import ErrorMessageFeedback from "../../../../components/common/ErrorMessageFeedback";

function Inventory({ student = {}, forceRefresh }) {
  const initValue = useMemo(() => {
    return {
      itemId: ''
    }
  }, []);
  const selectItemInitValue = useMemo(() => {
    return {
      itemId: '',
      count: ''
    }
  }, []);

  const [modalItem, setModalItem] = useState({
    isShow: false,
    item: {}
  });

  const {handleSubmit, reset, formState: {errors}, control, setValue, getValues} = useForm({
    mode: 'onChange',
    defaultValues : { ...initValue }
  });

  const { data: result, refetch : refetchSearch } = useQuery(
    ['searchInventory'],
    async () => {
      const param = {
        ...getValues(),
        userId : student?.userId,
        studentId : student?.id
      }
      const result = await http.get(`/api/v1/edit/student/inventory`,{params: param});
      return result.data;
    },
    {
      enabled: false,
      initialData: []
    });
  const deleteItemAction = useMutation((params) => http.delete(`/api/v1/edit/student/inventory/item/${params.userId}/${params.studentId}/${params.id}`));

  useEffect(() => {
    if(!student.id) {
      return;
    }

    async function fetchData() {
      await refetchSearch();
    }
    setValue('currentPage', 1);
    fetchData();

  }, [student, forceRefresh, refetchSearch, setValue]);

  const fnSort = useCallback(async (sortBy) => {
    setValue('sortByName', sortBy.id);
    setValue('desc', sortBy.desc);

    await refetchSearch();
  }, [setValue, refetchSearch]);

  const fnPaging = async (pageNum) => {
    setValue('currentPage', pageNum);
    await refetchSearch();
  };

  const onValid = useCallback(async () => {
    setValue('currentPage', 1);
    await refetchSearch();
  }, [setValue, refetchSearch]);

  const handleDelete = useCallback(async (e, data) => {
    e.preventDefault();
    const confirmMsg = '정말 삭제하시겠습니까?<br/>삭제한 데이터는 복구 할수 없습니다.';
    const completeMsg = '완료되었습니다.';

    Alert.confirm(confirmMsg, async () => {
      const param = {...data, userId : student.userId, studentId: student.id};

      await deleteItemAction.mutateAsync(param);

      toast.success(completeMsg);
      await onValid();
    });
  }, [student, deleteItemAction, onValid]);

  const addItemModalHandler = (e) => {
    e.preventDefault();

    setModalItem({
      isShow: true,
      item: {
        isRegist : true,
        userId: student.userId,
        studentId: student.id,
        name : student.name,
        selectedItem : {...selectItemInitValue}
      }
    });
  };

  const updateItemModalHandler = useCallback((e, itemData) => {
    e.preventDefault();

    setModalItem({
      isShow: true,
      item: {
        isRegist : false,
        userId: student.userId,
        studentId: student.id,
        name : student.name,
        selectedItem : itemData
      }
    });
  }, [student]);

  const handleModalHide = async (isRefresh = false) => {
    setModalItem({
      isShow: false,
      item: {}
    });
    if(isRefresh) {
      await onValid();
    }
  };

  const defaultSorted = useMemo(() => ({ id: 'id', desc: false }), []);
  const columns = useMemo(() => [
      {
        Header: "Category",
        accessor: "itemCategory",
        textAlign: 'center',
      },
      {
        Header: "Item Id",
        accessor: "itemId",
        textAlign: 'center',
        Cell: ({ row }) => (<a href="#!" onClick={(e) => updateItemModalHandler(e, row.original)}>{row.original.itemId}</a>),
      },
      {
        Header: "Name",
        accessor: "name",
        textAlign: 'center',
      },
      {
        Header: "Name Native",
        accessor: "nameNative",
        textAlign: 'center',
      },
      {
        Header: "Count",
        accessor: "count",
        textAlign: 'center',
      },
      {
        Header: "Item Count Limit",
        accessor: "itemCountLimit",
        textAlign: 'center',
        Cell: ({ row }) => (row.original.itemCountLimit === 0 ? '' : row.original.itemCountLimit),
      },
      {
        Header: "",
        accessor: "userId",
        textAlign: 'center',
        disableSortBy: true,
        Cell: ({ row }) => (<Button type="button"
                                    variant="outline-danger"
                                    onClick={(e) => handleDelete(e, row.original)}>
                                    <span className="material-icons">delete_outline</span>Delete</Button>),
      }
    ],[updateItemModalHandler, handleDelete]
  );

  return (
    <>
      <div className="search-condition">
        <Form onSubmit={handleSubmit(onValid)} onReset={reset} className="w-100">
          <Row>
            <Col xs="12" md="6">
              <Form.Group className="form-group" controlId="id_itemId">
                <Form.Label>Item ID</Form.Label>
                <Controller
                  name="itemId"
                  control={control}
                  rules={{
                    validate: {
                      number: (value) => Validator.validateNumber(value)
                    }
                  }}
                  render={({ field }) =>
                    <Form.Control
                      {...field}
                      type="text"
                      placeholder="포함"
                      isInvalid={errors.itemId}
                    />
                  }
                />
                <ErrorMessageFeedback error={errors.itemId} />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col xs="12" className="btn-wrapper">
              <button className="btn btn-outline-primary" type="submit">
                <span className="material-icons">search</span>Search
              </button>
            </Col>
          </Row>
        </Form>
      </div>

      <div className="search-result">
        <div className="sub-title">검색 결과</div>

        <div className="table-wrapper">
          <div className="count-block justify-content-between">
            <div>
              <button className="btn btn-outline-info" type="button" onClick={addItemModalHandler}>
                <span className="material-icons">add</span>Add Item
              </button>
            </div>
            <div>
              <span>Showing {result.startIndex}-{result.endIndex} of {result.totalCnt} results</span>
            </div>
          </div>

          <SortingTable columns={columns}
                        data={result.resultList}
                        defaultSorted={defaultSorted}
                        manualSortBy={true}
                        fnSort={fnSort} />

          <Paging totalPages={result.totalPages}
                  currentPage={result.currentPage}
                  prevPage={result.prevPage}
                  nextPage={result.nextPage}
                  startPage={result.startPage}
                  endPage={result.endPage}
                  displayPageNum={result.displayPageNum}
                  fnPaging={fnPaging} />
        </div>
      </div>

      <AddItemModal
        onHide={handleModalHide}
        modalItem={modalItem}
      />
    </>
  );
}

export default Inventory;