import moment from 'moment';

const format = 'YYYY-MM-DD HH:mm:ss';

export function today() {
  return moment(moment().format('YYYY-MM-DD') + '00:00:00', format).toDate();
}

export function todayTime(time) {
  return moment(moment().format('YYYY-MM-DD') + ' ' + time, format).toDate();
}

export function getDate(datetime) {
  if(!datetime) {
    return datetime;
  }
  return moment(datetime, format).toDate();
}

export function stringFormat(dateTime) {
  return moment(dateTime).format(format);
}