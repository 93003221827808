import { Form, Row, Col } from 'react-bootstrap';
import HelmetWrapper from '../components/common/HelmetWrapper';
import { useForm, Controller } from 'react-hook-form';
import ErrorMessageFeedback from '../components/common/ErrorMessageFeedback';
import { useNavigate } from "react-router-dom";
import {useMemo} from "react";
import { useMutation } from 'react-query';
import { http, toast, Constant } from "../utils";
import { store } from "../store/store";
import { loginInfoSlice } from "../store/LoginInfoSlice";

function Login() {
  const initValue = useMemo(() => {
    return {
      userId: '',
      password: ''
    }
  }, []);

  const {handleSubmit, reset, formState: {errors}, control } = useForm({
    defaultValues : { ...initValue }
  });
  const loginAction = useMutation((params) => http.post('/api/v1/login', params));

  const navigate = useNavigate();

  const onValid = async (data) => {
    const res = await loginAction.mutateAsync(data);

    const loginResult = res.data.loginResult;
    if(loginResult.message) {
      toast.error(loginResult.message);
      return;
    }
    store.dispatch(loginInfoSlice.actions.login(loginResult));

    navigate(Constant.HOME_URL);
  }

  return (
    <>
      <HelmetWrapper meta={{
        title : 'Login'
      }} />

      <div id="login-wrapper" className="d-flex flex-wrap">
        <div className="container">
          <div className="login-content">
            <div className="login-title">
              Archipin Data Management System
            </div>
            <div className="login-form mt-5">
              <Form onSubmit={handleSubmit(onValid)} onReset={reset} className="w-100">
                <Row>
                  <Col xs="12" className="mb-3">
                    <Form.Group className="form-group" controlId="id_userId">
                      <Form.Label>Email address</Form.Label>
                      <Controller
                        name="userId"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => {
                          return <Form.Control
                            {...field}
                            type="text"
                            isInvalid={errors.userId}
                          />;
                        }}
                      />
                      <ErrorMessageFeedback error={errors.userId} />
                    </Form.Group>
                  </Col>
                  <Col xs="12"  className="mb-3">
                    <Form.Group className="form-group" controlId="id_password">
                      <Form.Label>Password</Form.Label>
                      <Controller
                        name="password"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => {
                          return <Form.Control
                            {...field}
                            type="password"
                            isInvalid={errors.password}
                          />;
                        }}
                      />
                      <ErrorMessageFeedback error={errors.password} />
                    </Form.Group>
                  </Col>
                </Row>

                <button className="btn btn-primary mt-3 w-100" type="submit">로그인</button>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;