import {Button, Col, Row, Modal, Table} from "react-bootstrap";
import {useMutation, useQuery} from "react-query";
import { http, Alert } from '../../../utils';
import { Link } from "react-router-dom";
import {toast} from "react-toastify";
import {useState} from "react";

function AccountDetailModal({onHide, modalItem}) {
  const {
    isShow,
    item,
  } = modalItem;

  const { data: result, refetch : refetchSearchDetail } = useQuery(
    ['searchUserDetail'],
    () => http.get(`/api/v1/user/account/${item.id}`).then((r) => {
      getMembership(r.data.studentList)
      return r.data
    }),
    {
              enabled: false,
              initialData: { }
            });

  const init = async () => {
    await refetchSearchDetail();
  };
  const [forceRefresh, setForceRefresh] = useState(0)
  const [membership, setMembership] = useState("")
  const onValid = async () => {
    await refetchSearchDetail();
    setForceRefresh(forceRefresh + 1);
  };
  const handleClose = () => {
    onHide();
  };
  const updateAction = useMutation((role) => http.put(`/api/v1/user/account/${result.id}?role=` + role));
  const handleUpdate = async (roleText) => {
    const updatedRole = roleText

    const confirmMsg = '계정 권한을 변경하시겠습니까?<br>변경 내역<br>' + result.role + '&#12288;&#8594;&#12288;' + updatedRole
    const completeMsg = '변경 되었습니다.'

    Alert.confirm(confirmMsg, async() => {
      await updateAction.mutateAsync(updatedRole);
      toast.success(completeMsg)
      await onValid();
    })
  }
  //삭제되지 않은 유저의 membership 정보를 반환한다.
  const getMembership = (list) => {
    list.forEach((item) => {
      if(!item.delete) {
        if(item.userLudiPass) return setMembership(item.userLudiPass.endDate)
      }
      return setMembership("데이터X")
    })
  }

  const getSsoInfo = (userInfo) => {
    if(userInfo.googleId) {
      return `${userInfo.googleId} (google)`;
    }
    if(userInfo.kakaoId) {
      return `${userInfo.kakaoId} (kakao)`;
    }
    if(userInfo.appleId) {
      return `${userInfo.appleId} (apple)`;
    }
    if(userInfo.naverId) {
      return `${userInfo.naverId} (naver)`;
    }
    if(userInfo.snsLogin) {
      return userInfo.snsLogin;
    }
  };

  return (
    <>
      <Modal
        size="xl"
        show={isShow}
        onHide={handleClose}
        className="detail"
        onEntered={() => {
          init();
        }}
      >

        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">Account Detail</Modal.Title>
          <Button type="button" className="close" aria-label="Close" onClick={handleClose}>
            <span aria-hidden="true">&times;</span>
          </Button>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xs="12" md="6">
              <div className="detail-row">
                <span className="label">UserId</span> <span>{result.id}</span>
              </div>
            </Col>
            <Col xs="12" md="6">
              <div className="detail-row">
                <span className="label">Role</span> <span>{result.role}</span>
                &nbsp;&nbsp;<Button type="button" onClick={() => handleUpdate('NORMAL')}>NORMAL</Button>
                &nbsp;&nbsp;<Button type="button" onClick={() => handleUpdate('TEST')}>TEST</Button>
                &nbsp;&nbsp;<Button type="button" onClick={() => handleUpdate('FREETIME')}>FREETIME</Button>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs="12" md="6">
              <div className="detail-row">
                <span className="label">Create Date</span> <span>{result.createDate}</span>
              </div>
            </Col>
            <Col xs="12" md="6">
              <div className="detail-row">
                <span className="label">Membership</span>
                {membership}
              </div>
            </Col>
            <Col xs="12" md="6">
              <div className="detail-row">
                <span className="label">SSO Info</span> <span>{getSsoInfo(result)}</span>
              </div>
            </Col>
            <Col xs="12" md="6">
              <div className="detail-row">
                <span className="label">Phone</span> <span>{result.phoneNumber}</span>
              </div>
            </Col>
            <Col xs="12">
              <div className="detail-row studentList">
                <div className="label">Student</div>
                <Col xs="12">
                  <Table responsive bordered hover className='text-center col-12'>
                    <thead className="table-dark">
                    <tr>
                      <th scope="col">ID</th>
                      <th scope="col">Name</th>
                      <th scope="col">Last Login Date</th>
                      <th scope="col">is Delete</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                      result?.studentList?.map((item, idx) => {
                        return (
                          <tr key={idx} className={item.delete ? 'deleted-student' : ''}>
                            <td><Link target="student-search"
                                      to={`/adms/user/student-info/detail/view?studentId=${item.id}`}>{item.id}</Link></td>
                            <td>{item.name}</td>
                            <td>{item.lastAccesses.endDate}</td>
                            <td>{item.delete ? 'O' : 'X'}</td>
                          </tr>
                        );
                      })
                    }
                    {
                      (!result || result.studentList?.length === 0) &&
                      <tr>
                        <td colSpan='4' className="text-center">검색 결과가 없습니다.</td>
                      </tr>
                    }
                    </tbody>
                  </Table>
                </Col>
              </div>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default AccountDetailModal;