import React, { useEffect } from 'react';
import { Spinner } from 'react-bootstrap';
import { connect } from 'react-redux';

function Loader({ loaderCount }) {
  const overlay = {
    position: "fixed",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    background: "black",
    opacity: ".5",
    zIndex: "2000"
  };
  const loader = {
    position: "absolute",
    top: "50%",
    left: "50%",
  };

  useEffect(() => {
    if(loaderCount > 0) {
      document.body.style.overflow = "hidden";
    }else {
      document.body.removeAttribute('style');
    }
  }, [loaderCount]);

  return (
    loaderCount > 0 &&
      <div style={overlay}>
        <div style={loader}>
          <Spinner animation="border" role="status" variant="primary"></Spinner>
        </div>
      </div>
  );
}

function mapStateToProps(state) {
  return {
    loaderCount: state.loaderCount.count
  };
}

export default connect(mapStateToProps)(Loader);