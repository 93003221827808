import HelmetWrapper from "../../../components/common/HelmetWrapper";
import {Col, Form, Row} from "react-bootstrap";
import {Controller, useForm} from "react-hook-form";
import DatePicker from "react-datepicker";
import DatePickerInput from "../../../components/material/DatePickerInput";
import ErrorMessageFeedback from "../../../components/common/ErrorMessageFeedback";
import React, {useMemo} from "react";
import qs from "qs";
import {toast} from "react-toastify";

function TestResult() {
  const initValue = useMemo(() => {
    return {
      startDate: null,
      endDate: null,
    }
  }, []);

  const {handleSubmit, reset, formState: {errors, isValid}, control, getValues } = useForm({
    mode: 'onChange',
    defaultValues : { ...initValue }
  });

  const onValid = (data) => {

  }
  const onDownload = async (e, type) => {
    e.preventDefault();
    if(!isValid) {
      toast.error('기간을 올바르게 설정하세요');
      return;
    }
    const urlMap = {
      'step1Listening' : '/api/v1/online-test/report/step1/listening',
      'step1Reading' : '/api/v1/online-test/report/step1/reading',
      'step2Listening' : '/api/v1/online-test/report/step2/listening',
      'step2Reading' : '/api/v1/online-test/report/step2/reading',
      'step3Listening' : '/api/v1/online-test/report/step3/listening',
      'step3Reading' : '/api/v1/online-test/report/step3/reading',
    }
    if(!urlMap[type]) {
      return;
    }
    const param = {...getValues() };
    const url = `${urlMap[type]}?${qs.stringify(param)}`;
    window.open(url, '_blank');
  };

  return (
    <>
      <div className="contents-fluid">
        <HelmetWrapper meta={{
          title : '서울대 온라인 테스트 결과'
        }} />

        <div className="page-title">
          서울대 온라인 테스트 결과
        </div>

        <div className="search-condition">
          <Form onSubmit={handleSubmit(onValid)} onReset={reset} className="w-100">
            <Row>
              <Col xs="12" className="mb-4">
                <Form.Group className="form-group required mb-0">
                  <Form.Label>Date</Form.Label>
                </Form.Group>

                <div className="condition-date-picker">
                  <Form.Group className="form-group mb-0">
                    <Controller
                      name="startDate"
                      control={control}
                      rules={ {required: false} }
                      render={({ field, fieldState }) =>
                        <DatePicker
                          {...field}
                          dateFormat="yyyy/MM/dd HH:mm"
                          selected={field.value}
                          showTimeSelect
                          timeIntervals={60}
                          autoComplete="off"
                          onChange={(date) => {
                            field.onChange(date);
                          }}
                          className={fieldState.invalid ? 'is-invalid' : ''}
                          customInput={ <DatePickerInput /> }
                        />
                      }
                    />
                    <ErrorMessageFeedback error={errors.startDate} />
                  </Form.Group>

                  <div className='ms-2 me-2'> ~ </div>

                  <Form.Group className="form-group mb-0">
                    <Form.Label></Form.Label>
                    <Controller
                      name="endDate"
                      control={control}
                      rules={ {required: false} }
                      render={({ field, fieldState }) =>
                        <DatePicker
                          {...field}
                          dateFormat="yyyy/MM/dd HH:mm"
                          selected={field.value}
                          showTimeSelect
                          timeIntervals={60}
                          autoComplete="off"
                          onChange={(date) => {
                            field.onChange(date);
                          }}
                          className={fieldState.invalid ? 'is-invalid' : ''}
                          customInput={ <DatePickerInput /> }
                        />
                      }
                    />
                    <ErrorMessageFeedback error={errors.endDate} />
                  </Form.Group>
                </div>
              </Col>
            </Row>
          </Form>

          <div className="search-result">
            <div className="pt-1 pb-2"><a href="#!" onClick={(e) => onDownload(e, 'step1Listening')}>step1 listening<i className="fas fa-file-download ms-2"></i></a></div>
            <div className="pt-1 pb-2"><a href="#!" onClick={(e) => onDownload(e, 'step1Reading')}>step1 reading<i className="fas fa-file-download ms-2"></i></a></div>

            <div className="pt-1 pb-2"><a href="#!" onClick={(e) => onDownload(e, 'step2Listening')}>step2 listening<i className="fas fa-file-download ms-2"></i></a></div>
            <div className="pt-1 pb-2"><a href="#!" onClick={(e) => onDownload(e, 'step2Reading')}>step2 reading<i className="fas fa-file-download ms-2"></i></a></div>

            <div className="pt-1 pb-2"><a href="#!" onClick={(e) => onDownload(e, 'step3Listening')}>step3 listening<i className="fas fa-file-download ms-2"></i></a></div>
            <div className="pt-1 pb-2"><a href="#!" onClick={(e) => onDownload(e, 'step3Reading')}>step3 reading<i className="fas fa-file-download ms-2"></i></a></div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TestResult;