export const LOGIN_URL = '/adms/login/view';
export const HOME_URL = '/adms/user/account-info/view';

export const ROLE_ADMIN = 'ADMIN';
export const ROLE_GENERAL = 'GENERAL';
export const ROLE_LEVEL_1 = '1';
export const ROLE_LEVEL_2 = '2';
export const ROLE_LEVEL_3 = '3';
export const ROLE_LEVEL_4 = '4';

export const SERVER_STATUS_ON = 'ON';

export const CODE_ADMS_USER_ROLE = [
  {code : '1', name: 'Level 1'},
  {code : '2', name: 'Level 2'},
  {code : '3', name: 'Level 3'},
  {code : '4', name: 'Level 4'},
];
export const CODE_ADMS_USER_STATUS_CD = [
  {code : '00', name: 'Normal'},
  {code : '10', name: 'Lock'},
];

export const CODE_ADMS_EXCEL_UPLOADY_HISTORY_CD = [
  {code : '01', name: 'Success'},
  {code : '10', name: 'Fail'},
];

export const CODE_NPC_STATE_CD = [
  {code : 'NotOwned', name: 'NotOwned'},
  {code : 'CannotConversation', name: 'CannotConversation'},
  {code : 'Idle', name: 'Idle'},
];

export const CODE_RATIONALE_CD = [
  {code : 'DI', name: 'DI'},
  {code : 'UKN', name: 'UKN'},
  {code : 'SILENCE', name: 'SILENCE'},
  {code : 'PARDON', name: 'PARDON'},
  {code : 'REVERT', name: 'REVERT'},
  {code : 'SECOND_UKN', name: 'SECOND_UKN'},
  {code : 'SKIP_1', name: 'SKIP_1'},
  {code : 'CLOSEST_PT', name: 'CLOSEST_PT'},
  {code : 'FORCE_PT', name: 'FORCE_PT'},
  {code : 'YES_ON_CQ', name: 'YES_ON_CQ'},
  {code : 'NO_ON_CQ', name: 'NO_ON_CQ'},
  {code : 'SINGLE_WH', name: 'SINGLE_WH'},
  {code : 'P2F', name: 'P2F'},
  {code : 'GrammarCorrection', name: 'GrammarCorrection'},
  {code : 'BYPASS', name: 'BYPASS'},
  {code : 'GENERIC', name: 'GENERIC'},
  {code : 'ENTITY_ONLY', name: 'ENTITY_ONLY'},
  {code : 'FOUND_MAIN_BRANCH', name: 'FOUND_MAIN_BRANCH'},
  {code : 'FOUND_BRANCH', name: 'FOUND_BRANCH'},
  {code : 'DEFAULT', name: 'DEFAULT'},
  {code : 'PASS_MAIN_BRANCH', name: 'PASS_MAIN_BRANCH'},
];

export const CODE_COMPETENCE_TYPE_CD = [
  {code : 'vocabulary', name: 'vocabulary'},
  {code : 'function', name: 'function'},
  {code : 'grammar', name: 'grammar'},
];

export const CODE_VOICE_HUNTER_DMLOG_RESULT = [
  {code : 'Success', name: 'Success'},
  {code : 'Fail', name: 'Fail'}
];

export const CODE_SERVER_STATUS = [
  {code : 'OFF', name: 'OFF'},
  {code : 'ON', name: 'ON'}
];

export const CODE_STUDENT_LINK_STATUS = [
  {code : 'InProgress', name: 'InProgress'},
  {code : 'Complete', name: 'Complete'},
  {code : 'Reject', name: 'Reject'},
];