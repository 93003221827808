import React from "react";

function RawDataTab({onDownload} ) {
  const handleDownload = (e, type) => {
    e.preventDefault();
    onDownload(type)
  }

    return (
    <>
      <span>별도의 설명이 없으면 검색박스의 Date는 로그 생성일자를 의미합니다.</span>
      <div className="pt-1 pb-2"><a href="#!" onClick={(e) => handleDownload(e, 'user-info')}>UserInfo(Date: user 생성일)<i className="fas fa-file-download ms-2"></i></a></div>
      <div className="pt-1 pb-2"><a href="#!" onClick={(e) => handleDownload(e, 'student')}>Student(Date: student 생성일)<i className="fas fa-file-download ms-2"></i></a></div>
      <div className="pt-1 pb-2"><a href="#!" onClick={(e) => handleDownload(e, 'student-portal-cycle')}>Student portal cycle<i className="fas fa-file-download ms-2"></i></a></div>
      <br/>
      <div className="pt-1 pb-2"><a href="#!" onClick={(e) => handleDownload(e, 'level-test')}>Level Test(Date: part1 startTime)<i className="fas fa-file-download ms-2"></i></a></div>
      <div className="pt-1 pb-2"><a href="#!" onClick={(e) => handleDownload(e, 'dailyReport')}>Daily report<i className="fas fa-file-download ms-2"></i></a></div>
      <br/>
      <div className="pt-1 pb-2"><a href="#!" onClick={(e) => handleDownload(e, 'mission-scoring')}>Mission Scoring<i className="fas fa-file-download ms-2"></i></a></div>
      <div className="pt-1 pb-2"><a href="#!" onClick={(e) => handleDownload(e, 'mission-scoring-vocab')}>Mission Scoring(Vocabulary)<i className="fas fa-file-download ms-2"></i></a></div>
      <div className="pt-1 pb-2"><a href="#!" onClick={(e) => handleDownload(e, 'mission-scoring-function')}>Mission Scoring(Function)<i className="fas fa-file-download ms-2"></i></a></div>
      <div className="pt-1 pb-2"><a href="#!" onClick={(e) => handleDownload(e, 'mission-scoring-grammar')}>Mission Scoring(Grammar)<i className="fas fa-file-download ms-2"></i></a></div>
      <div className="pt-1 pb-2"><a href="#!" onClick={(e) => handleDownload(e, 'mission-scoring-pronunciation')}>Mission Scoring(Pronunciation)<i className="fas fa-file-download ms-2"></i></a></div>
      <br/>
      <div className="pt-1 pb-2"><a href="#!" onClick={(e) => handleDownload(e, 'content-scoring')}>Content Scoring<i className="fas fa-file-download ms-2"></i></a></div>
      <div className="pt-1 pb-2"><a href="#!" onClick={(e) => handleDownload(e, 'content-scoring-vocab')}>Content Scoring(Vocabulary)<i className="fas fa-file-download ms-2"></i></a></div>
      <div className="pt-1 pb-2"><a href="#!" onClick={(e) => handleDownload(e, 'content-scoring-function')}>Content Scoring(Function)<i className="fas fa-file-download ms-2"></i></a></div>
      <div className="pt-1 pb-2"><a href="#!" onClick={(e) => handleDownload(e, 'content-scoring-grammar')}>Content Scoring(Grammar)<i className="fas fa-file-download ms-2"></i></a></div>
      <div className="pt-1 pb-2"><a href="#!" onClick={(e) => handleDownload(e, 'content-scoring-pronunciation')}>Content Scoring(Pronunciation)<i className="fas fa-file-download ms-2"></i></a></div>
      <br/>
      <div className="pt-1 pb-2"><a href="#!" onClick={(e) => handleDownload(e, 'mission')}>Mission(Date: Mission start Date)<i className="fas fa-file-download ms-2"></i></a></div>
      <div className="pt-1 pb-2"><a href="#!" onClick={(e) => handleDownload(e, 'recommedation')}>Recommendation(Date: Recommendation start Date)<i className="fas fa-file-download ms-2"></i></a></div>
      <br/>
      <div className="pt-1 pb-2"><a href="#!" onClick={(e) => handleDownload(e, 'student-item')}>Student Item(Date: student 생성일)<i className="fas fa-file-download ms-2"></i></a></div>
      <div className="pt-1 pb-2"><a href="#!" onClick={(e) => handleDownload(e, 'student-structure')}>Student Structure<i className="fas fa-file-download ms-2"></i></a></div>
      <div className="pt-1 pb-2"><a href="#!" onClick={(e) => handleDownload(e, 'interaction-mh')}>Interaction MH<i className="fas fa-file-download ms-2"></i></a></div>
      <div className="pt-1 pb-2"><a href="#!" onClick={(e) => handleDownload(e, 'game-day')}>GameDay<i className="fas fa-file-download ms-2"></i></a></div>
      <div className="pt-1 pb-2"><a href="#!" onClick={(e) => handleDownload(e, 'boss-hunting')}>Boss Hunting<i className="fas fa-file-download ms-2"></i></a></div>
      <br/>
      <div className="pt-1 pb-2"><a href="#!" onClick={(e) => handleDownload(e, 'student-accesses')}>Student Accesses<i className="fas fa-file-download ms-2"></i></a></div>
      <div className="pt-1 pb-2"><a href="#!" onClick={(e) => handleDownload(e, 'student-ludienergy')}>Student ludienergy<i className="fas fa-file-download ms-2"></i></a></div>
      <div className="pt-1 pb-2"><a href="#!" onClick={(e) => handleDownload(e, 'student-pin')}>Student pin<i className="fas fa-file-download ms-2"></i></a></div>
      <div className="pt-1 pb-2"><a href="#!" onClick={(e) => handleDownload(e, 'student-inventory')}>Student inventory<i className="fas fa-file-download ms-2"></i></a></div>
      <div className="pt-1 pb-2"><a href="#!" onClick={(e) => handleDownload(e, 'student-npc-friendship')}>Student npc friendship<i className="fas fa-file-download ms-2"></i></a></div>
      <div className="pt-1 pb-2"><a href="#!" onClick={(e) => handleDownload(e, 'student-npc-friendship-grade')}>Student npc friendship grade<i className="fas fa-file-download ms-2"></i></a></div>
      {/*<div className="pt-1 pb-2"><a href="#!" onClick={(e) => handleDownload(e, 'recommedation-cbt3')}>Recommedation CBT3<i className="fas fa-file-download ms-2"></i></a></div>*/}
      {/*<div className="pt-1 pb-2"><a href="#!" onClick={(e) => handleDownload(e, 'dmLog')}>DM Log<i className="fas fa-file-download ms-2"></i></a></div>*/}
      <div className="pt-1 pb-2"><a href="#!" onClick={(e) => handleDownload(e, 'interaction-fc')}>Interaction FC<i className="fas fa-file-download ms-2"></i></a></div>
      <div className="pt-1 pb-2"><a href="#!" onClick={(e) => handleDownload(e, 'interaction-zg')}>Interaction ZG<i className="fas fa-file-download ms-2"></i></a></div>
      <div className="pt-1 pb-2"><a href="#!" onClick={(e) => handleDownload(e, 'interaction-ag')}>Interaction AG<i className="fas fa-file-download ms-2"></i></a></div>
      <div className="pt-1 pb-2"><a href="#!" onClick={(e) => handleDownload(e, 'interaction-je')}>Interaction JE<i className="fas fa-file-download ms-2"></i></a></div>
      <div className="pt-1 pb-2"><a href="#!" onClick={(e) => handleDownload(e, 'interaction-o2g')}>Interaction O2G<i className="fas fa-file-download ms-2"></i></a></div>
      <div className="pt-1 pb-2"><a href="#!" onClick={(e) => handleDownload(e, 'interaction-pet')}>Interaction PET<i className="fas fa-file-download ms-2"></i></a></div>
      <div className="pt-1 pb-2"><a href="#!" onClick={(e) => handleDownload(e, 'vending-machine')}>VendingMachine<i className="fas fa-file-download ms-2"></i></a></div>
      <div className="pt-1 pb-2"><a href="#!" onClick={(e) => handleDownload(e, 'review-content')}>ReviewContent<i className="fas fa-file-download ms-2"></i></a></div>
      {/*<div className="pt-1 pb-2"><a href="#!" onClick={(e) => handleDownload(e, 'ac-cbt3')}>AC (CBT3)<i className="fas fa-file-download ms-2"></i></a></div>*/}
      {/*<div className="pt-1 pb-2"><a href="#!" onClick={(e) => handleDownload(e, 'content-scoring-cbt3')}>Content Scoring (CBT3)<i className="fas fa-file-download ms-2"></i></a></div>*/}
      <div className="pt-1 pb-2"><a href="#!" onClick={(e) => handleDownload(e, 'parent-report-search')}>Parent Report Search(학부모 리포트 조회 history)<i className="fas fa-file-download ms-2"></i></a></div>
      <div className="pt-1 pb-2"><a href="#!" onClick={(e) => handleDownload(e, 'progress')}>Progress<i className="fas fa-file-download ms-2"></i></a></div>
    </>
  );
}

export default RawDataTab;