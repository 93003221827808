
import DefaultTable from "../../../../components/material/DefaultTable";
import {useCallback, useEffect, useMemo, useState} from "react";
import {useQuery} from "react-query";
import {http} from "../../../../utils";
import AddNpcModal from "./AddNpcModal";

function NpcState({ student = {}, forceRefresh }) {
  const [modalItem, setModalItem] = useState({
    isShow: false,
    item: {}
  });
  const selectItemInitValue = useMemo(() => {
    return {
      npcId: '',
      npcState: ''
    }
  }, []);

  const { data: result, refetch : refetchSearch } = useQuery(
    ['searchEditStudentNpcState'],
    async () => {
      const param = {
        studentId : student?.id
      }
      const result = await http.get(`/api/v1/edit/student/npc-state`,{params: param});
      return result.data;
    },
    {
      enabled: false,
      initialData: []
    });

  useEffect(() => {
    if(!student.id) {
      return;
    }

    async function fetchData() {
      await refetchSearch();
    }
    fetchData().then();

  }, [student, forceRefresh, refetchSearch]);

  const addModalHandler = (e) => {
    e.preventDefault();

    setModalItem({
      isShow: true,
      item: {
        isRegist : true,
        userId: student.userId,
        studentId: student.id,
        name : student.name,
        selectedItem : {...selectItemInitValue}
      }
    });
  };

  const updateModalHandler = useCallback((e, itemData) => {
    e.preventDefault();

    setModalItem({
      isShow: true,
      item: {
        isRegist : false,
        userId: student.userId,
        studentId: student.id,
        name : student.name,
        selectedItem : itemData
      }
    });
  }, [student]);

  const handleModalHide = async (isRefresh = false) => {
    setModalItem({
      isShow: false,
      item: {}
    });
    if(isRefresh) {
      await refetchSearch();
    }
  };

  const columns = [
    {
      Header: "NPC ID",
      accessor: "npcId",
      textAlign: 'center',
      width:'33%',
      Cell: ({ row }) => (<a href="#!" onClick={(e) => updateModalHandler(e, row.original)}>{row.original.npcId}</a>),
    },
    {
      Header: "NPC Name",
      accessor: "npcName",
      textAlign: 'center',
      width:'33%',
    },
    {
      Header: "NPC State",
      accessor: "npcState",
      textAlign: 'center',
      width:'33%',
    }
  ];

  return (
    <>
      <div className="search-result">

        <div className="table-wrapper">
          <div className="count-block justify-content-between">
            <div>
              <button className="btn btn-outline-info" type="button" onClick={addModalHandler}>
                <span className="material-icons">add</span>Add Npc
              </button>
            </div>
            <div>
              <span>Showing {result.startIndex}-{result.endIndex} of {result.totalCnt} results</span>
            </div>
          </div>

          <DefaultTable columns={columns}
                        data={result.resultList}
          />
        </div>
      </div>

      <AddNpcModal
        onHide={handleModalHide}
        modalItem={modalItem}
      />
    </>
  );
}

export default NpcState;