import {Button, Modal, Row, Col} from "react-bootstrap";
import ReactAudioPlayer from "react-audio-player";

function AudioPlayModal({onHide, modalItem, autoPlay = false}) {
  const {
    isShow,
    url,
  } = modalItem;

  const handleClose = () => {
    onHide();
  };

  return (
    <>
      <Modal
        size="md"
        show={isShow}
        onHide={handleClose}
        className="detail audio-play-modal pt-3"
        backdropClassName="audio-play-backdrop"
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">Audio Play</Modal.Title>
          <Button type="button" className="close" aria-label="Close" onClick={handleClose}>
            <span aria-hidden="true">&times;</span>
          </Button>
        </Modal.Header>
        <Modal.Body>
          <Row className="text-center">
            <Col xs="12">
              {
                url &&
                <ReactAudioPlayer
                  src={url}
                  autoPlay={autoPlay}
                  controls
                  controlsList={"nodownload nofullscreen noplaybackrate"}
                />
              }
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default AudioPlayModal;