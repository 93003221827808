import { Table } from "react-bootstrap";
import {Formatter} from "../../../utils";

function UserInfoTab({ data }) {

  return (
    <>
      <Table className="report-table">
        <colgroup>
          <col width="30%" />
          <col width="*" />
        </colgroup>
        <tbody>
          <tr>
            <th>신규 유저</th>
            <td>{Formatter.numberWithCommas(data?.newUserCount)}</td>
          </tr>
          <tr>
            <th>누적 유저</th>
            <td>{Formatter.numberWithCommas(data?.userCount)}</td>
          </tr>
          <tr>
            <th>신규 학습자</th>
            <td>{Formatter.numberWithCommas(data?.newStudentCount)}</td>
          </tr>
          <tr>
            <th>누적 학습자</th>
            <td>{Formatter.numberWithCommas(data?.studentCount)}</td>
          </tr>
          <tr>
            <th>삭제 학습자</th>
            <td>{Formatter.numberWithCommas(data?.deleteStudentCount)}</td>
          </tr>
          <tr>
            <th>누적 삭제 학습자</th>
            <td>{Formatter.numberWithCommas(data.deleteTotalStudentCount)}</td>
          </tr>
        </tbody>
      </Table>
    </>
  );
}

export default UserInfoTab;