import _ from 'lodash';
import { useState, useRef, useMemo } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {useMutation, useQuery} from "react-query";
import {Controller, useForm} from "react-hook-form";
import { http, toast, Validator, Constant, Alert} from "../../../utils";
import HelmetWrapper from "../../../components/common/HelmetWrapper";
import {Col, Form, Row} from "react-bootstrap";
import ErrorMessageFeedback from "../../../components/common/ErrorMessageFeedback";

function AdmsUserDetail() {
  const initValue = useMemo(() => {
    return {
      userId: '',
      userName: '',
      statusCd: _.first(Constant.CODE_ADMS_USER_STATUS_CD).code,
      role: _.first(Constant.CODE_ADMS_USER_ROLE).code,
      password: '',
      passwordConfirm: ''
    }
  }, []);
  const navigate = useNavigate();
  const urlParam = useParams();
  const [isRegist] = useState(() => _.isNil(urlParam.userId));

  const {handleSubmit, reset, formState: {errors}, control, watch} = useForm({
    defaultValues : { ...initValue }
  });

  const password = useRef();
  password.current = watch("password", "");

  useQuery(
    ['searchAdmsUserDetail'],
    async () => {
      if(isRegist) {
        return {...initValue };
      }
      return  (await http.get(`/api/v1/system/adms-user/${urlParam.userId}`)).data;
    },
    {
      initialData: {},
      onSuccess: (data) => {
        reset({ ...data });
      }
    });

  const insertAdmsUserAction = useMutation((params) => http.post('/api/v1/system/adms-user', params));
  const updateAdmsUserAction = useMutation((params) => http.put('/api/v1/system/adms-user', params));
  const deleteAdmsUserAction = useMutation((userId) => http.delete(`/api/v1/system/adms-user/${userId}`));

  const onValid = async (data) => {
    const confirmMsg = isRegist ? '등록 하시겠습니까?' : '수정 하시겠습니까?';
    const completeMsg = isRegist ? '등록 되었습니다.' : '수정 되었습니다.';
    Alert.confirm(confirmMsg, async () => {
      if(isRegist) {
        await insertAdmsUserAction.mutateAsync(data);
      }else {
        await updateAdmsUserAction.mutateAsync(data);
      }
      toast.success(completeMsg);
      navigate({
        pathname: '/adms/system/adms-user/view'
      });
    });
  };

  const deleteHandler = async() => {
    Alert.confirm('정말 삭제 하시겠습니까?', async () => {
      await deleteAdmsUserAction.mutateAsync(urlParam.userId);
      toast.success('삭제가 완료 되었습니다.');
      navigate({
        pathname: '/adms/system/adms-user/view'
      });
    });
  }

  return (
    <>
      <div className="contents-fluid col-6">
        <HelmetWrapper meta={{
          title : 'Adms User Detail'
        }} />
        <div className="page-title">
          Adms User Detail
        </div>

        <div className="mt-4">
          <Form onSubmit={handleSubmit(onValid)} onReset={reset} className="w-100">
            <Row>
              <Col xs="12">
                <Form.Group className={isRegist ? 'form-group required' : 'form-group'} controlId="id_userId">
                  <Form.Label>User ID</Form.Label>
                  <Controller
                    name="userId"
                    control={control}
                    rules={{
                      required: isRegist,
                      validate: {
                        email: (value) => Validator.validateEmail(value)
                      }
                    }}
                    render={({ field }) =>
                      <Form.Control
                        {...field}
                        type="text"
                        maxLength="500"
                        autoComplete="new-password"
                        disabled={!isRegist}
                        isInvalid={errors.userId}
                      />
                    }
                  />
                  <ErrorMessageFeedback error={errors.userId} />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs="12">
                <Form.Group className="form-group required" controlId="id_userName">
                  <Form.Label>User Name</Form.Label>
                  <Controller
                    name="userName"
                    control={control}
                    rules={ {required: true} }
                    render={({ field }) =>
                      <Form.Control
                        {...field}
                        type="text"
                        maxLength="100"
                        autoComplete="new-password"
                        isInvalid={errors.userName}
                      />
                    }
                  />
                  <ErrorMessageFeedback error={errors.userName} />
                </Form.Group>
              </Col>
            </Row>
            <Col xs="12">
              <Form.Group className="form-group required" controlId="id_statusCd">
                <Form.Label>Status</Form.Label>
                <Controller
                  name="statusCd"
                  control={control}
                  rules={ {required: true} }
                  render={({ field }) =>
                    <Form.Control
                      as="select"
                      className="form-select"
                      {...field}
                      value={field.value || ''}
                      isInvalid={errors.statusCd}
                    >
                      {
                        Constant.CODE_ADMS_USER_STATUS_CD.map((item, idx) => {
                          return <option key={idx} value={item.code}>{item.name}</option>
                        })
                      }
                    </Form.Control>
                  }
                />
                <ErrorMessageFeedback error={errors.statusCd} />
              </Form.Group>
            </Col>
            <Col xs="12">
              <Form.Group className="form-group required" controlId="id_role">
                <Form.Label>Role</Form.Label>
                <Controller
                  name="role"
                  control={control}
                  rules={ {required: true} }
                  render={({ field }) =>
                    <Form.Control
                      as="select"
                      className="form-select"
                      {...field}
                      value={field.value || ''}
                      isInvalid={errors.role}
                    >
                      {
                        Constant.CODE_ADMS_USER_ROLE.map((item, idx) => {
                          return <option key={idx} value={item.code}>{item.name}</option>
                        })
                      }
                    </Form.Control>
                  }
                />
                <ErrorMessageFeedback error={errors.role} />
              </Form.Group>
            </Col>
            {
              isRegist &&
              <>
                <Row>
                  <Col xs="12">
                    <Form.Group className="form-group required" controlId="id_password">
                      <Form.Label>Password</Form.Label>
                      <Controller
                        name="password"
                        control={control}
                        rules={{
                          required: true,
                          validate: {
                            passwordCheck: (value) => Validator.validatePasswordCheck(value)
                          }
                        }}
                        render={({ field }) =>
                          <Form.Control
                            {...field}
                            type="password"
                            maxLength="100"
                            autoComplete="new-password"
                            isInvalid={errors.password}
                          />
                        }
                      />
                      <ErrorMessageFeedback error={errors.password} />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col xs="12">
                    <Form.Group className="form-group required" controlId="id_passwordConfirm">
                      <Form.Label>Confirm Password</Form.Label>
                      <Controller
                        name="passwordConfirm"
                        control={control}
                        rules={{
                          required: true,
                          validate: {
                            passwordConfirm : (value) => Validator.validatePasswordConfirm(value, password.current)
                          }
                        }}
                        render={({ field }) =>
                          <Form.Control
                            {...field}
                            type="password"
                            maxLength="100"
                            autoComplete="new-password"
                            isInvalid={errors.passwordConfirm}
                          />
                        }
                      />
                      <ErrorMessageFeedback error={errors.passwordConfirm} />
                    </Form.Group>
                  </Col>
                </Row>
              </>
            }
            <Row>
              <Col xs="6" className="btn-wrapper d-flex justify-content-start">
                {
                  !isRegist &&
                  <button className="btn btn-danger" type="button" onClick={deleteHandler}>
                    <span className="material-icons">delete</span>Delete
                  </button>
                }
              </Col>
              <Col xs="6" className="btn-wrapper d-flex justify-content-end">
                {
                  isRegist &&
                  <button className="btn btn-success" type="submit">
                    <span className="material-icons">add</span>Add
                  </button>
                }
                {
                  !isRegist &&
                  <button className="btn btn-success" type="submit">
                    <span className="material-icons">done</span>Update
                  </button>
                }
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    </>
  );
}

export default AdmsUserDetail;