import {useForm} from "react-hook-form";
import {useCallback, useMemo, useEffect} from "react";
import {useQuery} from "react-query";
import {http} from "../../../../utils";
import SortingTable from "../../../../components/material/SortingTable";
import Paging from "../../../../components/common/Paging";

function ObjectUnlock({ student = {}, forceRefresh }) {
  const initValue = useMemo(() => {
    return {
      processId: ''
    }
  }, []);


  const {setValue, getValues} = useForm({
    mode: 'onChange',
    defaultValues : { ...initValue }
  });

  const { data: result, refetch : refetchSearch } = useQuery(
    ['searchStudentUnLock'],
    async () => {
      const param = {
        ...getValues(),
        userId : student?.userId,
        studentId : student?.id
      }

      const result = await http.get(`/api/v1/edit/student/unlock`,{params: param});
      return result.data;
    },
    {
      enabled: false,
      initialData: []
    });

  useEffect(() => {
    if(!student.id) {
      return;
    }

    async function fetchData() {
      await refetchSearch();
    }
    setValue('currentPage', 1);
    fetchData();

  }, [student, forceRefresh, refetchSearch, setValue]);

  const fnSort = useCallback(async (sortBy) => {
    setValue('sortByName', sortBy.id);
    setValue('desc', sortBy.desc);

    await refetchSearch();
  }, [setValue, refetchSearch]);

  const fnPaging = async (pageNum) => {
    setValue('currentPage', pageNum);
    await refetchSearch();
  };

  const defaultSorted = useMemo(() => ({}), []);
  const columns = useMemo(() => [
      {
        Header: "Process Id",
        accessor: "processId",
        textAlign: 'center',
      },
      {
        Header: "Name",
        accessor: "name",
        textAlign: 'center',
      },
      {
        Header: "Name Native",
        accessor: "nameNative",
        textAlign: 'center',
      },
    ],[]
  );

  return (
    <>
      <div className="search-result">
        <div className="table-wrapper">
          <div className="count-block justify-content-between">
            <div>
              <span>Showing {result.startIndex}-{result.endIndex} of {result.totalCnt} results</span>
            </div>
          </div>

          <SortingTable columns={columns}
                        data={result.resultList}
                        defaultSorted={defaultSorted}
                        manualSortBy={true}
                        fnSort={fnSort} />

          <Paging totalPages={result.totalPages}
                  currentPage={result.currentPage}
                  prevPage={result.prevPage}
                  nextPage={result.nextPage}
                  startPage={result.startPage}
                  endPage={result.endPage}
                  displayPageNum={result.displayPageNum}
                  fnPaging={fnPaging} />
        </div>
      </div>
    </>
  );
}

export default ObjectUnlock;