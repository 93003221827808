import {useEffect, useMemo, useState} from "react";
import {Col, Row, Form} from "react-bootstrap";
import HelmetWrapper from "../../../components/common/HelmetWrapper";
import {http, SearchParamUtils, Alert, DateUtils} from '../../../utils';
import {useMutation, useQuery} from "react-query";
import {Controller, useForm} from "react-hook-form";
import ErrorMessageFeedback from "../../../components/common/ErrorMessageFeedback";
import {useLocation} from "react-router-dom";
import DatePicker from "react-datepicker";
import DatePickerInput from "../../../components/material/DatePickerInput";
import {toast} from "react-toastify";
import HistoryLudiPass from "./HistoryLudiPass";
import HistoryLudiPassUpdate from "./HistoryLudiPassUpdate";
import HistoryOrderData from "./HistoryOrderData";

function EditLudiPass() {

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const [forceRefresh] = useState(false)
  const [haveData, setHaveData] = useState(false)

  const initValue = useMemo(() => {
    return {
      userId: ''
    }
  }, []);

  const {handleSubmit, reset, formState: {errors}, control, getValues} = useForm({
    defaultValues : { ...initValue, ...SearchParamUtils.searchParamToJsonObject(searchParams)}
  });

  const { data: student, refetch : refetchSearch } = useQuery(
      ['searchUser'],
      async () => {
        const userId = getValues('userId');
        const result = await http.get(`/api/v1/user/account/${userId}`);

        if(typeof result.data.studentList !== 'undefined') {
          setHaveData(true)
          return result.data.studentList.at(0);
        }
        return {};
      },
      {
        enabled: false,
        initialData: {},
      });

  useEffect(() => {
    refetchSearch();
  }, [refetchSearch]);

  const updateAction = useMutation((params) => http.put('/api/v1/edit/ludipass', params));
  const onValid = async () => {
    await refetchSearch();
  };
  const updateValid = async (data) => {
    const preDate = new Date(student.userLudiPass.endDate)
    if(typeof data.endDate == 'undefined') {
      alert('변경 날짜를 선택해주세요.')
      return
    }
    const curDate = data.endDate

    const confirmMsg = '루디패스 만료 날짜를 변경하시겠습니까?<br>변경날짜:<br>'
        + DateUtils.stringFormat(preDate) + '&#12288;&#8594;&#12288;' + DateUtils.stringFormat(curDate);
    const completeMsg = '변경 되었습니다.';

    Alert.prompt(confirmMsg, async (evt, value) => {
      const param = student.userLudiPass
      param.endDate = DateUtils.stringFormat(curDate)
      param.message = value
      await updateAction.mutateAsync(param);
      toast.success(completeMsg);
      window.location.replace('/adms/edit/ludipass/detail/view?userId=' + student.userId)
    })
  }
  const handleExcelDownload = async () => {
    let params = {
      userIdList: [student.userId],
      studentIdList: [student.id]
    }
    http.postExcelDownload('/api/v1/report/excel/ludipass', params);
  }
  return (
    <>
      <div className="contents-fluid">
        <HelmetWrapper meta={{
          title : 'Edit Ludi Pass'
        }} />
        <div className="page-title">
          Edit Ludi Pass
        </div>

        <div className="search-condition">
          <Form onSubmit={handleSubmit(onValid)} onReset={reset} className="w-100">
            <Row>
              <Col xs="12" md="12">
                <Form.Group className="form-group required" controlId="id_userId">
                  <Form.Label>User ID</Form.Label>
                  <Controller
                      name="userId"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) =>
                          <Form.Control
                              {...field}
                              type="text"
                              placeholder="일치"
                              isInvalid={errors.userId}
                          />
                      }
                  />
                  <ErrorMessageFeedback error={errors.userId} />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs="12" className="btn-wrapper justify-content-end">
                {
                  haveData &&
                  <button className="btn btn-success" onClick={handleExcelDownload}>
                    <span className="material-icons">get_app</span>Download
                  </button>
                }
                &nbsp;&nbsp;
                <button className="btn btn-primary" type="submit">
                  <span className="material-icons">search</span>Search
                </button>
              </Col>
            </Row>
          </Form>
        </div>
        <div className="search-result">
          <div className="sub-title">Membership Info</div>
          <div className="detail mt-4 student-info">
            <Row>
              <Col xs="6">
                <div className="detail-row">
                  <span className="label">User ID</span> <span>{`${student.userId ? student.userId : ''}`}</span>
                </div>
              </Col>
              <Col xs="6">
                <div className="detail-row">
                  <span className="label">Student ID</span><span>{`${student.id ? student.id : ''}`}</span>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs="12" md="6">
                <div className="detail-row">
                  <span className="label">Ludi Pass Type</span>
                  <span>{`${student.userLudiPass ? student.userLudiPass.ludiPassType : ''}`}</span>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs="12" md="6">
                <div className="detail-row">
                  <span className="label">Start Date</span>
                  <span>{`${student.userLudiPass ? student.userLudiPass.startDate : ''}`}</span>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs="12" md="12">
                <div className="detail-row">
                  <span className="label">Expiry Date</span>
                  <span>
                    {`${student.userLudiPass ? student.userLudiPass.endDate : ''}`}
                  </span>
                </div>
                {
                  student.userLudiPass &&
                  <div className="detail-row">
                    <span className="label">Expiry Date 변경</span>
                    <Form onSubmit={handleSubmit(updateValid)} onReset={reset}>
                      <Form.Group className="form-group">
                        <Controller
                            name="endDate"
                            control={control}
                            render={({ field, fieldState }) =>
                                <DatePicker
                                    {...field}
                                    dateFormat="yyyy/MM/dd HH:mm"
                                    rules={{ required: true}}
                                    selected={field.value}
                                    showTimeSelect
                                    timeIntervals={60}
                                    autoComplete="off"
                                    onChange={(date) => {
                                      field.onChange(date);
                                    }}
                                    className={fieldState.invalid ? 'is-invalid' : ''}
                                    customInput={ <DatePickerInput /> }
                                />
                            }
                        />
                        <ErrorMessageFeedback error={errors.startDate} />
                        <button className="btn btn-outline-danger" type="submit">
                          <span className="material-icons">update</span>Update
                        </button>
                      </Form.Group>
                    </Form>
                  </div>
                }
              </Col>
            </Row>
          </div>
        </div>
        <div className="search-result">
          <HistoryLudiPassUpdate student={student} forceRefresh={forceRefresh}/>
        </div>
        <div className="search-result">
          <HistoryLudiPass student={student} forceRefresh={forceRefresh}/>
        </div>
        <div className="search-result">
          <HistoryOrderData student={student} forceRefresh={forceRefresh}/>
        </div>
      </div>
    </>
  );
}
export default EditLudiPass;