import {useMemo} from "react";
import {Controller, useForm} from "react-hook-form";
import {useQuery} from "react-query";
import {Col, Form, Row} from "react-bootstrap";
import HelmetWrapper from "../../../components/common/HelmetWrapper";
import DefaultTable from "../../../components/material/DefaultTable";
import Paging from "../../../components/common/Paging";
import {http, DateUtils} from "../../../utils";
import moment from "moment";
import DatePicker from "react-datepicker";
import DatePickerInput from "../../../components/material/DatePickerInput";
import ErrorMessageFeedback from "../../../components/common/ErrorMessageFeedback";

function ExcelUploadHistory() {
  const initValue = useMemo(() => {
    return {
      startDate: DateUtils.getDate(moment().startOf('month').format('YYYY-MM-DD') + ' 00:00:00'),
      endDate: DateUtils.todayTime('23:59:59'),
      name: '',
      result: '',
    }
  }, []);

  const {handleSubmit, reset, formState: {errors}, control, setValue, getValues} = useForm({
    mode: 'onChange',
    defaultValues : { ...initValue }
  });

  const { data: result, refetch : refetchSearch } = useQuery(
    ['searchExcelUploadHistory'],
    async () => {
      const result = await http.get(`/api/v1/datatable/excelupload/history`,{params: getValues()});
      return result.data;
    },
    {
      enabled: false,
      initialData: []
    });

  const onValid = async () => {
    setValue('currentPage', 1);
    await refetchSearch();
  };

  const fnPaging = async (pageNum) => {
    setValue('currentPage', pageNum);
    await refetchSearch();
  };

  const columns = [
    {
      Header: "Name",
      accessor: "name",
      textAlign: 'center',
    },
    {
      Header: "Excel ID",
      accessor: "excelId",
      textAlign: 'center',
    },
    {
      Header: "Result",
      accessor: "remark",
      textAlign: 'center',
    },
    {
      Header: "Create Date",
      accessor: "createDate",
      textAlign: 'center',
    },
  ];

  return (
    <>
      <div className="contents-fluid">
        <HelmetWrapper meta={{
          title : 'Excel Upload History'
        }} />

        <div className="page-title">
          Excel Upload History
        </div>

        <div className="search-condition">
          <Form onSubmit={handleSubmit(onValid)} onReset={reset} className="w-100">
            <Row>
              <Col xs="6" className=" mb-4">
                <Form.Group className="form-group required mb-0">
                  <Form.Label>Date</Form.Label>
                </Form.Group>
                <div className="condition-date-picker">
                  <Form.Group className="form-group mb-0">
                    <Controller
                      name="startDate"
                      control={control}
                      rules={ {required: true} }
                      render={({ field, fieldState }) =>
                        <DatePicker
                          {...field}
                          dateFormat="yyyy/MM/dd HH:mm"
                          selected={field.value}
                          showTimeSelect
                          timeIntervals={60}
                          autoComplete="off"
                          onChange={(date) => {
                            field.onChange(date);
                          }}
                          className={fieldState.invalid ? 'is-invalid' : ''}
                          customInput={ <DatePickerInput /> }
                        />
                      }
                    />
                    <ErrorMessageFeedback error={errors.startDate} />
                  </Form.Group>

                  <div className='ms-2 me-2'> ~ </div>

                  <Form.Group className="form-group mb-0">
                    <Controller
                      name="endDate"
                      control={control}
                      rules={ {required: true} }
                      render={({ field, fieldState }) =>
                        <DatePicker
                          {...field}
                          dateFormat="yyyy/MM/dd HH:mm"
                          selected={field.value}
                          showTimeSelect
                          timeIntervals={60}
                          autoComplete="off"
                          onChange={(date) => {
                            field.onChange(date);
                          }}
                          className={fieldState.invalid ? 'is-invalid' : ''}
                          customInput={ <DatePickerInput /> }
                        />
                      }
                    />
                    <ErrorMessageFeedback error={errors.endDate} />
                  </Form.Group>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs="12" md="6">
                <Form.Group className="form-group" controlId="id_name">
                  <Form.Label>Name</Form.Label>
                  <Controller
                    name="name"
                    control={control}
                    render={({ field }) =>
                      <Form.Control
                        {...field}
                        type="text"
                        placeholder="포함"
                      />
                    }
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs="12" className="btn-wrapper">
                <button className="btn btn-primary" type="submit">
                  <span className="material-icons">search</span>Search
                </button>
              </Col>
            </Row>
          </Form>
        </div>

        <div className="search-result">
          <div className="sub-title">검색 결과</div>

          <div className="table-wrapper">
            <div className="count-block">
              <span>Showing {result.startIndex}-{result.endIndex} of {result.totalCnt} results</span>
            </div>

            <DefaultTable columns={columns}
                          data={result.resultList}
            />

            <Paging totalPages={result.totalPages}
                    currentPage={result.currentPage}
                    prevPage={result.prevPage}
                    nextPage={result.nextPage}
                    startPage={result.startPage}
                    endPage={result.endPage}
                    displayPageNum={result.displayPageNum}
                    fnPaging={fnPaging} />
          </div>
        </div>
      </div>
    </>
  );
}

export default ExcelUploadHistory;