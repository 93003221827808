import { Table } from "react-bootstrap";
import _ from "lodash";
import {Formatter} from "../../../utils";

function GeneralTab({ data }) {

  return (
    <>
      <Table className="report-table">
        <colgroup>
          <col width="30%" />
          <col width="*" />
        </colgroup>
        <tbody>
          <tr>
            <th>Daily Active Student</th>
            <td>{Formatter.numberWithCommas(data?.count)}</td>
          </tr>
          <tr>
            <th>레벨테스트 진입 인원(미완료)</th>
            <td>{data.sumLevelTestEnterCount}</td>
          </tr>
          <tr>
            <th>레벨테스트 완료 인원</th>
            <td>{data.sumLevelTestEndCount}</td>
          </tr>
          <tr>
            <th>평균 플레이 시간(분)</th>
            <td>{data?.avgStudySpan}</td>
          </tr>
          <tr>
            <th>평균 학습자 레벨</th>
            <td>{data?.avgLudiLevel}</td>
          </tr>
          <tr>
            <th>평균 학습자 레벨 상승</th>
            <td>{data.avgLevelUp}</td>
          </tr>
          <tr>
            <th>평균 미션 진행</th>
            <td>{data?.avgMissionCount}</td>
          </tr>
          <tr>
            <th>평균 포탈 진행</th>
            <td>{data?.avgPortalCount}</td>
          </tr>
          <tr>
            <th>최장 플레이 시간(분)</th>
            <td>{data?.maxStudySpan}</td>
          </tr>
          <tr>
            <th>최소 플레이 시간(분)</th>
            <td>{data?.minStudySpan}</td>
          </tr>
          <tr>
            <th>미션으로 획득한 루디 에너지</th>
            <td>{Formatter.numberWithCommas(data?.sumLudiEnergiesMission)}</td>
          </tr>
          <tr>
            <th>컨텐츠로 획득한 루디 에너지</th>
            <td>{Formatter.numberWithCommas(data?.sumLudiEnergiesContent)}</td>
          </tr>
          <tr>
            <th>재방문 유저</th>
            <td>{!_.isUndefined(data.revisitRate) ? `${data.revisitRate}%` : ''}</td>
          </tr>
          <tr>
            <th>Daily Report Count</th>
            <td>{data.dailyReportCount}</td>
          </tr>
        </tbody>
      </Table>
    </>
  );
}

export default GeneralTab;