import HelmetWrapper from "../../components/common/HelmetWrapper";
import {Col, Row} from "react-bootstrap";
import {useEffect, useState} from "react";
import {http, Alert} from "../../utils"
import {useMutation, useQuery} from "react-query";
import {toast} from "react-toastify";

function Notice() {
    const [active, setActive] = useState(false)
    const searchNotice = async () => {
        const resp = await http.get('/api/v1/notice')
        setActive(resp.data.active)
        return resp;
    }
    const {refetch: refetchSearch} = useQuery(['searchNotice'], () => searchNotice(), {
        enabled: false
    })
    const updateNoticeMutation = useMutation((params) => http.put('/api/v1/notice', params));
    const onUpdate = async () => {
        const confirmMsg = '수정하시겠습니까?';
        const completeMsg = '수정되었습니다.';

        Alert.confirm(confirmMsg, async () => {
            let sendData = {'active': !active}
            await updateNoticeMutation.mutateAsync(sendData)
            toast.success(completeMsg)
            refetchSearch()
        })
    }
    //onload 시 한번 실행
    useEffect(() => {
        refetchSearch()
    },[refetchSearch])
    return (
        <>
            <div className="contents-fluid">
                <HelmetWrapper meta={{
                    title : 'Popup Management'
                }} />
                <div className="page-title">
                    Popup Management
                </div>
                <div className="search-condition">
                    <Row>
                        <Col xs="12" className="btn-wrapper justify-content-start">
                            <h2>현재 상태: {active ? '활성': '비활성'}</h2>
                            &nbsp;&nbsp;&nbsp;
                            <button className="btn btn-primary" type="button" onClick={onUpdate}>
                                <span className="material-icons"></span>Change
                            </button>
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    )
}
export default Notice;
