import { Table } from "react-bootstrap";
import _ from "lodash";

function MissionFcTimeTab({ data }) {

  const formatTime = (totalSec) => {
    const hour = _.isUndefined(totalSec) ? '00' : String(Math.floor(totalSec / 3600)).padStart(2, '0');
    const min = _.isUndefined(totalSec) ? '00' : String(Math.floor((totalSec % 3600) / 60)).padStart(2, '0');
    const time = _.isUndefined(totalSec) ? '00' : String((totalSec % 3600) % 60).padStart(2, '0');
    return `${hour}:${min}:${time}`;
  }

  return (
    <>
      <div className="sub-title mb-4">미션 전체</div>
      <Table className="report-table">
        <colgroup>
          <col width="30%" />
          <col width="*" />
        </colgroup>
        <tbody>
          <tr>
            <th>미션 총 걸린 시간 (em+tm+sm)</th>
            <td>{formatTime(data.missionTotalSec)}</td>
          </tr>
          <tr>
            <th>FC(수리하기+헌팅) 총 걸린 시간</th>
            <td>{formatTime(data.repairHuntingSec)}</td>
          </tr>
          <tr>
            <th>미션+FC(수리하기+헌팅)</th>
            <td>{formatTime(data.totalMissionWithRepairHuntingSec)}</td>
          </tr>
          <tr>
            <th>미션 EM 포함</th>
            <td>{_.isUndefined(data.missionTotalPer) ? '' : `${data.missionTotalPer}%`}</td>
          </tr>
          <tr>
            <th>FC 수리&헌팅</th>
            <td>{_.isUndefined(data.repairHuntingPer) ? '' : `${data.repairHuntingPer}%`}</td>
          </tr>
        </tbody>
      </Table>

      <div className="sub-title mt-5">EM 제외 미션</div>
      <Table className="report-table">
        <colgroup>
          <col width="30%" />
          <col width="*" />
        </colgroup>
        <tbody>
        <tr>
          <th>미션 총 걸린 시간(em 제외)</th>
          <td>{formatTime(data.missionTotalExceptEmSec)}</td>
        </tr>
        <tr>
          <th>FC(수리하기+헌팅) 총 걸린 시간</th>
          <td>{formatTime(data.repairHuntingSec)}</td>
        </tr>
        <tr>
          <th>미션(em제외)+FC(수리하기+헌팅)</th>
          <td>{formatTime(data.totalMissionExceptEmWithRepairHuntingSec)}</td>
        </tr>
        <tr>
          <th>미션 EM 제외</th>
          <td>{_.isUndefined(data.missionTotalExceptEmPer) ? '' : `${data.missionTotalExceptEmPer}%`}</td>
        </tr>
        <tr>
          <th>FC 수리&헌팅</th>
          <td>{_.isUndefined(data.repairHuntingExceptEmPer) ? '' : `${data.repairHuntingExceptEmPer}%`}</td>
        </tr>
        </tbody>
      </Table>
    </>
  );
}

export default MissionFcTimeTab;