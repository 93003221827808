import _ from 'lodash';
import { useState, useMemo } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {useMutation, useQuery} from "react-query";
import {Controller, useForm} from "react-hook-form";
import { http, toast, Validator, Alert, DateUtils} from "../../../utils";
import HelmetWrapper from "../../../components/common/HelmetWrapper";
import {Col, Form, Row} from "react-bootstrap";
import ErrorMessageFeedback from "../../../components/common/ErrorMessageFeedback";
import DatePicker from "react-datepicker";
import DatePickerInput from "../../../components/material/DatePickerInput";

function MessageRepeatDetail() {
  const initValue = useMemo(() => {
    return {
      startDate: '',
      endDate: '',
      period: '',
      message: ''
    }
  }, []);

  const navigate = useNavigate();
  const urlParam = useParams();
  const [isRegist] = useState(() => _.isNil(urlParam.id));

  const {handleSubmit, reset, formState: {errors}, control} = useForm({
    mode: 'onChange',
    defaultValues : { ...initValue }
  });

  const { data: result } = useQuery(
    ['searchMessageRepeatDetail'],
    async () => {
      if(isRegist) {
        return {...initValue };
      }
      return  (await http.get(`/api/v1/message/repeat/${urlParam.id}`)).data;
    },
    {
      initialData: {},
      onSuccess: (data) => {
        const resetData = {
          ...data,
          startDate: DateUtils.getDate(data.startDate),
          endDate: DateUtils.getDate(data.endDate)
        }
        reset({ ...resetData });
      }
    });

  const insertAction = useMutation((params) => http.post('/api/v1/message/repeat', params));
  const cancelAction = useMutation((params) => http.put('/api/v1/message/repeat/cancel', params));

  const onValid = async (data) => {
    const confirmMsg = '등록 하시겠습니까?';
    const completeMsg = '등록 되었습니다.';
    Alert.confirm(confirmMsg, async () => {
      await insertAction.mutateAsync(data);

      toast.success(completeMsg);
      navigate({
        pathname: '/adms/message/repeat/view'
      });
    });
  };

  const handleCancel = async () => {
    const confirmMsg = '취소 하시겠습니까?';
    const completeMsg = '취소 되었습니다.';

    Alert.confirm(confirmMsg, async () => {
      await cancelAction.mutateAsync({id : urlParam.id});

      toast.success(completeMsg);
      navigate({
        pathname: '/adms/message/repeat/view'
      });
    });
  };

  return (
    <>
      <div className="contents-fluid">
        <HelmetWrapper meta={{
          title : 'Repeat'
        }} />
        <div className="page-title">
          Repeat
        </div>

        <div className="mt-4">
          <Form onSubmit={handleSubmit(onValid)} onReset={reset} className="w-100">
            <Row>
              <Col xs="12" className="mb-4">
                <Form.Group className="form-group required mb-0">
                  <Form.Label>Date</Form.Label>
                </Form.Group>
                <div className="condition-date-picker">
                  <Form.Group className="form-group mb-0">
                    <Controller
                      name="startDate"
                      control={control}
                      rules={ {required: true} }
                      render={({ field, fieldState }) =>
                        <DatePicker
                          {...field}
                          dateFormat="yyyy/MM/dd HH:mm"
                          selected={field.value}
                          showTimeSelect
                          timeIntervals={60}
                          autoComplete="off"
                          onChange={(date) => {
                            field.onChange(date);
                          }}
                          className={fieldState.invalid ? 'is-invalid' : ''}
                          customInput={ <DatePickerInput /> }
                          readOnly={!isRegist}
                        />
                      }
                    />
                    <ErrorMessageFeedback error={errors.startDate} />
                  </Form.Group>

                  <div className='ms-2 me-2'> ~ </div>

                  <Form.Group className="form-group mb-0">
                    <Controller
                      name="endDate"
                      control={control}
                      rules={ {required: true} }
                      render={({ field, fieldState }) =>
                        <DatePicker
                          {...field}
                          dateFormat="yyyy/MM/dd HH:mm"
                          selected={field.value}
                          showTimeSelect
                          timeIntervals={60}
                          autoComplete="off"
                          onChange={(date) => {
                            field.onChange(date);
                          }}
                          className={fieldState.invalid ? 'is-invalid' : ''}
                          customInput={ <DatePickerInput /> }
                          readOnly={!isRegist}
                        />
                      }
                    />
                    <ErrorMessageFeedback error={errors.endDate} />
                  </Form.Group>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs="6">
                <Form.Group className="form-group required" controlId="id_period">
                  <Form.Label>Period</Form.Label>
                  <Controller
                    name="period"
                    control={control}
                    rules={{
                      required: true,
                      validate: {
                        number: (value) => Validator.validateNumber(value)
                      }
                    }}
                    render={({ field }) =>
                      <Form.Control
                        {...field}
                        type="text"
                        maxLength="100"
                        placeholder="분 단위"
                        isInvalid={errors.period}
                        readOnly={!isRegist}
                      />
                    }
                  />
                  <ErrorMessageFeedback error={errors.period} />
                </Form.Group>
              </Col>
            </Row>
            {
              !isRegist &&
              <>
                <Row>
                  <Col xs="12">
                    <div className="detail-row mb-3">
                      <span className="label">isExpired</span> <span className="ms-3">{`${result.expired ? 'Expired' : 'Not Expired'}`}</span>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xs="12">
                    <div className="detail-row mb-3">
                      <span className="label">Status</span> <span className="ms-3">{result.statusCd}</span>
                    </div>
                  </Col>
                </Row>
              </>
            }
            <Row>
              <Col xs="12">
                <Form.Group className="form-group required" controlId="id_message">
                  <Form.Label>Message</Form.Label>
                  <Controller
                    name="message"
                    control={control}
                    rules={ {required: true} }
                    render={({ field }) =>
                      <Form.Control
                        {...field}
                        as="textarea"
                        rows="10"
                        placeholder="최대 25자 입력"
                        maxLength={25}
                        isInvalid={errors.message}
                        readOnly={!isRegist}
                      />
                    }
                  />
                  <ErrorMessageFeedback error={errors.message} />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs="12" className="btn-wrapper d-flex justify-content-end">
                {
                  isRegist &&
                  <button className="btn btn-success" type="submit">
                    <span className="material-icons">add</span>Add
                  </button>
                }
                {
                  (!result.expired && result.statusCd === 'Active')&&
                  <button className="btn btn-warning" type="button" onClick={handleCancel}>
                    <span className="material-icons">clear</span>Cancel
                  </button>
                }
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    </>
  );
}

export default MessageRepeatDetail;