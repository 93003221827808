import React from "react";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-fluid">
        Copyright © Archipin, Inc. All rights reserved.
      </div>
    </footer>
  )
};
export default Footer;
