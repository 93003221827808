import React from 'react';

const Page404 = () => {
    return (
        <>
            <div>404 not found</div>
        </>
    );
};

export default Page404;