import React, {useMemo, useState} from "react";
import {Controller, useForm} from "react-hook-form";
import HelmetWrapper from "../../../components/common/HelmetWrapper";
import {Col, Form, Row} from "react-bootstrap";
import DatePicker from "react-datepicker";
import DatePickerInput from "../../../components/material/DatePickerInput";
import ErrorMessageFeedback from "../../../components/common/ErrorMessageFeedback";
import Paging from "../../../components/common/Paging";
import {useQuery} from "react-query";
import {Constant, http} from '../../../utils';
import DefaultTable from "../../../components/material/DefaultTable";
import AudioPlayModal from "../../../components/material/AudioPlayModal";

function VHDmLog() {
  const initValue = useMemo(() => {
    return {
      startDate: '',
      endDate: '',
      nickName: '',
      result : '',
      userId: ''
    }
  }, []);

  const [audioPlayModalItem, setAudioPlayModalItem] = useState({
    isShow: false,
    url : ''
  });

  const {handleSubmit, reset, formState: {errors}, control, getValues, setValue } = useForm({
    mode: 'onChange',
    defaultValues : { ...initValue }
  });

  const { data: result, refetch : refetchSearch } = useQuery(
    ['searchVHDmlog'],
    async () => {
      return (await http.get(`/api/v1/voice-hunber/dm-log`, {params: getValues()})).data;
    },
    {
      enabled: false,
      initialData: []
    });

  const onValid = async () => {
    setValue('currentPage', 1);
    await refetchSearch();
  };

  const fnPaging = async (pageNum) => {
    setValue('currentPage', pageNum);
    await refetchSearch();
  };

  const handleaudioPlayModalShow = (e, id) => {
    e.preventDefault();

    const url = `/api/v1/voice-hunber/dm-log/${id}/audio`;
    setAudioPlayModalItem({
      isShow: true,
      url : url
    });
  }

  const handleAudioPlayModalHide = () => {
    setAudioPlayModalItem({
      isShow: false,
      url : ''
    });
  }

  const columns = [
    {
      Header: "userId",
      accessor: "userId",
      textAlign: 'center',
    },
    {
      Header: "NickName",
      accessor: "nickName",
      textAlign: 'center',
    },
    {
      Header: "Level",
      accessor: "level",
      textAlign: 'center',
      Cell: ({ row }) => (String(row.original.level)),
    },
    {
      Header: "Question",
      accessor: "question",
      textAlign: 'center',
    },
    {
      Header: "User HL",
      accessor: "userHL",
      textAlign: 'center',
    },
    {
      Header: "Build Code",
      accessor: "buildCode",
      textAlign: 'center',
    },
    {
      Header: "Result",
      accessor: "result",
      textAlign: 'center',
    },
    {
      Header: "Create Date",
      accessor: "createDate",
      textAlign: 'center',
    },
    {
      Header: "",
      accessor: "id",
      textAlign: 'center',
      Cell: ({ row }) => (
        <>
          {
            row.original.audioFileExists &&
            <a href="#!" title="play audio" onClick={(e) => handleaudioPlayModalShow(e, row.original.id)}>
              <span className="material-icons">play_circle_outline</span>
            </a>
          }
        </>
      ),
    },
  ];

  return (
    <>
      <div className="contents-fluid">
        <HelmetWrapper meta={{
          title : 'Voice Hunter DmLog'
        }} />

        <div className="page-title">
          Voice Hunter DmLog
        </div>

        <div className="search-condition">
          <Form onSubmit={handleSubmit(onValid)} onReset={reset} className="w-100">
            <Row>
              <Col xs="6" className=" mb-4">
                <Form.Group className="form-group mb-0">
                  <Form.Label>Date</Form.Label>
                </Form.Group>
                <div className="condition-date-picker">
                  <Form.Group className="form-group mb-0">
                    <Controller
                      name="startDate"
                      control={control}
                      rules={ {required: false} }
                      render={({ field, fieldState }) =>
                        <DatePicker
                          {...field}
                          dateFormat="yyyy/MM/dd HH:mm"
                          selected={field.value}
                          showTimeSelect
                          timeIntervals={60}
                          autoComplete="off"
                          onChange={(date) => {
                            field.onChange(date);
                          }}
                          className={fieldState.invalid ? 'is-invalid' : ''}
                          customInput={ <DatePickerInput /> }
                        />
                      }
                    />
                    <ErrorMessageFeedback error={errors.startDate} />
                  </Form.Group>

                  <div className='ms-2 me-2'> ~ </div>

                  <Form.Group className="form-group mb-0">
                    <Form.Label></Form.Label>
                    <Controller
                      name="endDate"
                      control={control}
                      rules={ {required: false} }
                      render={({ field, fieldState }) =>
                        <DatePicker
                          {...field}
                          dateFormat="yyyy/MM/dd HH:mm"
                          selected={field.value}
                          showTimeSelect
                          timeIntervals={60}
                          autoComplete="off"
                          onChange={(date) => {
                            field.onChange(date);
                          }}
                          className={fieldState.invalid ? 'is-invalid' : ''}
                          customInput={ <DatePickerInput /> }
                        />
                      }
                    />
                    <ErrorMessageFeedback error={errors.endDate} />
                  </Form.Group>
                </div>
              </Col>
              <Col xs="12" md="6">
                <Form.Group className="form-group" controlId="id_nickName">
                  <Form.Label>Nick Name</Form.Label>
                  <Controller
                    name="nickName"
                    control={control}
                    render={({ field }) =>
                      <Form.Control
                        {...field}
                        type="text"
                        placeholder="일치"
                      />
                    }
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs="12" md="6">
                <Form.Group className="form-group" controlId="id_result">
                  <Form.Label>Result</Form.Label>
                  <Controller
                    name="result"
                    control={control}
                    render={({ field }) =>
                      <Form.Control
                        as="select"
                        className="form-select"
                        {...field}
                        value={field.value || ''}
                      >
                        <option value=""></option>
                        {
                          Constant.CODE_VOICE_HUNTER_DMLOG_RESULT.map((item, idx) => {
                            return <option key={idx} value={item.code}>{item.name}</option>
                          })
                        }
                      </Form.Control>
                    }
                  />
                </Form.Group>
              </Col>

              <Col xs="12" md="6">
                <Form.Group className="form-group" controlId="id_userId">
                  <Form.Label>User ID</Form.Label>
                  <Controller
                    name="userId"
                    control={control}
                    render={({ field }) =>
                      <Form.Control
                        {...field}
                        type="text"
                        placeholder="일치"
                      />
                    }
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs="12" className="btn-wrapper">
                <button className="btn btn-primary" type="submit">
                  <span className="material-icons">search</span>Search
                </button>
              </Col>
            </Row>
          </Form>
        </div>

        <div className="search-result">
          <div className="sub-title">검색 결과</div>
          <div className="table-wrapper">
            <div className="count-block justify-content-between">
              <div>
              </div>
              <div>
                <span>Showing {result.startIndex}-{result.endIndex} of {result.totalCnt} results</span>
              </div>
            </div>

            <DefaultTable columns={columns}
                          data={result.resultList}
            />

            <Paging totalPages={result.totalPages}
                    currentPage={result.currentPage}
                    prevPage={result.prevPage}
                    nextPage={result.nextPage}
                    startPage={result.startPage}
                    endPage={result.endPage}
                    displayPageNum={result.displayPageNum}
                    fnPaging={fnPaging} />

          </div>
        </div>
      </div>

      <AudioPlayModal
        onHide={handleAudioPlayModalHide}
        modalItem={audioPlayModalItem}
        autoPlay={true}
      />
    </>
  );
}

export default VHDmLog;