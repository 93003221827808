import {Button, Col, Form, Modal, Row, InputGroup, Table} from "react-bootstrap";
import {Controller, useForm} from "react-hook-form";
import ErrorMessageFeedback from "../../../../components/common/ErrorMessageFeedback";
import {useMemo, useState} from "react";
import {Alert, http, toast, Validator} from "../../../../utils";
import ItemSearchModal from "./ItemSearchModal";
import {useMutation} from "react-query";

function AddItemModal({onHide, modalItem}) {
  const {
    isShow,
    item,
  } = modalItem;

  const initValue = useMemo(() => {
    return {
      itemId: '',
      count: ''
    }
  }, []);

  const [itemSearchModalItem, setItemSearchModalItem] = useState({
    isShow: false
  });

  const {handleSubmit, reset, formState: {errors}, control, setValue} = useForm({
    mode: 'onChange',
    defaultValues : { ...initValue }
  });
  const insertItemAction = useMutation((params) => http.post('/api/v1/edit/student/inventory/item', params));
  const updateItemAction = useMutation((params) => http.put('/api/v1/edit/student/inventory/item', params));

  const handleClose = (isRefresh = false) => {
    onHide(isRefresh);
  };

  const init = async () => {
    reset({...item.selectedItem});
  };

  const onValid = async (data) => {
    const confirmMsg = item.isRegist ? '등록 하시겠습니까?' : '수정 하시겠습니까?';
    const completeMsg = item.isRegist ? '등록 되었습니다.' : '수정 되었습니다.';
    Alert.confirm(confirmMsg, async () => {
      const param = {...data, userId : item.userId, studentId: item.studentId};
      item.isRegist ? await insertItemAction.mutateAsync(param) : await updateItemAction.mutateAsync(param);

      toast.success(completeMsg);
      handleClose(true);
    });
  };

  const handleItemSearchModalShow = () => {
    setItemSearchModalItem({
      isShow: true
    });
  }

  const handleItemSearchModalHide = () => {
    setItemSearchModalItem({
      isShow: false
    });
  }

  const handleItemSearchSelect = (data) => {
    setValue('itemId', data.itemId);
    item.selectedItem = data;
  }

  return (
    <>
      <Modal
        size="lg"
        show={isShow}
        onHide={handleClose}
        className="detail"
        onEntered={() => {
          init();
        }}
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">{`${item.isRegist ? 'Inventory (Add Item)' : 'Inventory (Edit Item)'}`}</Modal.Title>
          <Button type="button" className="close" aria-label="Close" onClick={handleClose}>
            <span aria-hidden="true">&times;</span>
          </Button>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xs="12" md="6">
              <div className="detail-row ms-0">
                <span className="label">Student ID</span> <span>{item.studentId}</span>
              </div>
            </Col>
            <Col xs="12" md="6">
              <div className="detail-row">
                <span className="label">Student Name</span> <span>{item.name}</span>
              </div>
            </Col>
          </Row>
          <Form onSubmit={handleSubmit(onValid)} onReset={reset} className="w-100 mt-4">
            <Row>
              <Col xs="12">
                  <Form.Group className="form-group required" controlId="id_itemId">
                    <Form.Label>Item ID</Form.Label>
                    <InputGroup>
                      <Controller
                        name="itemId"
                        control={control}
                        rules={{
                          required: true,
                          validate: {
                            number: (value) => Validator.validateNumber(value)
                          }
                        }}
                        render={({ field }) =>
                          <Form.Control
                            {...field}
                            type="text"
                            readOnly={true}
                            isInvalid={errors.itemId}
                          />
                        }
                      />
                      <button className="btn btn-outline-dark ms-2" type="button" disabled={!item.isRegist} onClick={handleItemSearchModalShow}>
                        <span className="material-icons">search</span>Item Search
                      </button>
                    </InputGroup>
                    <ErrorMessageFeedback error={errors.itemId} />
                  </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs="12">
                <Form.Group className="form-group required" controlId="id_count">
                  <Form.Label>수량</Form.Label>
                  <Controller
                    name="count"
                    control={control}
                    rules={{
                      required: true,
                      validate: {
                        number: (value) => Validator.validateNumber(value),
                        greaterThan : (value) => Validator.validateGreaterThan(value, 0)
                      }
                    }}
                    render={({ field }) =>
                      <Form.Control
                        {...field}
                        type="text"
                        isInvalid={errors.count}
                      />
                    }
                  />
                  <ErrorMessageFeedback error={errors.count} placeholder={ {greaterThan: 0} }/>
                </Form.Group>
              </Col>
            </Row>
            {
              item.selectedItem?.itemId &&
              <Row>
                <div className="sub-title">Selected Item</div>
                <Table responsive bordered className='text-center col-12'>
                  <thead className="table-dark">
                  <tr>
                    <th scope="col">Category</th>
                    <th scope="col">Item Id</th>
                    <th scope="col">Name</th>
                    <th scope="col">Name Native</th>
                    <th scope="col">Item Count Limit</th>
                  </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{item.selectedItem.itemCategory}</td>
                      <td>{item.selectedItem.itemId}</td>
                      <td>{item.selectedItem.name}</td>
                      <td>{item.selectedItem.nameNative}</td>
                      <td>{item.selectedItem.itemCountLimit === 0 ? '' : item.selectedItem.itemCountLimit}</td>
                    </tr>
                  </tbody>
                </Table>
              </Row>
            }
            <Row>
              <Col xs="12" className="btn-wrapper mt-4 text-end">
                {
                  item.isRegist &&
                  <button className="btn btn-info" type="submit">
                    <span className="material-icons">add</span>Add
                  </button>
                }
                {
                  !item.isRegist &&
                  <button className="btn btn-success" type="submit">
                    <span className="material-icons">done</span>Update
                  </button>
                }
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>

      <ItemSearchModal
        onSelect={handleItemSearchSelect}
        onHide={handleItemSearchModalHide}
        modalItem={itemSearchModalItem}
      />
    </>
  );
}

export default AddItemModal;