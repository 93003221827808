import {Fragment, useCallback, useMemo, useRef, useState} from "react";
import readXlsxFile from "read-excel-file";
import {useMutation} from "react-query";
import {toast} from "react-toastify";
import HelmetWrapper from "../../../components/common/HelmetWrapper";
import {Col, Row} from "react-bootstrap";
import {http, Alert} from '../../../utils';
import DefaultTable from "../../../components/material/DefaultTable";

function CreateAccount() {
    const [resultList, setResultList] = useState([])
    const [filename, setFilename] = useState("")

    const fileInput = useRef(null);

    const handleExcelUpload = e => {
        fileInput.current.click();
    }

    const handleExcelUploadChange = async (e) => {
        e.preventDefault()

        const map = {
            days: "dayCount",
            organization: "organization",
            godomallID: "godomallId",
            godomallID_string: "godomallIdString",
            gender:"gender",
            groupID:"groupId",
            loginID:"loginId",
            pw:"pw",
            studentName:"studentName",
            startDate:"startDate",
            endDate:"endDate"
        }
        readXlsxFile(e.target.files[0], {map}).then((result => {
            setFilename(e.target.files[0].name)
            setResultList(result.rows)
        }))
    }

    const defaultSorted = useMemo(() => ({ id: 'userId', desc: true }), []);

    const fnSort = useCallback(async (sortBy) => {
    },[])

    const columns = useMemo(() => [
        {
            Header: "Days",
            accessor: "dayCount",
            textAlign: 'center',
        },
        {
            Header: "Organization",
            accessor: "organization",
            textAlign: 'center',
        },
        {
            Header: "Godomall ID",
            accessor: "godomallId",
            textAlign: 'center',
        },
        {
            Header: "Godomall ID(String)",
            accessor: "godomallIdString",
            textAlign: 'center',
        },
        {
            Header: "Gender",
            accessor: "gender",
            textAlign: 'center',
        },
        {
            Header: "Group ID",
            accessor: "groupId",
            textAlign: 'center',
        },
        {
            Header: "Login ID",
            accessor: "loginId",
            textAlign: 'center',
        },
        {
            Header: "PW",
            accessor: "pw",
            textAlign: 'center',
        },
        {
            Header: "Student Name",
            accessor: "studentName",
            textAlign: 'center',
        },
        {
            Header: "Start Date",
            accessor: "startDate",
            textAlign: 'center',
        },
        {
            Header: "End Date",
            accessor: "endDate",
            textAlign: 'center',
        }
    ],[])

    const insertAction = useMutation((param) => http.post("/api/v1/b2b", param));
    const handleUpdate = async () => {
        const confirmMsg = '계정을 생성하시겠습니까?'
        const completeMsg = '생성되었습니다.';

        Alert.confirm(confirmMsg, async () => {
            await insertAction.mutateAsync(resultList)
            toast.success(completeMsg)
        })
    }
    return (
        <>
            <div className="contents-fluid">
                <HelmetWrapper meta={{
                    title : 'B2b Create Account'
                }} />
                <div className="page-title">
                    B2b Create Account
                </div>
                <div className="search-condition">
                    <Row>
                        <Col xs="12" className="btn-wrapper justify-content-end">
                            <span>업로드 파일명: {filename}</span>
                            &nbsp;&nbsp;&nbsp;
                            <Fragment>
                                <button className="btn btn-success" onClick={handleExcelUpload}>
                                    <span className="material-icons">file_upload</span>Excel Upload
                                </button>
                                <input type="file"
                                       name="file"
                                       ref={fileInput}
                                       onChange={handleExcelUploadChange}
                                       style={{ display: "none" }} />
                            </Fragment>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="12" className="btn-wrapper justify-content-end">
                            <button className="btn btn-danger" onClick={handleUpdate}>
                                <span className="material-icons">done</span>Create Account
                            </button>
                        </Col>
                    </Row>
                </div>
                <div className="page-title">
                    업로드 내역({resultList.length ? resultList.length : 0}건)
                </div>
                <div className="search-result">
                    <DefaultTable columns={columns}
                                  data={resultList}
                                  defaultSorted={defaultSorted}
                                  fnSort={fnSort}/>
                </div>
            </div>
        </>
    )
}
export default CreateAccount;