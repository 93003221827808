import { useNavigate } from 'react-router-dom';
import { useMutation } from 'react-query';
import { connect } from "react-redux";
import { logout } from "../../store/LoginInfoSlice";
import { http, Constant } from "../../utils";

const Header = ({ loginInfo, logout }) => {
  const navigate = useNavigate();

  const logoutMutation = useMutation(
    () => http.post('/api/v1/logout')
  );

  const handleLogout = async (e) => {
    e.preventDefault();
    await logoutMutation.mutateAsync();
    logout();
    navigate(Constant.LOGIN_URL);
  };

  return (
    <header className="header">
      <div className="header-fluid">
        <div className="system-title">
          ADMS {loginInfo.serverType ? `(${loginInfo.serverType})` : ''}
        </div>
        <div className="user-info">
          <span className="material-icons-outlined"></span>
          <i className="material-icons me-2">person_outline</i>
          <span className="ms-1 me-2">{loginInfo.userName}</span>
          <span className="ms-1 me-2">레벨{loginInfo.role}</span>

          <a href="#!" title="로그아웃" className="logout" onClick={handleLogout}>
            <span className="material-icons">logout</span>
          </a>
        </div>
      </div>
    </header>
  );
};

function mapStateToProps(state) {
  return {
    loginInfo: state.loginInfo.data
  };
}
const mapDispatchToProps = { logout };

export default connect(mapStateToProps, mapDispatchToProps)(Header);