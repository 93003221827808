import {useMemo} from "react";
import SortingTable from '../../../components/material/SortingTable';

function InteractionList({ resultList }) {
  const defaultSorted = useMemo(() => ({ id: 'contentCategory', desc: false }), []);
  const columns = useMemo(() => [
      {
        Header: 'Content Category',
        accessor: 'contentCategory',
        textAlign: 'center',
      },
      {
        Header: 'Content',
        accessor: 'content',
        textAlign: 'center',
      },
      {
        Header: 'Count',
        accessor: 'count',
        textAlign: 'center',
        Cell: ({ row }) => (row.original.count === 0 ? (<div className="no-data">0</div>) : row.original.count),
      },
      {
        Header: 'Success',
        accessor: 'correctCount',
        textAlign: 'center',
        Cell: ({ row }) => (row.original.correctCount === 0 ? (<div className="no-data">0</div>) : row.original.correctCount),
      },
      {
        Header: 'Fail',
        accessor: 'wrongCount',
        textAlign: 'center',
        Cell: ({ row }) => (row.original.wrongCount === 0 ? (<div className="no-data">0</div>) : row.original.wrongCount),
      },
      {
        Header: 'Give Up',
        accessor: 'giveUpCount',
        textAlign: 'center',
        Cell: ({ row }) => (row.original.giveUpCount === 0 ? (<div className="no-data">0</div>) : row.original.giveUpCount),
      },
    ],[]
  );

  return (
    <>
      <SortingTable columns={columns}
                    data={resultList}
                    defaultSorted={defaultSorted}
                    manualSortBy={false} />
    </>
  );
}

export default InteractionList;