import {Col, Row, Button} from "react-bootstrap";
import {toast} from "../../../utils";
import {useState} from "react";
import EditLudiEnengyModal from "./EditLudiEnengyModal";

function StudentBriefInfo({ student = {}, fnAddPortalSentence, fnSearch }) {
  const [modalItem, setModalItem] = useState({
    isShow: false,
    item: {}
  });

  const handleAddPortalSentence = (e) => {
    e.preventDefault();
    if(!student?.id) {
      return;
    }

    if(student.portalData.cube.portalSentence) {
      toast.error('이미 portal sentense가 추가 되어있습니다.');
      return;
    }
    fnAddPortalSentence();
  }

  const handleEditLudiEnergyModalShow = (e) => {
    e.preventDefault();
    setModalItem({
      isShow: true,
      item: {
        userId: student.userId,
        studentId: student.id,
        name: student.name,
        ludiEnergy : student?.portalData?.cube?.ludiEnergy || 0
      }
    });
  }

  const handleEditLudiEnergyModalHide = (isRefresh) => {
    setModalItem({
      isShow: false,
      item : {}
    });
    if(isRefresh) {
      fnSearch();
    }
  }

  return (
    <>
      <div className="detail mt-4 student-info">
        <Row>
          <Col xs="12" md="12">
            <div className="detail-row">
              <span className="label">Student ID</span> <span>{student.id}</span>
              <span className={`ms-2 ${student.login ? 'text-success fw-bold' : ''}`}>{`( ${student.login ? 'Login' : 'Log Off'} )`}</span>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs="12" md="6">
            <div className="detail-row">
              <span className="label">Student Name</span> <span>{student.name}</span>
            </div>
          </Col>
          <Col xs="12" md="6">
            <div className="detail-row">
              <span className="label">Birthday</span> <span>{student.birthday}</span>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs="12" md="6">
            <div className="detail-row">
              <span className="label">Gender</span> <span>{student.gender}</span>
            </div>
          </Col>
          <Col xs="12" md="6">
            <div className="detail-row">
              <span className="label">Delete Date</span> <span>{student.deleteDate}</span>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs="6">
            <div className="detail-row">
              <span className="label">Portal Cycle</span> <span className="level">{student.portalCycle}</span>
            </div>
          </Col>
          <Col xs="6">
            <div className="detail-row">
              <span className="label">LudiEnergy</span>{`${student?.portalData?.cube?.ludiEnergy || 0} / ${student.maxLudiEnergy || 0}`}
              <a href="#!" className="ms-2" onClick={handleEditLudiEnergyModalShow} title="show edit ludiEnergy modal"><span className="material-icons">arrow_circle_up</span></a>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs="6">
            <div className="detail-row">
              <span className="label">PortalSentence</span>{student?.portalData?.cube?.portalSentence ? 'On' : 'Off'}
              {
                <Button type="button"
                        variant="outline-info"
                        className="ms-3"
                        onClick={handleAddPortalSentence}><span className="material-icons">add</span>Add PortalSentence</Button>
              }
            </div>
          </Col>
        </Row>
      </div>

      <EditLudiEnengyModal
        onHide={handleEditLudiEnergyModalHide}
        modalItem={modalItem}
      />
    </>
  );
}

export default StudentBriefInfo;