import { createSlice } from "@reduxjs/toolkit";

export const loginInfoSlice = createSlice({
  name: "loginInfo",
  initialState: {
    isLogin: false,
    data: {}
  },
  reducers: {
    login: (state, action) => {
      state.isLogin = true;
      state.data = action.payload;
    },
    logout: (state) => {
      state.isLogin = false;
      state.data = {};
    }
  }
});

export const { logout, setApprovalStatusCd } = loginInfoSlice.actions;