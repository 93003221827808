import { Helmet } from 'react-helmet-async';

const HelmetWrapper = ({ meta }) => {
  const {
    title,
    description
  } = meta;

  return (
    <>
      <Helmet>
        <title>{title} | helloludi adms</title>
        description && <meta name="description" content={description} />
      </Helmet>
    </>
  );
};

export default HelmetWrapper;