import {useMutation, useQuery} from "react-query";
import {Alert, http, toast} from "../../../utils";
import {useNavigate, useParams} from "react-router-dom";
import HelmetWrapper from "../../../components/common/HelmetWrapper";
import {Button, Col, Form, Row} from "react-bootstrap";
import {history} from "../../../router/History";
import {useEffect, useState} from "react";
import {Controller, useForm} from "react-hook-form";
import ErrorMessageFeedback from "../../../components/common/ErrorMessageFeedback";


function StudentLinkDetail() {

  const urlParam = useParams();
  const navigate = useNavigate();
  const [isUpdate, setIsUpdate] = useState(false);

  const {handleSubmit, formState: {errors}, reset, control} = useForm({
    mode: 'all'
  });

  const { data: result, refetch: refetchSearch } = useQuery(
    ['searchStudentLinkDetail'],
    async () => {
      return (await http.get(`/api/v1/parents/student-link/${urlParam.id}`)).data;
    },
    {
      initialData: {},
      onSuccess: () => {
        reset({...result})
      }
    });

  useEffect(() => {
    async function fetchData() {
      await refetchSearch();
    }
    fetchData()
  }, [refetchSearch])

  const updateAction = useMutation((params) => http.put('/api/v1/parents/student-link-update', params));
  const update = async (data) => {
    const confirmMsg = '학습자 연동 상세를 업데이트 하시겠습니까?'
    const completeMsg = '완료 되었습니다.'
    Alert.confirm(confirmMsg, async () => {
      result.godomallId = data.godomallId
      result.phoneNumber = data.phoneNumber
      await updateAction.mutateAsync(result)

      toast.success(completeMsg)
      setIsUpdate(false)
      navigate({
        pathname:'/adms/parents/student-link/detail/view/' + result.id
      })
    })
  }

  return (
    <>
      <div className="contents-fluid">
        <HelmetWrapper meta={{
          title : '학습자 연동 상세'
        }} />
        <div className="page-title">
          학습자 연동 상세
        </div>

        <div className="text-end mt-3">
          {
            !isUpdate &&
              <>
                <Button variant="outline-danger" onClick={() => setIsUpdate(true) }><span className="material-icons">mode</span><span>Edit</span></Button>
                <Button variant="outline-secondary" onClick={() => history.back() }><span className="material-icons">keyboard_backspace</span><span>Back</span></Button>
              </>
          }
          {
            isUpdate &&
              <>
                <Button variant="outline-primary" onClick={handleSubmit(update)}><span className="material-icons">done</span><span>Done</span></Button>
                <Button variant="outline-secondary" onClick={() => setIsUpdate(false)}><span className="material-icons">keyboard_backspace</span><span>Back</span></Button>
              </>
          }

        </div>

        <div className="detail mt-4">
          <Row>
            <Col xs="12" md="6">
              <div className="detail-row">
                <span className="label">User Id</span> <span>{result.userId}</span>
              </div>
            </Col>
            <Col xs="12" md="6">
              <div className="detail-row">
                <span className="label">Student ID</span> <span>{result.studentId}</span>
              </div>
            </Col>
            {
              !isUpdate &&
                <Col xs="12" md="6">
                  <div className="detail-row">
                    <span className="label">Godomall ID</span> <span>{result.godomallId}</span>
                  </div>
                </Col>
            }
            {
              isUpdate &&
                <Col xs="12" md="6">
                  <div className="detail-row">
                    <span className="label">Godomall ID</span>
                    <Form.Group className="form-group required" controlId="id_godomallId">
                      <Controller
                          name="godomallId"
                          control={control}
                          rules={{ required: true }}
                          defaultValue={result.godomallId}
                          render={({ field }) =>
                              <Form.Control
                                  {...field}
                                  type="text"
                                  maxLength="100"
                                  placeholder=""
                                  isInvalid={errors.subject}
                              />
                          }
                      />
                      <ErrorMessageFeedback error={errors.subject} />
                    </Form.Group>
                  </div>
                </Col>
            }
            <Col xs="12" md="6">
              <div className="detail-row">
                <span className="label">Student Name</span> <span>{result.studentName}</span>
              </div>
            </Col>
            <Col xs="12" md="6">
              <div className="detail-row">
                <span className="label">Real Name</span> <span>{result.realName}</span>
              </div>
            </Col>
            <Col xs="12" md="6">
              <div className="detail-row">
                <span className="label">Status</span> <span>{result.statusCd}</span>
              </div>
            </Col>
            {
              !isUpdate &&
                <Col xs="12" md="6">
                  <div className="detail-row">
                    <span className="label">Phone Number</span> <span>{result.phoneNumber}</span>
                  </div>
                </Col>
            }
            {
              isUpdate &&
                <Col xs="12" md="6">
                  <div className="detail-row">
                    <span className="label">Phone Number</span>
                    <Form.Group className="form-group required" controlId="id_godomallId">
                      <Controller
                          name="phoneNumber"
                          control={control}
                          rules={{ required: true }}
                          defaultValue={result.phoneNumber}
                          render={({field}) =>
                              <Form.Control
                                  {...field}
                                  size="20"
                                  type="text"
                                  maxLength="100"
                                  placeholder=""
                                  isInvalid={errors.subject}
                              />
                          }
                      />
                      <ErrorMessageFeedback error={errors.subject} />
                    </Form.Group>
                  </div>
                </Col>
            }
            <Col xs="12" md="6">
              <div className="detail-row">
                <span className="label">학부모 리포트 패스워드 설정여부</span> <span>{result.setupPassword ? '설정' : '미설정'}</span>
              </div>
            </Col>
            <Col xs="12" md="6">
              <div className="detail-row">
                <span className="label">Create Date</span> <span>{result.createDate}</span>
              </div>
            </Col>
            {
              result.statusCd !== 'Complete' &&
              <Col xs="12" md="6">
                <div className="detail-row">
                  <span className="label">Expire Date</span> <span>{result.expireDate}</span>
                </div>
              </Col>
            }
            {
              result.statusCd === 'Complete' &&
              <Col xs="12" md="6">
                <div className="detail-row">
                  <span className="label">Complete Date</span> <span>{result.completeDate}</span>
                </div>
              </Col>
            }
          </Row>
        </div>
      </div>
    </>
  );
}

export default StudentLinkDetail;