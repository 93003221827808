import _ from 'lodash';
import {useEffect, useMemo} from "react";
import HelmetWrapper from "../../../components/common/HelmetWrapper";
import {Col, Form, Row} from "react-bootstrap";
import {Controller, useForm} from "react-hook-form";
import {useQuery} from "react-query";
import {http, Validator, Constant} from "../../../utils";
import PagingTable from "../../../components/material/PagingTable";
import ErrorMessageFeedback from "../../../components/common/ErrorMessageFeedback";

function AcCompetence() {
  const initValue = useMemo(() => {
    return {
      portalOrder: '',
      competenceType: '',
      conversationLevel: '',
      studentIdText: '',
      studentIdList: [],
    }
  }, []);

  const {handleSubmit, reset, formState: {errors}, control, watch, getValues, setValue} = useForm({
    mode: 'onChange',
    defaultValues : { ...initValue }
  });

  const { data: resultList, refetch : refetchSearch } = useQuery(
    ['searchAcCompetence'],
    async () => {
      const res = await http.post(`/api/v1/report/ac-competence`,getValues());
      return res.data;
    },
    {
      enabled: false,
      initialData: {}
    });

  const watchStudentIdText = watch('studentIdText', '');
  const watchStudentIdList = watch('studentIdList', []);

  useEffect(() => {
    const list = _.split(watchStudentIdText, /\r?\n/)
      .map(v => v.trim())
      .filter((v) => v.length !== 0);

    setValue('studentIdList', list);
  }, [watchStudentIdText, setValue]);

  const handleSearch = (e) => {
    e.preventDefault();
    handleSubmit(async () => {
      await refetchSearch();
    })();
  }

  const downloadExcel = (e) => {
    e.preventDefault();
    handleSubmit(() => {
      http.postExcelDownload('/api/v1/report/ac-competence/excel', {...getValues()});
    })();
  }

  const columns = [
    {
      Header: "Competence Type",
      accessor: "competenceType",
      textAlign: 'center',
    },
    {
      Header: "Competence Id",
      accessor: "competenceId",
      textAlign: 'left',
    },
    {
      Header: "Level",
      accessor: "level",
      textAlign: 'center',
    },
    {
      Header: "Min Point",
      accessor: "minPoint",
      textAlign: 'center',
      Cell: ({ row }) => _.round(row.original.minPoint, 1),
    },
    {
      Header: "Max Point",
      accessor: "maxPoint",
      textAlign: 'center',
      tdClassName: 'center',
      Cell: ({ row }) => _.round(row.original.maxPoint, 1),
    },
    {
      Header: "Avg Point",
      accessor: "avgPoint",
      textAlign: 'center',
      tdClassName: 'center',
      Cell: ({ row }) => _.round(row.original.avgPoint, 1),
    },
  ];

  return (
    <>
      <div className="contents-fluid">
        <HelmetWrapper meta={{
          title : 'Ac Competence'
        }} />

        <div className="page-title">
          Ac Competence
        </div>

        <div className="search-condition">
          <Form onSubmit={handleSearch} onReset={reset} className="w-100">
            <Row>
              <Col xs="12" md="6">
                <Form.Group className="form-group required" controlId="id_portalOrder">
                  <Form.Label>Portal Order</Form.Label>
                  <Controller
                    name="portalOrder"
                    control={control}
                    rules={{
                      required: true,
                      validate: {
                        number: (value) => Validator.validateNumber(value)
                      }
                    }}
                    render={({ field }) =>
                      <Form.Control
                        {...field}
                        type="text"
                        placeholder="일치"
                        isInvalid={errors.portalOrder}
                      />
                    }
                  />
                  <ErrorMessageFeedback error={errors.portalOrder} />
                </Form.Group>
              </Col>
              <Col xs="12" md="6">
                <Form.Group className="form-group" controlId="id_competenceType">
                  <Form.Label>Competence Type</Form.Label>
                  <Controller
                    name="competenceType"
                    control={control}
                    render={({ field }) =>
                      <Form.Control
                        as="select"
                        className="form-select"
                        {...field}
                        value={field.value || ''}
                      >
                        <option value=""></option>
                        {
                          Constant.CODE_COMPETENCE_TYPE_CD.map((item, idx) => {
                            return <option key={idx} value={item.code}>{item.name}</option>
                          })
                        }
                      </Form.Control>
                    }
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs="12" md="6">
                <Form.Group className="form-group" controlId="id_conversationLevel">
                  <Form.Label>Start Level</Form.Label>
                  <Controller
                    name="conversationLevel"
                    control={control}
                    rules={{
                      validate: {
                        decimalPoint1: (value) => Validator.validateDecimalPoint1(value)
                      }
                    }}
                    render={({ field }) =>
                      <Form.Control
                        {...field}
                        type="text"
                        placeholder="일치"
                        isInvalid={errors.conversationLevel}
                      />
                    }
                  />
                  <ErrorMessageFeedback error={errors.conversationLevel} />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs="12">
                <Form.Group className="form-group" controlId="id_studentIdText">
                  <Form.Label>학습자 ID ( <span className="fw-bold text-primary">{watchStudentIdList.length}</span> 건 )</Form.Label>
                  <Controller
                    name="studentIdText"
                    control={control}
                    render={({ field }) =>
                      <Form.Control
                        {...field}
                        as="textarea"
                        rows="5"
                        placeholder="줄바꿈 으로 (Enter) 아이디를 구분하여 넣어주세요"
                      />
                    }
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs="12" className="btn-wrapper justify-content-between">
                <div>
                  <button className="btn btn-outline-secondary" type="button" onClick={downloadExcel}>
                    <i className="fas fa-file-download me-2"></i>Excel Download
                  </button>
                </div>
                <div>
                  <button className="btn btn-primary" type="submit">
                    <span className="material-icons">search</span>Search
                  </button>
                </div>
              </Col>
            </Row>
          </Form>
        </div>
        <div className="search-result">
          <div className="sub-title">검색 결과</div>

          <PagingTable columns={columns}
                       data={resultList}
          />
        </div>
      </div>
    </>
  );
}

export default AcCompetence;