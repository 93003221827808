import HelmetWrapper from "../../../components/common/HelmetWrapper";
import {useMemo, useState} from "react";
import {Button, Col, Form, Row, Tab, Tabs} from "react-bootstrap";
import {Controller, useForm} from "react-hook-form";
import ErrorMessageFeedback from "../../../components/common/ErrorMessageFeedback";
import {useMutation, useQuery} from "react-query";
import {Alert, http, toast} from "../../../utils";
import StudentBriefInfo from "../StudentBriefInfo";
import Inventory from "./Inventory";
import ObjectUnlock from './ObjectUnlock';
import Mission from './Mission';
import NpcState from './NpcState';

function EditStudent() {
  const initValue = useMemo(() => {
    return {
      studentId: ''
    }
  }, []);

  const [forceRefresh, setForceRefresh] = useState(0)

  const {handleSubmit, reset, formState: {errors}, control, getValues, watch} = useForm({
    mode: 'onChange',
    defaultValues : { ...initValue }
  });

  const { data: result, refetch : refetchSearch } = useQuery(
    ['searchStudent'],
    async () => {
      const studentId = getValues('studentId');
      const result = await http.get(`/api/v1/edit/student/${studentId}`);
      return result.data;
    },
    {
      enabled: false,
      initialData: {}
    });

  const addPortalSentenceAction = useMutation((params) => http.put(`/api/v1/edit/student/portal/portal-sentence`, params));
  const deleteAction = useMutation((studentId) => http.delete(`/api/v1/edit/student/${studentId}`));

  const onValid = async () => {
    await refetchSearch();
    setForceRefresh(forceRefresh + 1);
  };

  const handleDelete = (e) => {
    e.preventDefault();
    const confirmMsg = `학습자를 정말 삭제 하시겠습니까?`;
    const completeMsg = '완료 되었습니다.';

    Alert.confirm(confirmMsg, async () => {
      await deleteAction.mutateAsync(getValues('studentId'));

      toast.success(completeMsg);
      await onValid();
    });
  }

  const fnAddPortalSentence = () => {
    const confirmMsg = `Portal Sentense를 추가 하시겠습니까?`;
    const completeMsg = '완료 되었습니다.';

    Alert.confirm(confirmMsg, async () => {
      const param = {userId: result.userId, studentId: result.id};

      await addPortalSentenceAction.mutateAsync(param);

      toast.success(completeMsg);
      await onValid();
    });
  }

  return (
    <>
      <div className="contents-fluid">
        <HelmetWrapper meta={{
          title : 'Edit Student'
        }} />
        <div className="page-title">
          Edit Student
        </div>

        <div className="search-condition">
          <Form onSubmit={handleSubmit(onValid)} onReset={reset} className="w-100">
            <Row>
              <Col xs="12" md="12">
                <Form.Group className="form-group required" controlId="id_studentId">
                  <Form.Label>Student ID</Form.Label>
                  <Controller
                    name="studentId"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) =>
                      <Form.Control
                        {...field}
                        type="text"
                        placeholder="일치"
                        isInvalid={errors.studentId}
                      />
                    }
                  />
                  <ErrorMessageFeedback error={errors.studentId} />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs="6" className="btn-wrapper justify-content-start">
                <Button type="button"
                        variant="outline-danger"
                        disabled={!watch('studentId')}
                        onClick={(e) => handleDelete(e)}>
                  <span className="material-icons">delete_outline</span>Delete Student</Button>
              </Col>
              <Col xs="6" className="btn-wrapper">
                <button className="btn btn-primary" type="submit">
                  <span className="material-icons">search</span>Search
                </button>
              </Col>
            </Row>
          </Form>
        </div>

        <div className="search-result">
          <div className="sub-title">Student Info</div>

          <StudentBriefInfo student={result}
                            fnAddPortalSentence={fnAddPortalSentence}
                            fnSearch={onValid} />
        </div>

        <div className="search-result mt-5">
          <Tabs
            id="daily-report-tabs"
            className="mb-3"
          >
            <Tab eventKey="inventory" title="Inventory">
              <Inventory student={result} forceRefresh={forceRefresh} />
            </Tab>
            <Tab eventKey="objectUnlock" title="Object Unlock">
              <ObjectUnlock student={result} forceRefresh={forceRefresh} />
            </Tab>
            <Tab eventKey="npcState" title="NPC State">
              <NpcState student={result} forceRefresh={forceRefresh} />
            </Tab>
            <Tab eventKey="mission" title="Mission">
              <Mission student={result} forceRefresh={forceRefresh} />
            </Tab>
          </Tabs>
        </div>
      </div>
    </>
  );
}

export default EditStudent;