import "react-datepicker/dist/react-datepicker.css";
import {Col, Form, Row,  Tabs, Tab} from "react-bootstrap";
import HelmetWrapper from "../../../components/common/HelmetWrapper";
import {Controller, useForm} from "react-hook-form";
import {useMemo, useState, useRef, useEffect} from "react";
import DatePicker from "react-datepicker";
import DatePickerInput from '../../../components/material/DatePickerInput';
import ErrorMessageFeedback from "../../../components/common/ErrorMessageFeedback";
import {DateUtils, http} from "../../../utils";
import moment from 'moment';
import _ from "lodash";
import {useQuery} from "react-query";
import GeneralTab from './GeneralTab';
import UserInfoTab from './UserInfoTab';
import ContentsTab from './ContentsTab';
import MissionFcTimeTab from './MissionFcTimeTab';
import RawDataTab from "./RawDataTab";
import DmLogTab from "./DmLogTab";
import ZeppelinGameTab from './ZeppelinGameTab';

function DailyReport() {
  const initValue = useMemo(() => {
    return {
      totalPeriodStart : '',
      totalPeriodEnd : '',
      startDate: moment(DateUtils.todayTime('00:00:00')).subtract(1, 'days').toDate(),
      endDate: DateUtils.todayTime('00:00:00'),
      activeTab: 'general',
      excludeStudentIdText: '',
      excludeStudentIdList: [],
      userIdText: '',
      userIdList: []
    }
  }, []);

  const {handleSubmit, reset, formState: {errors, isValid}, control, watch, getValues, setValue } = useForm({
    mode: 'onChange',
    defaultValues : { ...initValue }
  });

  const { data: resultGeneral, refetch : refetchGeneral } = useQuery(
    ['searchDailyReportGeneral'],
    async () => {
      const result = await http.post(`/api/v1/report/dailyreport/general`, getValues());
      return result.data;
    },
    {
      enabled: false,
      initialData: { }
    });
  const { data: resultUserInfo, refetch : refetchUserInfo } = useQuery(
    ['searchDailyReportUserInfo'],
    async () => {
      const result = await http.post(`/api/v1/report/dailyreport/user-info`, getValues());
      return result.data;
    },
    {
      enabled: false,
      initialData: { }
    });
  const { data: resultContents, refetch : refetchContents } = useQuery(
    ['searchDailyReportContents'],
    async () => {
      const result = await http.post(`/api/v1/report/dailyreport/contents`, getValues());
      return result.data;
    },
    {
      enabled: false,
      initialData: []
    });

  const { data: resultMissionFcTime, refetch : refetchMissionFcTime } = useQuery(
    ['searchDailyReportMissionFcTime'],
    async () => {
      const result = await http.post(`/api/v1/report/dailyreport/mission-fc-time`, getValues());
      return result.data;
    },
    {
      enabled: false,
      initialData: []
    });

  const { data: resultDmLog, refetch : refetchDmLog } = useQuery(
    ['searchDailyReportDmLog'],
    async () => {
      const result = await http.post(`/api/v1/report/dailyreport/dm-log`, getValues());
      return result.data;
    },
    {
      enabled: false,
      initialData: []
    });

  const formRef = useRef();
  const [key, setKey] = useState('general');
  const watchExcludeStudentIdText = watch('excludeStudentIdText', '');
  const watchExcludeStudentIdList = watch('excludeStudentIdList', []);
  const watchIncludeUserIdText = watch('userIdText', '');
  const watchIncludeUserIdList = watch('userIdList', []);

  useEffect(() => {
    const list = _.split(watchExcludeStudentIdText, /\r?\n/)
        .map(v => v.trim())
        .filter((v) => v.length !== 0);

    setValue('excludeStudentIdList', list);
  }, [watchExcludeStudentIdText, setValue]);

  useEffect(() => {
    const list = _.split(watchIncludeUserIdText, /\r?\n/)
      .map(v => v.trim())
      .filter((v) => v.length !== 0);

    setValue('userIdList', list);
  }, [watchIncludeUserIdText, setValue]);

  const onValid = () => {
    const tab = getValues('activeTab');
    const fn = {
      'general' : refetchGeneral,
      'userInfo' : refetchUserInfo,
      'contents' : refetchContents,
      'missionFcTime' : refetchMissionFcTime,
      'dmLog' : refetchDmLog
    }
    if(typeof fn[tab] === 'function') {
      fn[tab]();
    }
    setKey(tab);
  };

  const handleTabClick = (key) => {
    setValue('activeTab', key);
    formRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))
  };

  const onDownload = async (type) => {
    if(!isValid) {
      return;
    }
    const urlMap = {
      'user-info' : '/api/v1/report/excel/user-info',
      'student' : '/api/v1/report/excel/student',
      'student-portal-cycle' : '/api/v1/report/excel/student-portal-cycle',

      'level-test' : '/api/v1/report/excel/level-test',
      'dailyReport' : '/api/v1/report/excel/daily-report',
      'mission-scoring' : '/api/v1/report/excel/mission-scoring',

      'mission-scoring-vocab' : '/api/v1/report/excel/mission-scoring/vocab',
      'mission-scoring-function' : '/api/v1/report/excel/mission-scoring/function',
      'mission-scoring-grammar' : '/api/v1/report/excel/mission-scoring/grammar',
      'mission-scoring-pronunciation' : '/api/v1/report/excel/mission-scoring/pronunciation',

      'content-scoring' : '/api/v1/report/excel/content-scoring',

      'content-scoring-vocab' : '/api/v1/report/excel/content-scoring/vocab',
      'content-scoring-function' : '/api/v1/report/excel/content-scoring/function',
      'content-scoring-grammar' : '/api/v1/report/excel/content-scoring/grammar',
      'content-scoring-pronunciation' : '/api/v1/report/excel/content-scoring/pronunciation',

      'mission' : '/api/v1/report/excel/mission',
      'recommedation' : '/api/v1/report/excel/recommedation',

      'student-item' : '/api/v1/report/excel/student-item',
      'student-structure' : '/api/v1/report/excel/student-structure',
      'interaction-mh' : '/api/v1/report/excel/interaction-mh',
      'game-day' : '/api/v1/report/excel/game-day',
      'boss-hunting' : '/api/v1/report/excel/boss-hunting',

      'student-accesses' : '/api/v1/report/excel/student/accesses',
      'student-ludienergy' : '/api/v1/report/excel/student-ludienergy',
      'student-pin' : '/api/v1/report/excel/student-pin',
      'student-inventory' : '/api/v1/report/excel/student-inventory',
      'student-npc-friendship' : '/api/v1/report/excel/student-npc-friendship',
      'student-npc-friendship-grade' : '/api/v1/report/excel/student-npc-friendship-grade',
      // 'recommedation-cbt3' : '/api/v1/report/excel/recommedation/cbt3',
      'dmLog' : '/api/v1/report/excel/dm-log',
      'interaction-fc' : '/api/v1/report/excel/interaction-fc',
      'interaction-zg' : '/api/v1/report/excel/interaction-zg',
      'interaction-ag' : '/api/v1/report/excel/interaction-ag',
      'interaction-je' : '/api/v1/report/excel/interaction-je',
      'interaction-o2g' : '/api/v1/report/excel/interaction-o2g',
      'interaction-pet' : '/api/v1/report/excel/interaction-pet',
      'vending-machine' : '/api/v1/report/excel/vending-machine',
      'review-content' : '/api/v1/report/excel/review-content',
      'all' : '/api/v1/report/excel/all',
      // 'ac-cbt3' : '/api/v1/report/excel/ac/cbt3',
      // 'content-scoring-cbt3' : '/api/v1/report/excel/content-scoring/cbt3',
      'parent-report-search' : '/api/v1/report/excel/parent-report-search',
      'progress' : '/api/v1/report/excel/progress'
    }
    if(!urlMap[type]) {
      return;
    }

    http.postExcelDownload(urlMap[type], {...getValues()});
  };

  return (
    <>
      <div className="contents-fluid">
        <HelmetWrapper meta={{
          title : 'Daily Report'
        }} />

        <div className="page-title">
          Daily Report
        </div>

        <div className="search-condition">
          <Form onSubmit={handleSubmit(onValid)} onReset={reset} ref={formRef} className="w-100">
            <Row>
              <Col xs="12" className="mb-4">
                <Form.Group className="form-group required mb-0">
                  <Form.Label>Date</Form.Label>
                </Form.Group>

                <div className="condition-date-picker">
                  <Form.Group className="form-group mb-0">
                    <Controller
                      name="startDate"
                      control={control}
                      rules={ {required: true} }
                      render={({ field, fieldState }) =>
                        <DatePicker
                          {...field}
                          dateFormat="yyyy/MM/dd HH:mm"
                          selected={field.value}
                          showTimeSelect
                          timeIntervals={60}
                          autoComplete="off"
                          onChange={(date) => {
                            field.onChange(date);
                          }}
                          className={fieldState.invalid ? 'is-invalid' : ''}
                          customInput={ <DatePickerInput /> }
                        />
                      }
                    />
                    <ErrorMessageFeedback error={errors.startDate} />
                  </Form.Group>

                  <div className='ms-2 me-2'> ~ </div>

                  <Form.Group className="form-group mb-0">
                    <Form.Label></Form.Label>
                    <Controller
                      name="endDate"
                      control={control}
                      rules={ {required: true} }
                      render={({ field, fieldState }) =>
                        <DatePicker
                          {...field}
                          dateFormat="yyyy/MM/dd HH:mm"
                          selected={field.value}
                          showTimeSelect
                          timeIntervals={60}
                          autoComplete="off"
                          onChange={(date) => {
                            field.onChange(date);
                          }}
                          className={fieldState.invalid ? 'is-invalid' : ''}
                          customInput={ <DatePickerInput /> }
                        />
                      }
                    />
                    <ErrorMessageFeedback error={errors.endDate} />
                  </Form.Group>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs="12">
                <Form.Group className="form-group" controlId="id_exclude_studentIdText">
                  <Form.Label>제외할 유저의 StudentID ( <span className="fw-bold text-primary">{watchExcludeStudentIdList.length}</span> 건 )</Form.Label>
                  <Controller
                    name="excludeStudentIdText"
                    control={control}
                    render={({ field }) =>
                      <Form.Control
                        {...field}
                        as="textarea"
                        rows="5"
                        placeholder="줄바꿈(Enter)으로 StudentId를 구분하여 넣어주세요"
                      />
                    }
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs="12">
                <Form.Group className="form-group" controlId="id_include_userIdText">
                  <Form.Label>조회할 유저의 UserID ( <span className="fw-bold text-primary">{watchIncludeUserIdList.length}</span> 건 )</Form.Label>
                  <Controller
                    name="userIdText"
                    control={control}
                    render={({ field }) =>
                      <Form.Control
                        {...field}
                        as="textarea"
                        rows="5"
                        placeholder="줄바꿈(Enter)으로 UserId를 구분하여 넣어주세요"
                      />
                    }
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs="12" className="btn-wrapper">
                <button className="btn btn-primary" type="submit">
                  <span className="material-icons">search</span>Search
                </button>
              </Col>
            </Row>
          </Form>
        </div>

        <div className="search-result">
          <div className="pt-1 pb-4"><a href="#!" onClick={(e) => {e.preventDefault(); onDownload('all');}}>Excel Download<i className="fas fa-file-download ms-2"></i></a></div>
          <Tabs
            id="daily-report-tabs"
            activeKey={key}
            onSelect={handleTabClick}
            className="mb-3"
          >
            <Tab eventKey="general" title="General">
              <GeneralTab data={resultGeneral}/>
            </Tab>
            <Tab eventKey="userInfo" title="User Info">
              <UserInfoTab data={resultUserInfo} />
            </Tab>
            <Tab eventKey="contents" title="Contents">
              <ContentsTab dataList={resultContents} />
            </Tab>
            <Tab eventKey="missionFcTime" title="Mission FC Time">
              <MissionFcTimeTab data={resultMissionFcTime} />
            </Tab>
            <Tab eventKey="zeppelinGame" title="Zeppelin Game">
              <ZeppelinGameTab />
            </Tab>
            <Tab eventKey="dmLog" title="DM Log">
              <DmLogTab data={resultDmLog} />
            </Tab>

            <Tab eventKey="rawData" title="Raw Data">
              <RawDataTab onDownload={onDownload}/>
            </Tab>
          </Tabs>
        </div>
      </div>
    </>
  );
}

export default DailyReport;