import { Constant } from '../../utils';

const level1MenuList = [
  {
    name: 'User',
    icon: 'group',
    path: '',
    childList: [
      {name: 'Account Info', path: '/adms/user/account-info/view'},
      {name: 'Student Info', path: '/adms/user/student-info/view'},
      {name: 'Student Accesses', path: '/adms/user/student-accesses/view'},
    ]
  },
  {
    name: 'Game',
    icon: 'games',
    path: '',
    childList: [
      {name: 'Mission Info', path: '/adms/game/mission-info/view'},
      // {name: 'Transaction', path: '/adms/game/transaction/view'},
      {name: 'Interaction', path: '/adms/game/interaction-info/view'},
      {name: 'Portal', path: '/adms/game/portal-info/view'},
      // {name: 'NPC', path: '/adms/game/npc/view'},
      // {name: 'Animal', path: '/adms/game/animal/view'}
    ]
  },
  {
    name: 'My Account',
    icon: 'person_outline',
    path: '',
    childList: [
      {name: 'Password Change', path: '/adms/my-account/password-change/view'},
    ]
  },
  {
    name: '서울대 온라인 테스트',
    icon: 'school',
    path: '',
    childList: [
      {name: '온라인 테스트 결과', path: '/adms/online-test/report/view'},
    ]
  },
  {
    name: 'Voice Hunter',
    icon: 'mic',
    path: '',
    childList: [
      {name: 'Dm Log', path: '/adms/voice-hunber/dm-log/view'},
      {name: '적립금 신청', path: '/adms/voice-hunber/reserve-fund/view'},
    ]
  },
];

const level2MenuList = [
  {
    name: 'DataTable',
    icon: 'backup',
    path: '',
    childList: [
      {name: 'Excel Upload History', path: '/adms/game/datatable/history/view'},
      {name: 'Data Table Overwrite', path: '/adms/game/datatable/overwrite/view', serverTypes: ['local', 'review']},
    ]
  },
  {
    name: 'Report',
    icon: 'table_chart',
    path: '',
    childList: [
      {name: 'Daily Report', path: '/adms/report/daily-report/view'},
      {name: 'Dm Log', path: '/adms/report/dm-log/view'},
      {name: 'Ac Competence', path: '/adms/report/ac-competence/view'},
    ]
  },
];

const level3MenuList = [
  {
    name: 'Parents',
    icon: 'family_restroom',
    path: '',
    childList: [
      {name: '학습자 연동', path: '/adms/parents/student-link/view'},
    ]
  },
  {
    name: 'Edit Data',
    icon: 'edit',
    path: '',
    childList: [
      {name: 'Edit Student', path: '/adms/edit/student/view'},
      {name: 'Edit Ludi Pass', path: '/adms/edit/ludipass/detail/view'},
      {name: 'Edit Ludi Pass(Bulk)', path: '/adms/edit/ludipass/bulk/view'},
    ]
  },
  {
    name: 'Message',
    icon: 'email',
    path: '',
    childList: [
      {name: 'Repeat', path: '/adms/message/repeat/view'},
      {name: 'Reward', path: '/adms/message/reward/view'},
    ]
  },
  {
    name: 'Maintenance',
    icon: 'construction',
    path: '',
    childList: [
      {name: 'Maintenance', path: '/adms/maintenance/view'},
      {name: 'Routine Maintenance', path: '/adms/routine-maintenance/view'},
    ]
  },
  {
    name: 'B2B',
    icon: 'groups_2',
    path: '',
    childList: [
      {name: 'Create Account', path: '/adms/b2b/account/view'},
      {name: 'Create History', path: '/adms/b2b/history/view'},
    ]
  },
  {
    name: 'Notice',
    icon: 'cached',
    path: '',
    childList: [
      {name: 'Popup Management', path: '/adms/notice/view'},
    ]
  },
];

const level4MenuList = [
  {
    name: 'System',
    icon: 'settings',
    path: '',
    childList: [
      {name: 'Adms User', path: '/adms/system/adms-user/view'},
    ]
  },
];

export const getMenuList = (role) => {
  if(Constant.ROLE_LEVEL_1 === role) {
    return level1MenuList;
  } else if(Constant.ROLE_LEVEL_2 === role){
    return [...level1MenuList, ...level2MenuList];
  } else if(Constant.ROLE_LEVEL_3 === role) {
    return [...level1MenuList, ...level2MenuList, ...level3MenuList]
  } else if(Constant.ROLE_LEVEL_4 === role) {
    return [...level1MenuList, ...level2MenuList, ...level3MenuList, ...level4MenuList]
  } else {
    return [];
  }
}