import { Table } from "react-bootstrap";
import { useState } from "react";

function DefaultTable({ columns, data = [], defaultSorted, fnSort, className = '' }) {
  const [sortBy, setSortBy] = useState(defaultSorted)
  const isSort = typeof fnSort === 'function';

  const handleHeaderClick = (column) => {
    if(!isSort) {
      return;
    }

    let changedSortBy = {
      id : column.accessor,
      desc : false
    }
    if(sortBy.id === column.accessor) {
      changedSortBy = {
        id : column.accessor,
        desc : !sortBy.desc
      }
    }
    setSortBy({...changedSortBy});
    fnSort(changedSortBy);
  };

  return (
    <Table responsive bordered hover className={className}>
      <thead className="table-dark">
        <tr>
          {
            columns.map((header, i) => {
              const headerProps = {
                  style: {
                    minWidth: header.minWidth,
                    width: header.width,
                    textAlign: 'center'
                  }
              };
              return (
                <th key={i} {...headerProps}
                    className={`${isSort ? 'cursor-pointer' : ''}`}
                    onClick={() => handleHeaderClick(header)}>
                  <span>{header.Header}</span>
                  <span>
                      {
                        (isSort && sortBy?.id === header.accessor)
                        ? (sortBy?.desc
                              ? <i className="fas fa-sort-down ms-2"></i>
                              : <i className="fas fa-sort-up ms-2"></i>
                          )
                        : ""
                      }
                  </span>
                </th>
              );
            })
          }
        </tr>
      </thead>
      <tbody>
      {
        data?.map((row, rowIdx) => {
          return (
            <tr key={rowIdx}>
              {
                columns.map((column, i) => {
                  const cellProps = {
                    style: {
                      minWidth: column.minWidth,
                      width: column.width,
                      textAlign : column.textAlign
                    }
                  };
                  return (
                    <td key={`${rowIdx}_${i}`}  {...cellProps} className={`${column.tdClassName || ''}`}>
                      { !column.Cell && column.accessor && (row[column.accessor] || '')}

                      { column.Cell && <column.Cell row={{original: row}} /> }
                    </td>
                  );
                })
              }
            </tr>
          );
        })
      }
      {
        (!data || data.length === 0) &&
        <tr>
          <td colSpan={columns.length} className="text-center">검색 결과가 없습니다.</td>
        </tr>
      }
      </tbody>
    </Table>
  )
}

export default DefaultTable;