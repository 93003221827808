import React from "react";
import { Outlet } from 'react-router-dom';

// Layout
const LoginLayout = () => {
  const container = {
    'backgroundColor': '#1d1919'
  };

  return (
    <>
      <div style={container}>
        <Outlet />
      </div>
    </>
  );
}
export default LoginLayout;

