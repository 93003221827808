import _ from 'lodash';
import HelmetWrapper from "../../../components/common/HelmetWrapper";
import DefaultTable from "../../../components/material/DefaultTable";
import {Alert, http, toast} from "../../../utils";
import {useMutation, useQuery} from "react-query";
import {Col, Row} from "react-bootstrap";

function DataTableOverwrite() {

  const { data: result } = useQuery(
    ['searchAllDataTable'],
    async () => {
      const result = [];
      const data = (await http.get(`/api/v1/datatable/overwrite/collections`)).data;
      _.forEach(data, function(name) {
        result.push({name : name});
      });
      return result;
    },
    {
      enabled: true,
      initialData: []
    });

  const allOverwriteAction = useMutation(() => http.post(`/api/v1/datatable/overwrite/collections`));
  const overwriteAction = useMutation((collectionName) => http.post(`/api/v1/datatable/overwrite/collection/${collectionName}`));

  const handleAllOverwrite = () => {
    const overwriteMsg = `review -> prod 서버에 모든 DataTable을 Overwrite 하시겠습니까?`;
    Alert.confirm(overwriteMsg, async () => {
      await allOverwriteAction.mutateAsync();

      toast.success('완료 되었습니다.');
    });
  }

  const handleOverwrite = (e, data) => {
    const overwriteMsg = `[${data.name}] review -> prod 서버에 Overwrite 하시겠습니까?`;
    Alert.confirm(overwriteMsg, async () => {
      await overwriteAction.mutateAsync(data.name.toLowerCase());

      toast.success('완료 되었습니다.');
    });
  }

  const columns = [
    {
      Header: "Collection Name",
      accessor: "name",
      textAlign: 'left',
      width:'50%',
    },
    {
      Header: "",
      accessor: "",
      textAlign: 'center',
      Cell: ({ row }) => (
        <button className="btn btn-outline-success" type="button" onClick={ e => handleOverwrite(e, row.original) }>
          <span className="material-icons">done</span>Overwrite
        </button>
      ),
    },
  ];

  return (
    <>
      <div className="contents-fluid">
        <HelmetWrapper meta={{
          title : 'Data Table Overwrite'
        }} />

        <div className="page-title">
          Data Table Overwrite(review -> prod)
        </div>

        <div className="search-condition">
          <Row>
            <Col xs="12" className="btn-wrapper">
              <button className="btn btn-success" type="button" onClick={handleAllOverwrite}>
                <span className="material-icons">done</span>All Overwrite
              </button>
            </Col>
          </Row>
        </div>
        <div className="search-result">
          <DefaultTable columns={columns}
                        data={result}
          />
        </div>
      </div>
    </>
  );
}

export default DataTableOverwrite;