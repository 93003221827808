import {Button, Col, Form, Modal, Row} from "react-bootstrap";
import {Controller, useForm} from "react-hook-form";
import {useMemo} from "react";
import {Alert, Constant, http, toast} from "../../../../utils";
import {useMutation, useQuery} from "react-query";
import _ from "lodash";

function AddNpcModal({onHide, modalItem}) {
  const {
    isShow,
    item,
  } = modalItem;

  const initValue = useMemo(() => {
    return {
      npcId: '',
      npcState: ''
    }
  }, []);

  const {handleSubmit, reset, control} = useForm({
    mode: 'onChange',
    defaultValues : { ...initValue }
  });

  const { data: resultNpcList, refetch : refetchNpcListSearch } = useQuery(
    ['searchNpcList'],
    async () => {
      const result = await http.get(`/api/v1/edit/student/npc-state/npc`);

      if(item.isRegist) {
        item.selectedItem = {
          npcId : _.first(result.data).npcId,
          npcState: _.first(Constant.CODE_NPC_STATE_CD).code
        };
      }
      return result.data;
    },
    {
      enabled: false,
      initialData: []
    });

  const insertNpcAction = useMutation((params) => http.post('/api/v1/edit/student/npc-state', params));
  const updateNpcAction = useMutation((params) => http.put('/api/v1/edit/student/npc-state', params));

  const handleClose = (isRefresh = false) => {
    onHide(isRefresh);
  };

  const init = async () => {
    await refetchNpcListSearch();
    reset({...item.selectedItem});
  };

  const onValid = async (data) => {
    const confirmMsg = item.isRegist ? '등록 하시겠습니까?' : '수정 하시겠습니까?';
    const completeMsg = item.isRegist ? '등록 되었습니다.' : '수정 되었습니다.';
    Alert.confirm(confirmMsg, async () => {
      const param = {...data, userId : item.userId, studentId: item.studentId};
      item.isRegist ? await insertNpcAction.mutateAsync(param) : await updateNpcAction.mutateAsync(param);

      toast.success(completeMsg);
      handleClose(true);
    });
  };

  return (
    <>
      <Modal
        size="lg"
        show={isShow}
        onHide={handleClose}
        className="detail"
        onEntered={() => {
          init();
        }}
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">{`${item.isRegist ? 'Add NPC' : 'Update NPC'}`}</Modal.Title>
          <Button type="button" className="close" aria-label="Close" onClick={handleClose}>
            <span aria-hidden="true">&times;</span>
          </Button>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit(onValid)} onReset={reset} className="w-100 mt-4">
            <Row>
              <Col xs="12">
                <Form.Group className="form-group required" controlId="id_npcId">
                  <Form.Label>NPC</Form.Label>
                  <Controller
                    name="npcId"
                    control={control}
                    render={({ field }) =>
                      <Form.Control
                        as="select"
                        className="form-select"
                        {...field}
                        value={field.value || ''}
                      >
                        {
                          resultNpcList.map((item, idx) => {
                            return <option key={idx} value={item.npcId}>{`[${item.npcId}] ${item.name}`}</option>
                          })
                        }
                      </Form.Control>
                    }
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs="12">
                <Form.Group className="form-group required" controlId="id_npcState">
                  <Form.Label>NPC State</Form.Label>
                  <Controller
                    name="npcState"
                    control={control}
                    render={({ field }) =>
                      <Form.Control
                        as="select"
                        className="form-select"
                        {...field}
                        value={field.value || ''}
                      >
                        {
                          Constant.CODE_NPC_STATE_CD.map((item, idx) => {
                            return <option key={idx} value={item.code}>{`${item.name}`}</option>
                          })
                        }
                      </Form.Control>
                    }
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs="12" className="btn-wrapper mt-4 text-end">
                {
                  item.isRegist &&
                  <button className="btn btn-info" type="submit">
                    <span className="material-icons">add</span>Add
                  </button>
                }
                {
                  !item.isRegist &&
                  <button className="btn btn-success" type="submit">
                    <span className="material-icons">done</span>Update
                  </button>
                }
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default AddNpcModal;