import {Controller, useForm} from "react-hook-form";
import {useCallback, useMemo, useState, useEffect} from "react";
import {useMutation, useQuery} from "react-query";
import {Alert, http, toast, Validator} from "../../../../utils";
import SortingTable from "../../../../components/material/SortingTable";
import Paging from "../../../../components/common/Paging";
import UnlockObjectSearchModal from "./UnlockObjectSearchModal";
import {Col, Form, Row} from "react-bootstrap";
import ErrorMessageFeedback from "../../../../components/common/ErrorMessageFeedback";

function ObjectUnlock({ student = {}, forceRefresh }) {
  const initValue = useMemo(() => {
    return {
      processId: ''
    }
  }, []);

  const [unlockSearchModalItem, setUnlockSearchModalItem] = useState({
    isShow: false
  });

  const {handleSubmit, reset, formState: {errors}, control, setValue, getValues} = useForm({
    mode: 'onChange',
    defaultValues : { ...initValue }
  });

  const { data: result, refetch : refetchSearch } = useQuery(
    ['searchStudentUnLock'],
    async () => {
      const param = {
        ...getValues(),
        userId : student?.userId,
        studentId : student?.id
      }

      const result = await http.get(`/api/v1/edit/student/unlock`,{params: param});
      return result.data;
    },
    {
      enabled: false,
      initialData: []
    });
  const insertObjectUnlockAction = useMutation((params) => http.post('/api/v1/edit/student/unlock/item', params));
  const disableObjectUnlockAction = useMutation((params) => http.put('/api/v1/edit/student/unlock/item', params));

  useEffect(() => {
    if(!student.id) {
      return;
    }

    async function fetchData() {
      await refetchSearch();
    }
    setValue('currentPage', 1);
    fetchData();

  }, [student, forceRefresh, refetchSearch, setValue]);

  const fnSort = useCallback(async (sortBy) => {
    setValue('sortByName', sortBy.id);
    setValue('desc', sortBy.desc);

    await refetchSearch();
  }, [setValue, refetchSearch]);

  const fnPaging = async (pageNum) => {
    setValue('currentPage', pageNum);
    await refetchSearch();
  };

  const onValid = useCallback(async () => {
    setValue('currentPage', 1);
    await refetchSearch();
  }, [setValue, refetchSearch]);

  const handleUnLockSearchModalShow = () => {
    setUnlockSearchModalItem({
      isShow: true
    });
  };
  const handleUnLockSearchModalHide = () => {
    setUnlockSearchModalItem({
      isShow: false
    });
  }

  const handleUnlockSearchSelect = async (data) => {
    const completeMsg = '완료 되었습니다.';
    const param = {...data, userId : student.userId, studentId: student.id};

    await insertObjectUnlockAction.mutateAsync(param);

    toast.success(completeMsg);
    await refetchSearch();
  }

  const disableUnlock = useCallback(async (e, data) => {
      e.preventDefault();
      const confirmMsg = 'unlock된 object를 무효화 합니다. <br/> 계속 하시겠습니까?';
      const completeMsg = '완료 되었습니다.';
      const param = {...data, userId : student.userId, studentId: student.id};

      Alert.confirm(confirmMsg, async () => {
        await disableObjectUnlockAction.mutateAsync(param);

        toast.success(completeMsg);
        await refetchSearch();
      });
  }, [student, disableObjectUnlockAction, refetchSearch]);

  const defaultSorted = useMemo(() => ({}), []);
  const columns = useMemo(() => [
      {
        Header: "Process Id",
        accessor: "processId",
        textAlign: 'center',
      },
      {
        Header: "Name",
        accessor: "name",
        textAlign: 'center',
      },
      {
        Header: "Name Native",
        accessor: "nameNative",
        textAlign: 'center',
      },
      {
        Header: "Unlocked",
        accessor: "unlocked",
        textAlign: 'center',
        Cell: ({ row }) => (row.original.unlocked ? 'true' : 'false'),
      },
    {
      Header: "",
      accessor: "true",
      textAlign: 'center',
      disableSortBy: true,
      Cell: ({ row }) => (row.original.unlocked ? <a href="#!" onClick={(e) => disableUnlock(e, row.original)}>disable unlock</a> : ''),
    },
    ],[disableUnlock]
  );

  return (
    <>
      <div className="search-condition">
        <Form onSubmit={handleSubmit(onValid)} onReset={reset} className="w-100">
          <Row>
            <Col xs="12" md="6">
              <Form.Group className="form-group" controlId="id_processId">
                <Form.Label>ProcessId</Form.Label>
                <Controller
                  name="processId"
                  control={control}
                  rules={{
                    validate: {
                      number: (value) => Validator.validateNumber(value)
                    }
                  }}
                  render={({ field }) =>
                    <Form.Control
                      {...field}
                      type="text"
                      placeholder="포함"
                      isInvalid={errors.processId}
                    />
                  }
                />
                <ErrorMessageFeedback error={errors.processId} />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col xs="12" className="btn-wrapper">
              <button className="btn btn-outline-primary" type="submit">
                <span className="material-icons">search</span>Search
              </button>
            </Col>
          </Row>
        </Form>
      </div>
      <div className="search-result">
        <div className="sub-title">검색 결과</div>

        <div className="table-wrapper">
          <div className="count-block justify-content-between">
            <div>
              <button className="btn btn-outline-info" type="button" onClick={handleUnLockSearchModalShow}>
                <span className="material-icons">add</span>Add UnLock
              </button>
            </div>
            <div>
              <span>Showing {result.startIndex}-{result.endIndex} of {result.totalCnt} results</span>
            </div>
          </div>

          <SortingTable columns={columns}
                        data={result.resultList}
                        defaultSorted={defaultSorted}
                        manualSortBy={true}
                        fnSort={fnSort} />

          <Paging totalPages={result.totalPages}
                  currentPage={result.currentPage}
                  prevPage={result.prevPage}
                  nextPage={result.nextPage}
                  startPage={result.startPage}
                  endPage={result.endPage}
                  displayPageNum={result.displayPageNum}
                  fnPaging={fnPaging} />
        </div>
      </div>

      <UnlockObjectSearchModal
        onSelect={handleUnlockSearchSelect}
        onHide={handleUnLockSearchModalHide}
        modalItem={unlockSearchModalItem}
      />
    </>
  );
}

export default ObjectUnlock;